import thunkMiddleware from 'redux-thunk'
import {
  applyMiddleware, createStore, Store,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'

import { history } from '~/client/utils/history'
import { rootReducer } from '~/client/store/rootReducer'
// TODO add back analytics and location thing (qv?)
import { locationToState, stateToLocation } from '~/client/store/locationState/middleware'
// import { analyticsMiddlewareFactory } from '~/client/store/analytics'

const defaultInitialState = {} as State.GlobalState

// We have to check `.default` because of the way this module is imported/resolved on the server and the client.
// On the server, this iw the entire exports object (namespace), while on the client it's just the module
// @ts-expect-error
const resolvedThunkMiddleware = thunkMiddleware.default ? thunkMiddleware.default : thunkMiddleware

/**
 * creates a store from a given state and enhancerions
 */
export const configureStore = (
  // analytics: Gallery.Analytics.IAnalytics,
  initialState: State.GlobalState = defaultInitialState,
  enableDevTools: boolean = false
): Store<State.GlobalState> => {
  const middlewares = [
    // analyticsMiddlewareFactory(analytics),
    stateToLocation(history),
    resolvedThunkMiddleware]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  let store
  if (enableDevTools) {
    const composedEnhancers = composeWithDevTools({ trace: true })(middlewareEnhancer)
    store = createStore(rootReducer, initialState, composedEnhancers)
  } else {
    store = createStore(rootReducer, initialState, middlewareEnhancer)
  }

  locationToState(store.dispatch)

  return store
}
