import config from 'config'
import { AnyAction } from 'redux'
import { getLogger } from 'client/utils/gallery/logger'
import { ContentService, ContentQuery } from '~/services/ContentService'
import {
  buildContentState,
  buildFacetState,
} from '~/client/store/buildContentState'
import { loadMoreCountSelector, currentPageInfoSelector } from '~/client/store/paging/selectors'
import {
  refinementIdsByFilterSelector,
} from '~/client/store/refinement/selectors'
import { categoryByIdSelector } from '~/client/store/filterCategories'
import { updateUrl } from '~/client/store/locationState/utils'
import {
  getGalleryNameSelector,
  getLocaleSelector,
  getMpvid,
  getQuantitySelector,
  selectedOptionsSelector,
} from '~/client/store/config'
import {
  getBypassApproval,
  getNoCache,
  getUseConstraints,
  getForcedRankingStrategyOverride,
  getQuickViewId,
  getTemplatePurposes,
} from '~/client/store/debug'
import { CONTENT_REVERT, CONTENT_UPDATE } from '~/client/store/constants'
import { REFINEMENT_DIMENSION } from 'shared/constants'
import { v4 } from 'uuid'
import {
  getRenderPropsOverrides,
  getSearchBackend,
  getDesignCreationTypes,
  getUseRealisationEngineService,
  getDebugModeEnabled,
} from '~/client/store/debug/reducer'
import { getDesignId, getProductKey } from '~/client/store/config/reducer'
import { isNotFalse } from '~/shared/heplers'
// TODO
// import { assembleASPExperimentFlags } from '~/experiments/ASPSearch/assembleASPExperimentFlags'
import { getMetadataQueryParams } from '~/client/store/content/utils'
import { INITIAL_STATE as DEFAULT_PAGE_STATE } from '~/client/store/paging/constants'
// import { getContentQueryConfigExperimentFlags } from '~/experiments/utils'
import { designPersonalizationContextSelector, logoAppliedSelector } from '~/client/store/designPersonalization/selectors'
import { DesignPersonalizationContextService } from '~/services/DesignPersonalizationContextService'
// import { normalizeTemplateInteractionsToBoosts } from '~/client/utils/normalizeTemplateInteractionsToBoosts'
// import { isExperimentActive } from '~/shared/ab-testing'
// import { BOOST_VARIATIONS, RELEVANT_BOOST_TYPES } from '~/experiments/DesignBoost/constants'
// import { ASPNewnessExperiment } from '~/experiments/ASPSearch/ASPNewnessExperiment'
import { taxonomyIdByCategoryIdSelector } from '~/client/store/filterCategories/reducer'
import { boostsSelector } from '../boosts/reducer'
import { updateBoosts } from '../boosts/actions'
import { authDataSelector } from '../auth/reducer'
import { updatePreviouslyOrdered } from '~/client/store/previouslyOrdered/actions'
import { createFullPreviouslyOrdered, filterOutPreviouslyOrdered } from '~/client/store/previouslyOrdered/utils'
import { checkIfReloadFromScratch, updateRemovedTemplate } from '~/client/store/removedTemplateForPreviouslyOrdered/actions'
import { refillRemovedTemplate } from '../removedTemplateForPreviouslyOrdered/utils'
import { normalizeTemplateInteractionsToBoosts } from '~/client/utils/normalizeTemplateInteractionsToBoosts'

type ContentCategories = {
  categories: string[][];
  facetCategories: string[];
}

type GetContentCategoriesFn = (selectedCategories: Util.StringDictionary<string[]>) => ContentCategories

/**
 * Gets categories to filter and facet on
 *
 * Prune L1s out of request when filtering on L2s to get the correct results back
 *   ex: (L1,L2) in the QS would behave like (L1 OR L2) and return all L1 content plus L2, which isn't correct
 * @param state
 */
function getContentCategories (state: State.GlobalState): GetContentCategoriesFn {
  const getCategory = categoryByIdSelector(state)

  return (selectedCategories: Util.StringDictionary<string[]>): ContentCategories => {
    const parentIds = {} as Util.StringDictionary<string>

    const roots = Object.values(state.filters.byId)
      .filter((f) => f.dimension === REFINEMENT_DIMENSION.CATEGORY)
      .map((f) => (f as State.CategoryFilter).categoryId.toString())

    // iterate over the categories for that filter,
    //   finding the max depth and then filtering on that depth
    // exclude L2s from facetCategories since we dont show L3s
    const categories = Object.values(selectedCategories).map((catIds) => {
      let maxDepth = 0

      catIds.forEach((catId) => {
        const category = getCategory(catId)

        if (!category) {
          throw new Error(`Invalid category. Category ${catId} does not exist`)
        }

        const { level } = category

        if (level > maxDepth) {
          maxDepth = level
        }

        // L3s will never be shown, so no facets needed
        if (level < 2) {
          parentIds[catId] = catId
        }
      })

      return catIds.filter((catId) => getCategory(catId).level === maxDepth)
    })

    return {
      categories,
      facetCategories: roots.concat(Object.keys(parentIds)),
    }
  }
}

interface ContentUpdateParams {
  newContent: Gallery.ContentQuery.Response,
  locale: i18n.Locale,
  query: ContentQuery,
  quantity: Gallery.Models.Url.ValidParsedQsValue<number>,
  qvContent?: Gallery.ContentQuery.TileEntityResponse,
  oldState?: State.GlobalState,
  getTaxonomyIdByCategoryId: (categoryId: string) => string,
  selectedTaxonomyIds: string[],
}

/**
 * Action generator for the content api response
 *
 * @param newContent content api response
 * @param locale locale code
 * @param query the original query used to request content, added to the action for information only
 * @param quantity
 * @param qvContent
 * @param oldState
 */
function generateContentUpdate ({
  newContent,
  locale,
  query,
  quantity,
  qvContent,
  oldState,
  getTaxonomyIdByCategoryId,
  selectedTaxonomyIds,
}: ContentUpdateParams): AnyAction {
  const mergedContent = (oldState?.config.rawContent ?? []).concat(newContent.results.content)
  const loadMoreCount = oldState ? oldState.paging.loadMoreCount + 1 : DEFAULT_PAGE_STATE.loadMoreCount

  return {
    type: CONTENT_UPDATE,
    payload: {
      ...buildFacetState(newContent.results.facets),
      ...buildContentState(
        mergedContent,
        locale,
        quantity,
        getTaxonomyIdByCategoryId,
        qvContent,
        selectedTaxonomyIds
      ),
      paging: {
        totalEntities: newContent.total,
        loadMoreCount,
        hasMoreTemplates: newContent.length + newContent.offset < newContent.total,
      },
      requestUrl: newContent.requestUrl,
      config: {
        viewId: v4(),
        rawContent: mergedContent,
        lastRawContent: newContent.results.content,
      },
    },
    meta: {
      query,
    },
  }
}

function generateContentRevert (originalState: State.GlobalState, query: ContentQuery): AnyAction {
  return {
    type: CONTENT_REVERT,
    payload: {
      paging: originalState.paging,
      refinements: originalState.refinements,
    },
    meta: {
      query,
    },
  }
}

interface ContentUpdateOptions {
  actions?: AnyAction | AnyAction[]
  shouldUpdateUrl?: boolean;
  loadMore?: boolean;
  callback?: () => void;
  shouldUpdateBoosts?: boolean;
  latestOrderedTemplate?:
  VP.DesignPersonalization.Models.DesignPersonalizationContextService.TemplateInteraction;
  reloadFromScratch?: boolean;

}

/**
 * Action creator similar to contentUpdate.
 * If the imageplacholder attribute is present, this will return all templates with those containing
 * image placeholders sorted to the top instead of a filtered list of image placholder templates.
 *
 * Since the Personalization UX test won, this should be replaced with boosting logic in Content Query.
 * Once boosting is available in Content Query / Design Conductor, we should replace this with boosting.
 */
export const personalizationPlaceholderRerankContentUpdate = ({
  actions,
  shouldUpdateUrl = true,
  loadMore = false,
  callback = undefined,
  shouldUpdateBoosts = true,
  latestOrderedTemplate = undefined,
  reloadFromScratch = false,
}: ContentUpdateOptions = {}): Gallery.ContentQuery.Action => async (
  dispatch,
  getState
): Promise<AnyAction> => {
  const originalState = getState()

  let isReloadFromScratch = reloadFromScratch

  if (checkIfReloadFromScratch(actions)) {
    isReloadFromScratch = true
  }

  if (actions) {
    [actions].flat().forEach(dispatch)
  }

  const state = getState()
  const { previouslyOrdered, removedTemplateForPreviouslyOrdered } = state
  const refinements = refinementIdsByFilterSelector(state)
  const getTaxonomyIdByCategoryId = taxonomyIdByCategoryIdSelector(state)
  const { categories, facetCategories } = getContentCategories(state)(refinements.categories)
  const quantity = getQuantitySelector(state)
  // const rawExperiments = getRawExperiments(state)
  const { keywords: metadataKeywords, attributes: metadataAttributes } = getMetadataQueryParams(state)
  const designId = getDesignId(state)
  const loadMoreCount = loadMoreCountSelector(state)
  const authData = authDataSelector(state)

  const searchBackend = getSearchBackend(state)
  const debugEnabled = getDebugModeEnabled(state)
  const designCreationTypes = getDesignCreationTypes(state)
  const designPersonalizationContext = designPersonalizationContextSelector(state)

  const useRealisationEngineService = !!getUseRealisationEngineService(state)

  //   const experimentFlags = assembleASPExperimentFlags(
  //     [ASPNewnessExperiment],
  //     rawExperiments,
  //     getASPExperimentFlags(state)
  //   )
  //   const configExperimentFlags = getContentQueryConfigExperimentFlags(rawExperiments)

  const {
    pageSize,
    offset,
  } = currentPageInfoSelector(state, loadMore ? loadMoreCount + 1 : DEFAULT_PAGE_STATE.loadMoreCount)

  const attributes = [...metadataAttributes, ...Object.values(refinements.attributes)]

  const boostEnabled = false // isExperimentActive(BOOST_VARIATIONS.Enabled, rawExperiments)
  // todo
  enum TEMPLATE_INTERACTION_TYPE {
    QUICKVIEW = 'QuickView',
    QUICKVIEW_QSP = 'QuickViewQSP',
    STUDIO_TRANSITION = 'StudioTransition',
  }

  const RELEVANT_BOOST_TYPES = [
    TEMPLATE_INTERACTION_TYPE.QUICKVIEW,
    TEMPLATE_INTERACTION_TYPE.QUICKVIEW_QSP,
    TEMPLATE_INTERACTION_TYPE.STUDIO_TRANSITION,
  ]

  if (boostEnabled && shouldUpdateBoosts && authData?.canonicalId && authData?.accessToken) {
    const templateInteractions = await DesignPersonalizationContextService.getTemplateInteractions(
      authData.canonicalId,
      authData.accessToken,
      RELEVANT_BOOST_TYPES
    )

    const { boostedKeywords, boostedCategories } = normalizeTemplateInteractionsToBoosts(templateInteractions)

    dispatch(updateBoosts({
      boostedKeywords,
      boostedCategories,
    }))
  }

  const boosts = boostEnabled ? boostsSelector(state) : { boostedKeywords: [], boostedCategories: [] }

  const query: ContentQuery = {
    categories,
    facetCategories,
    attributes,
    useRealisationEngineService,
    designPersonalizationContext,
    bypassApproval: getBypassApproval(state),
    locale: getLocaleSelector(state),
    galleryName: getGalleryNameSelector(state),
    keyword: [...metadataKeywords, refinements.keyword].filter(isNotFalse),
    collection: refinements.collection,
    length: pageSize,
    mpvId: getMpvid(state),
    offset,
    selectedOptions: selectedOptionsSelector(state),
    noCache: getNoCache(state),
    useConstraints: getUseConstraints(state),
    forcedRankingStrategyOverride: getForcedRankingStrategyOverride(state),
    renderProps: getRenderPropsOverrides(state),
    templatePurposes: getTemplatePurposes(state),
    searchBackend,
    experimentFlags: {},
    configExperimentFlags: [],
    designCreationTypes,
    debug: debugEnabled,
    boostedKeywords: boosts.boostedKeywords,
    boostedCategories: boosts.boostedCategories,
    includeCategoryAndKeywordData: true,
    filterAltTextCompatibleTagging: true,
  }

  try {
    const contentServiceConfig = config.services.contentQueryService
    const contentService = new ContentService(contentServiceConfig, getLogger)
    const logoApplied = logoAppliedSelector(originalState)
    let matchingTemplate

    if (latestOrderedTemplate) {
      const productKey = getProductKey(state)
      const locale = getLocaleSelector(state)
      const matchingTemplates = await contentService.getMatchingProduct(
        productKey,
        locale,
        decodeURIComponent(latestOrderedTemplate.data) // latestOrderedTemplate.data is templateToken
      )

      if (matchingTemplates.length) {
        matchingTemplate = {
          ...matchingTemplates[0],
          timestamp: latestOrderedTemplate.timestamp, // adding timestamp from TemplateInteractions
        }
      }
    }

    // make the call to get templates without filtering on image placeholders
    const newContent = await contentService.getContent(query)

    if (logoApplied) {
      /**
             * Function to get templates with image placeholders only.
             *
             * The function will recursively call itself if there are image placeholder templates in the full
             * set of templates that don't exist within the set of image placeholder-only templates.
             *
             * @param unfilteredContent the full set of templates for the page the user is on
             * @param imagePlaceholderOffset offset to use when retrieving additional image placeholder templates
             */
      const getImagePlaceholderContent = async (
        unfilteredContent: Gallery.ContentQuery.TileEntityResponse[],
        imagePlaceholderOffset = 0
      ): Promise<Gallery.ContentQuery.TileEntityResponse[]> => {
        const imagePlaceholderLength = 96

        // make a request to content query for 96 image placholder templates
        const { results: { content: imagePlaceholderContent }, total } = await contentService.getContent({
          ...query,
          attributes: attributes.concat([['imageplaceholder_true']]),
          length: imagePlaceholderLength,
          offset: imagePlaceholderOffset,
        })

        // check if the the image placeholder templates have any overlap with the full set of templates
        const overlapExists = unfilteredContent.filter((content) => imagePlaceholderContent.findIndex(
          (imagePlaceholderDesign) => imagePlaceholderDesign.designId === content.designId
        ) >= 0).length > 0

        // recursively retrieve more image placeholder templates if:
        // 1. there are still templates to retrieve (checked by seeing if the offset is greater than the total)
        // 2. there is no overlap of templates between the image placeholder-only and full set
        // 3. the last image placholder-only template exists within the full set
        if (
          imagePlaceholderOffset < total &&
                        (!overlapExists ||
                            unfilteredContent.findIndex(
                              (design) => design.designId ===
                                    imagePlaceholderContent[imagePlaceholderContent.length - 1].designId
                            ) >= 0)
        ) {
          return [
            ...imagePlaceholderContent,
            ...(await getImagePlaceholderContent(
              unfilteredContent,
              imagePlaceholderOffset + imagePlaceholderLength
            )),
          ]
        }

        return imagePlaceholderContent
      }

      const imagePlaceholderContent = await getImagePlaceholderContent(newContent.results.content, 0)
      const imagePlaceholderDesigns: Gallery.ContentQuery.TileEntityResponse[] = []

      // filter the full set of templates to only those that do NOT have an image placeholder
      // and push all the image placeholder templates to a different array
      const newContentNoImagePlaceholder = newContent.results.content.filter((design) => {
        if (imagePlaceholderContent.findIndex(
          (imagePlaceholderDesign) => imagePlaceholderDesign.designId === design.designId
        ) >= 0) {
          imagePlaceholderDesigns.push(design)
          return false
        }

        return true
      })

      // prepend the image placeholder templates to the set of non-image placeholder templates, effectively
      // sorting the image placeholder templates to the top of the page
      newContent.results.content = [...imagePlaceholderDesigns, ...newContentNoImagePlaceholder]

      callback?.()
    }

    const designIdQSP = getQuickViewId(state)

    const quickViewDesignID = designIdQSP?.toString() ?? designId

    let qvContent

    if (quickViewDesignID) {
      const qvQuery: ContentQuery = {
        bypassApproval: getBypassApproval(state),
        locale: getLocaleSelector(state),
        galleryName: getGalleryNameSelector(state),
        mpvId: getMpvid(state),
        noCache: getNoCache(state),
        useConstraints: getUseConstraints(state),
        forcedRankingStrategyOverride: getForcedRankingStrategyOverride(state),
        designIds: [quickViewDesignID.toString()],
        length: 1,
        selectedOptions: selectedOptionsSelector(state),
        attributes: Object.values(refinements.attributes),
        renderProps: getRenderPropsOverrides(state),
        searchBackend,
        experimentFlags: {}, // todo
        configExperimentFlags: [],
        debug: debugEnabled,
      };

      [qvContent] = (await contentService.getContent(qvQuery)).results.content

      if (!qvContent) {
        const message = `Failed to open quickView for QSP: ${quickViewDesignID}`

        getLogger().error({
          message,
          query,
        })
      } else if (matchingTemplate?.designId === quickViewDesignID.toString()) {
        qvContent = { ...qvContent, isPreviouslyOrdered: true }
      }
    }

    if (matchingTemplate) {
      const {
        completeMatchingTemplate,
        filteredAllTemplates,
        removedTemplate,
      } = createFullPreviouslyOrdered(newContent.results.content, matchingTemplate)

      newContent.results.content = [
        { ...completeMatchingTemplate },
        ...filteredAllTemplates,
      ]
      if (removedTemplate) {
        dispatch(updateRemovedTemplate(removedTemplate))
      }
      dispatch(updatePreviouslyOrdered(completeMatchingTemplate))
    } else if (isReloadFromScratch && previouslyOrdered?.isPreviouslyOrdered) {
      const filteredAllTemplates = filterOutPreviouslyOrdered(newContent.results.content, previouslyOrdered)

      newContent.results.content = [
        { ...previouslyOrdered },
        ...filteredAllTemplates,
      ]
    } else if (previouslyOrdered?.isPreviouslyOrdered && removedTemplateForPreviouslyOrdered) {
      const { allTemplates, removedTemplate } = refillRemovedTemplate(
        newContent.results.content,
        previouslyOrdered,
        removedTemplateForPreviouslyOrdered
      )

      if (!removedTemplate) {
        newContent.results.content = [...allTemplates]
        dispatch(updateRemovedTemplate(null))
      }
    }

    let contentUpdateAction: AnyAction = generateContentUpdate({
      newContent,
      locale: state.config.locale,
      query,
      quantity,
      qvContent,
      oldState: loadMore ? state : undefined,
      getTaxonomyIdByCategoryId,
      selectedTaxonomyIds: categories.flat(),
    })

    if (shouldUpdateUrl) {
      contentUpdateAction = updateUrl(contentUpdateAction)
    }

    return dispatch(contentUpdateAction)
  } catch (e) {
    // revert on the client, otherwise throw
    if (process.env.IS_CLIENT) {
      getLogger().error(e as Error)
      return dispatch(generateContentRevert(originalState, query))
    }
    throw e
  }
}

/**
 * Action Creator to update the content state.
 * Given a query to perform against the Content Service, retrieve the new
 * content and return the CONTENT_UPDATE action.
 */
export const contentUpdate = ({
  actions,
  shouldUpdateUrl = true,
  loadMore = false,
  callback = undefined,
  shouldUpdateBoosts = false,
  latestOrderedTemplate = undefined,
  reloadFromScratch = false,
}: ContentUpdateOptions = {}): Gallery.ContentQuery.Action => async (
  dispatch,
  getState
): Promise<AnyAction> => {
  const originalState = getState()
  const showPersonalizationUI = false // TODO booleanRenderPropertySelector(originalState)(RenderProperty.ShowPersonalizationUI)
  // let isReloadFromScratch = reloadFromScratch

  // if (checkIfReloadFromScratch(actions)) {
  //   isReloadFromScratch = true
  // }

  if (showPersonalizationUI) {
    return personalizationPlaceholderRerankContentUpdate({
      actions, shouldUpdateUrl, loadMore, callback, shouldUpdateBoosts, latestOrderedTemplate, reloadFromScratch,
    })(
      dispatch,
      getState,
      undefined
    )
  }

  if (actions) {
    [actions].flat().forEach(dispatch)
  }

  const state = getState()
  // const { previouslyOrdered, removedTemplateForPreviouslyOrdered } = state
  const refinements = refinementIdsByFilterSelector(state)
  const getTaxonomyIdByCategoryId = taxonomyIdByCategoryIdSelector(state)
  const { categories, facetCategories } = getContentCategories(state)(refinements.categories)
  const quantity = getQuantitySelector(state)
  // const rawExperiments = getRawExperiments(state)
  const { keywords: metadataKeywords, attributes: metadataAttributes } = getMetadataQueryParams(state)
  const designId = getDesignId(state)
  const loadMoreCount = loadMoreCountSelector(state)

  const searchBackend = getSearchBackend(state)
  const debugEnabled = getDebugModeEnabled(state)
  const designCreationTypes = getDesignCreationTypes(state)
  const designPersonalizationContext = designPersonalizationContextSelector(state)
  const useRealisationEngineService = !!getUseRealisationEngineService(state)
  // const authData = authDataSelector(state)

  //   const experimentFlags = assembleASPExperimentFlags(
  //     [ASPNewnessExperiment],
  //     rawExperiments,
  //     getASPExperimentFlags(state)
  //   )
  //   const configExperimentFlags = getContentQueryConfigExperimentFlags(rawExperiments)

  const {
    pageSize,
    offset,
  } = currentPageInfoSelector(state, loadMore ? loadMoreCount + 1 : DEFAULT_PAGE_STATE.loadMoreCount)

  const attributes = [...metadataAttributes, ...Object.values(refinements.attributes)]

  // const boostEnabled = false // isExperimentActive(BOOST_VARIATIONS.Enabled, rawExperiments)

  //   if (boostEnabled && shouldUpdateBoosts && authData?.canonicalId && authData?.accessToken) {
  //     const templateInteractions = await DesignPersonalizationContextService.getTemplateInteractions(
  //       authData.canonicalId,
  //       authData.accessToken,
  //       RELEVANT_BOOST_TYPES
  //     )

  //     const { boostedKeywords, boostedCategories } = normalizeTemplateInteractionsToBoosts(templateInteractions)

  //     dispatch(updateBoosts({
  //       boostedKeywords,
  //       boostedCategories,
  //     }))
  //   }

  const boosts = boostsSelector(state)

  const query: ContentQuery = {
    categories,
    facetCategories,
    attributes,
    useRealisationEngineService,
    bypassApproval: getBypassApproval(state),
    locale: getLocaleSelector(state),
    galleryName: getGalleryNameSelector(state),
    keyword: [...metadataKeywords, refinements.keyword].filter(isNotFalse),
    collection: refinements.collection,
    length: pageSize,
    mpvId: getMpvid(state),
    offset,
    selectedOptions: selectedOptionsSelector(state),
    noCache: getNoCache(state),
    useConstraints: getUseConstraints(state),
    forcedRankingStrategyOverride: getForcedRankingStrategyOverride(state),
    renderProps: getRenderPropsOverrides(state),
    templatePurposes: getTemplatePurposes(state),
    searchBackend,
    experimentFlags: {},
    configExperimentFlags: [],
    designCreationTypes,
    designPersonalizationContext,
    debug: debugEnabled,
    boostedKeywords: boosts.boostedKeywords,
    boostedCategories: boosts.boostedCategories,
    includeCategoryAndKeywordData: true,
    filterAltTextCompatibleTagging: true,
  }

  try {
    const contentServiceConfig = config.services.contentQueryService
    const contentService = new ContentService(contentServiceConfig, getLogger)
    // let matchingTemplate

    if (latestOrderedTemplate) {
    //   const productKey = getProductKey(state)
    //   const locale = getLocaleSelector(state)
    //   const matchingTemplates = await contentService.getMatchingProduct(
    //     productKey,
    //     locale,
    //     decodeURIComponent(latestOrderedTemplate.data) // latestOrderedTemplate.data is templateToken
    //   )

      // if (matchingTemplates.length) {
      //   matchingTemplate = {
      //     ...matchingTemplates[0],
      //     timestamp: latestOrderedTemplate.timestamp, // adding timestamp from TemplateInteractions
      //   }
      // }
    }
    const newContent = await contentService.getContent(query)
    const designIdQSP = getQuickViewId(state)

    const quickViewDesignID = designIdQSP?.toString() ?? designId

    // TODO qv
    let qvContent
    if (quickViewDesignID) {
      // const qvQuery: ContentQuery = {
      //   bypassApproval: getBypassApproval(state),
      //   locale: getLocaleSelector(state),
      //   galleryName: getGalleryNameSelector(state),
      //   mpvId: getMpvid(state),
      //   noCache: getNoCache(state),
      //   useConstraints: getUseConstraints(state),
      //   forcedRankingStrategyOverride: getForcedRankingStrategyOverride(state),
      //   designIds: [quickViewDesignID.toString()],
      //   length: 1,
      //   selectedOptions: selectedOptionsSelector(state),
      //   attributes: Object.values(refinements.attributes),
      //   renderProps: getRenderPropsOverrides(state),
      //   searchBackend,
      //   experimentFlags: {},
      //   configExperimentFlags: [],
      //   debug: debugEnabled,
      //   designCreationTypes,
      // }

      //   [qvContent] = (await contentService.getContent(qvQuery)).results.content

      // if (!qvContent) {
      //   const message = `Failed to open quickView for QSP: ${quickViewDesignID}`

      //   getLogger().error({
      //     message,
      //     query,
      //   })
      // } else if (matchingTemplate?.designId === quickViewDesignID.toString()) {
      //   qvContent = { ...qvContent, isPreviouslyOrdered: true }
      // }
    }

    // if (matchingTemplate) {
    //   const {
    //     completeMatchingTemplate,
    //     filteredAllTemplates,
    //     removedTemplate,
    //   } = createFullPreviouslyOrdered(newContent.results.content, matchingTemplate)

    //   newContent.results.content = [
    //     { ...completeMatchingTemplate },
    //     ...filteredAllTemplates,
    //   ]
    //   if (removedTemplate) {
    //     dispatch(updateRemovedTemplate(removedTemplate))
    //   }
    //   dispatch(updatePreviouslyOrdered(completeMatchingTemplate))
    // } else if (isReloadFromScratch && previouslyOrdered?.isPreviouslyOrdered) {
    //   const filteredAllTemplates = filterOutPreviouslyOrdered(newContent.results.content, previouslyOrdered)

    //   newContent.results.content = [
    //     { ...previouslyOrdered },
    //     ...filteredAllTemplates,
    //   ]
    // } else if (previouslyOrdered?.isPreviouslyOrdered && removedTemplateForPreviouslyOrdered) {
    //   const { allTemplates, removedTemplate } = refillRemovedTemplate(
    //     newContent.results.content,
    //     previouslyOrdered,
    //     removedTemplateForPreviouslyOrdered
    //   )

    //   if (!removedTemplate) {
    //     newContent.results.content = [...allTemplates]
    //     dispatch(updateRemovedTemplate(null))
    //   }
    // }

    let contentUpdateAction: AnyAction = generateContentUpdate({
      newContent,
      locale: state.config.locale,
      query,
      quantity,
      qvContent,
      oldState: loadMore ? state : undefined,
      getTaxonomyIdByCategoryId,
      selectedTaxonomyIds: categories.flat(),
    })

    if (shouldUpdateUrl) {
      contentUpdateAction = updateUrl(contentUpdateAction)
    }

    return dispatch(contentUpdateAction)
  } catch (e) {
    // revert on the client, otherwise throw
    if (process.env.IS_CLIENT) {
      getLogger().error(e as Error)
      return dispatch(generateContentRevert(originalState, query))
    }
    throw e
  }
}
