export type Match = {
  designId?: string,
  segment?: string[] | null,
}

// Regex test: https://regex101.com/r/QHuET8/2
// This tests for any sequence of '/<characters>' ending with a 'qv/<GUID>' for static or 'qv/<hash>'
// eslint-disable-next-line prefer-regex-literals
const matchRegex = new RegExp(/(?:\/(?=(?<segment>[^В/]+)))+(?:\/?(?<prefix>qv\/)(?<designId>(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]{4}-){3}(?:[0-9a-fA-F]){12}|(?:([0-9A-Z]){2}-){15}(?:[0-9A-Z]){2}))?/, 'g')

export const matchGalleryParams = (pathname: string): Match => {
  try {
    const matches = pathname.matchAll(matchRegex)

    if (matches) {
      const groups = Array.from(matches).map((match) => match.groups)

      return {
        designId: groups.find((g) => !!g?.designId)?.designId,
        segment: groups.filter((g) => !g?.designId).map((g) => g?.segment).filter((x): x is string => !!x),
      }
    }

    return {}
  } catch (e) {
    return {}
  } finally {
    // reset
    matchRegex.lastIndex = 0
  }
}
