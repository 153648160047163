import { useSelector } from 'react-redux'

import { buildFilterOptions } from '~/client/components/Gallery/Filters/util'

export const useFilterOptions = (
  filter: State.AttributeFilter
): State.FilterOptions => useSelector((state: State.GlobalState) => {
  const options = filter ? buildFilterOptions(state, filter.options).filter((o) => o.facetCount != null) : []

  // only render the filter if there is at least one enabled item
  const visible = options.some((o) => !o.disabled)
  const selected = options.reduce((accum, o) => ({
    ...accum,
    [o.value]: !!o.selected,
  }), {} as SelectedValues)

  return {
    options,
    selected,
    visible,
  }
})
