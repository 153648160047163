import config from 'config'
import { Tile } from '~/client/components/common/Tile'
import {
  Typography, Icon, Link, Box,
} from '@vp/swan'
import { getLocaleSelector, getMpvid } from '~/client/store/config'
import { useSelector } from 'react-redux'
import { useTranslations } from '~/client/hooks/useTranslations'
import { buildFullyQualifiedVistaprintUrl } from 'client/utils/vistaprintUrlBuilder'
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics'
import {
  ANALYTICS_PAGE_ZONE,
  ANALYTICS_CTA_VALUE,
  URL_TARGET,
} from '~/client/components/Gallery/PreviewArea/CareTile/constants'
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants'
// import { useExperimentation } from '~/client/hooks/useExperimentation'
// import { NEW_DIFY_TILE_POSITION_VARIATIONS } from '~/experiments/NewDIFYTilePosition/constants'

// declare const PUBLIC_PATH: string

// const DESIGNERS_IMAGE = `${PUBLIC_PATH}images/care-tile-designers.png`
const DESIGNERS_IMAGE = 'TODO'

type PropTypes = {
  previewHeight: number;
}

export const CareTile = ({ previewHeight }: PropTypes): JSX.Element => {
  const localize = useTranslations()
  // TODO
  //   const isExperimentActive = useExperimentation()
  const locale = useSelector(getLocaleSelector)
  const mpvId = useSelector(getMpvid)

  //   const NewDIFYTileVariant1Enabled = isExperimentActive(NEW_DIFY_TILE_POSITION_VARIATIONS.Enabled1)
  //   const NewDIFYTileVariant2Enabled = isExperimentActive(NEW_DIFY_TILE_POSITION_VARIATIONS.Enabled2)

  const isDIFYTileVariantionEnables = false // NewDIFYTileVariant1Enabled || NewDIFYTileVariant2Enabled

  const url = buildFullyQualifiedVistaprintUrl({
    path: URL_TARGET,
    locale,
    mpvId,
  })
  const analytics = useAnalytics()
  const { pathname } = 'TODO' // was useLocation()

  const onClick = (): void => {
    const eventDetailList = [
      pathname,
      URL_TARGET,
      ANALYTICS_PAGE_ZONE.CARE_TILE,
      ANALYTICS_CTA_VALUE.EXPERT_SERVICES_DESIGN_SERVICES_BRIEF,
    ]

    analytics.trackEvent({
      action: ANALYTICS_EVENT_ACTIONS.NAVIGATION_CLICKED,
      eventLabel: `Content:${ANALYTICS_PAGE_ZONE.CARE_TILE}`,
      eventDetail: eventDetailList.join(';'),
      navigationDetail: ANALYTICS_CTA_VALUE.EXPERT_SERVICES_DESIGN_SERVICES_BRIEF,
      route: config.client.segmentRoute,
    })
  }

  if (isDIFYTileVariantionEnables) {
    return (
      <Tile
        style={{
          minHeight: previewHeight,
        }}
        type='care-tile care-tile-dark '
      >
        <Link aria-label={localize('CareTileHeading')} className='care-tile-action-area' href={url} referrerPolicy='no-referrer-when-downgrade' skin='unstyled' onClick={onClick}>
          <div className='care-tile-content'>
            <Box px={{ lg: 4, xl: 7 }}>
              <Box py={{
                xs: 2, md: 3, lg: 5, xl: 7,
              }}
              >
                <img alt={localize('CareTileImageDescription')} className='care-tile-designer-img' src={DESIGNERS_IMAGE} />
              </Box>
              <Typography className='care-tile-header-text' fontWeight='bold'>
                {localize('CareTileHeadingVariant')}
              </Typography>
              <Typography fontSize='small'>{localize('CareTileCaptionTextVariant')}</Typography>
              <Box paddingTop={5}>
                <Typography className='care-tile-cta' fontWeight='bold'>
                  <span className='care-tile-cta-text'>{localize('CareTileCTAText')}</span>
                  <span><Icon darkMode iconType='arrowRight' /></span>
                </Typography>
              </Box>
            </Box>
          </div>
        </Link>
      </Tile>
    )
  }

  return (
    <Tile
      style={{
        minHeight: previewHeight,
      }}
      type='care-tile'
    >
      <Link aria-label={localize('CareTileHeading')} className='care-tile-action-area' href={url} referrerPolicy='no-referrer-when-downgrade' skin='unstyled' onClick={onClick}>
        <div className='care-tile-content'>
          <div className='care-tile-icon-circle'>
            <Icon
              className='care-tile-icon'
              iconType='workWithAPro'
            />
          </div>
          <Typography className='care-tile-header-text' fontWeight='bold'>
            {localize('CareTileHeading')}
          </Typography>
          <Typography fontSize='small'>{localize('CareTileCaptionText')}</Typography>
        </div>
      </Link>
    </Tile>
  )
}

CareTile.displayName = 'CareTile'
