import {
  memo, useRef, MouseEventHandler,
  JSX, useMemo,
} from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  ColorSwatches,
  Icon,
  SelectionSet,
  Typography,
} from '@vp/swan'
import {
  TemplateTile,
  TemplateTileHeader,
  TemplateTileImage,
  TemplateTileContent,
} from '@vp/ep-template-tile' // todo how to add to import map?
import classnames from 'classnames'
import { getLocaleSelector } from '~/client/store/config'
// import { LinkWithQuery } from '~/components/common/LinkWithQuery'
// import { useFavorite } from '~/components/Gallery/DesignTile/hooks/useFavorite'
// import { SpacingBlock } from '~/components/common/PricingDisplay/SpacingBlock'
import { useColorSwatches } from '~/client/components/Gallery/DesignTile/hooks/useColorSwatches'
import { DesignProps } from '~/client/components/Gallery/DesignTile/interface'
// import { usePricing } from '~/components/Gallery/DesignTile/hooks/usePricing'
import { useImage } from '~/client/components/Gallery/DesignTile/hooks/useImage'
import { DismissableAlertBox } from '~/client/components/common/DismissableAlertBox'
import { TileGreetingOptions } from '~/client/components/common/TileGreetingOptions'
import { DebugPop } from '~/client/components/Gallery/PreviewArea/DebugPop'
import { TemplateTileColorSwatchWrapper } from '~/client/components/common/TemplateTile/components/TemplateTileColorSwatchWrapper'
// import { useGalleryBaseURL } from '~/hooks/useGalleryBaseURL'
// import { PreviouslyOrderedCallout } from '~/experiments/PreviouslyOrderedDesigns/PreviouslyOrderedCallout'
import { BoostedTerms } from '../PreviewArea/BoostedTerms'
import { LinkWithQuery } from 'client/components/common/LinkWithQuery'
import { useLocation, useNavigate } from 'react-router-dom'
import { useGalleryBaseURL } from 'client/hooks/useGalleryBaseURL'

const DesignTileComponent = ({
  imageProps,
  colorSwatches,
  handleColorSwatchChange,
  market,
  pricingPresentationType,
  productOptionsByProductKey,
  quantity,
  differentialPrice,
  hideVatMessage,
  hideShippingMessageInVatMessage,
  selectedProductOptions,
  pricingPresentationHTMLModifier = (html: string): string => (html),
  tileEntity,
  selectedDesignId,
  entityId,
  favoriteId,
  previewType,
  noFollow,
  currentDesignId,
  greetingOptions,
  greetingTitle,
  shouldShowGreetingOptions,
  shouldHideForSocialMedia,
  isPreviouslyOrdered,
  onMouseEnter,
  maxColorSwatchesPerRow,
  onLabelClick,
  refValue,
}: DesignProps): JSX.Element => {
  const locale = useSelector(getLocaleSelector)

  // Get Favorite button params
  const {
    isFavorite,
    onChange,
    ariaLabel,
    errorMessage,
    onRequestDismiss,
    dismissVisuallyHiddenLabel,
  } = {
    isFavorite: false,
    onChange: () => {},
    ariaLabel: '',
    errorMessage: '',
    onRequestDismiss: () => {},
    dismissVisuallyHiddenLabel: ''
  }
  // TODO
  //       useFavorite({
  //     entityId, previewType, colorSwatches, favoriteId,
  //   })

  // Get Preview Image params
  const galleryBaseURL = useGalleryBaseURL()

  const {
    imgRef,
    showSpinner,
    alt,
    imageSrc,
    imageSrcSet,
    loading,
    onError,
    onLoad,
    accessibilityText,
    isError,
    fetchpriority,
    size,
    emptyPreviewText,
  } = useImage(imageProps)

  const location = useLocation()

  const linkProps = useMemo(() => ({
    to: `${galleryBaseURL}/qv/${tileEntity.designId}${location.search}`,
    state: { designId: currentDesignId },
    noFollow,
  }), [currentDesignId, galleryBaseURL, tileEntity.designId, noFollow])

  // Get and handle Color Swatches information
  const contentRef = useRef<HTMLDivElement>(null)
  const {
    shouldShowColorSwatches,
    onColorSwatchChangeWrapper,
    handleClickShowMoreButton,
    showMoreIcon,
    isExpanded,
    shouldShowShowMoreButton,
    isColorSwatchColorsSimilar,
  } = useColorSwatches(handleColorSwatchChange, maxColorSwatchesPerRow, colorSwatches)

  const handleStopPropagation = (e: React.MouseEvent): void => e.stopPropagation()
  const handlePreventDefault = (e: React.MouseEvent): void => e.preventDefault()

  const handleShowMoreButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    handlePreventDefault(e)
    handleClickShowMoreButton()
    onLabelClick(e)
  }

  // Get Pricing data
  // const {
  //   sanitizedPricingHtml,
  //   lines,
  //   templateTileTitle,
  //   shouldRenderSpacingBlock,
  //   isLoading,
  //   shouldShowComparativeNames,
  // } = usePricing({
  //   tileEntity,
  //   differentialPrice,
  //   pricingPresentationType,
  //   market,
  //   quantity,
  //   hideVatMessage,
  //   hideShippingMessageInVatMessage,
  //   selectedProductOptions,
  //   productOptionsByProductKey,
  //   pricingPresentationHTMLModifier,
  // })

  return (
    <LinkWithQuery {...linkProps} className='template-tile-link'>
      <TemplateTile ref={refValue} onMouseEnter={onMouseEnter}>
        <TemplateTileHeader>
          <>
            {/* TODO */}
            {/* {isPreviouslyOrdered && (
            <PreviouslyOrderedCallout
              className='previously-ordered-callout-tile'
              ml='3'
            />
          )} */}
            {errorMessage && (
              <DismissableAlertBox
                toast
                className='template-favorite-button-alert-box'
                dismissVisuallyHiddenLabel={dismissVisuallyHiddenLabel}
                skin='error'
                onClick={handlePreventDefault}
                onRequestDismiss={onRequestDismiss}
              >
                {errorMessage}
              </DismissableAlertBox>
            )}
            <Checkbox
              aria-label={ariaLabel}
              checked={isFavorite}
              skin='favorite'
              onChange={onChange}
              onClick={handleStopPropagation}
            />
          </>
        </TemplateTileHeader>
        <TemplateTileImage
          accessibleTextForLoadingState={accessibilityText}
          alt={alt}
          emptyPreviewText={emptyPreviewText}
          fetchpriority={fetchpriority}
          isError={isError}
          isLoading={showSpinner}
          loading={loading}
          ref={imgRef}
          size={size}
          src={imageSrc}
          srcSet={imageSrcSet}
          onError={onError}
          onLoad={onLoad}
        />
        <TemplateTileContent>
          {shouldShowColorSwatches && (
            <div className='color-swatches-wrapper'>
              <SelectionSet
                className={classnames('color-swatches', { 'show-color-swatches': isExpanded })}
                selectedValue={selectedDesignId}
                onClick={handleStopPropagation}
                onSelectedValueChange={onColorSwatchChangeWrapper}
              // skin='standard'
              // name='colorSwatches'
              // variant='single-select'
              // imageWidth={50}
              // imageVerticalAlignment='center'
              >
                <ColorSwatches className={classnames('color-swatches-items', { 'hide-color-swatches': !isExpanded })} ref={contentRef}>
                  {colorSwatches.map(({ designId, colorComposition = [] }) => (
                    <TemplateTileColorSwatchWrapper
                      colorComposition={colorComposition}
                      designId={designId}
                      isColorSwatchColorsSimilar={isColorSwatchColorsSimilar}
                      key={designId}
                      locale={locale}
                    />
                  ))}
                </ColorSwatches>
              </SelectionSet>
              {shouldShowShowMoreButton && (
                <Button className='show-more-button' skin='tertiary' onClick={handleShowMoreButtonClick}>
                  <span>{colorSwatches.length}</span>
                  <Icon iconType={showMoreIcon} size='24p' />
                </Button>
              )}
            </div>
          )}
          {/* {shouldShowComparativeNames && (
          <TemplateTileTitle>
            {templateTileTitle}
          </TemplateTileTitle>
        )}
        {!shouldHideForSocialMedia && (
          <TemplateTilePricing>
            {isLoading && (
              <span className='price-block'>
                <Skeleton />
              </span>
            )}
            <Typography
              className='price-block'
              component='span'
              dangerouslySetInnerHTML={{ __html: sanitizedPricingHtml }}
            />
            {shouldRenderSpacingBlock && <SpacingBlock lines={lines} />}
          </TemplateTilePricing>
        )} */}
          {shouldShowGreetingOptions && (
            <TileGreetingOptions>
              <Typography as='span' className='tile-greeting-title' fontWeight='bold'>
                {greetingTitle}
              </Typography>
              {greetingOptions}
            </TileGreetingOptions>
          )}
          <DebugPop entityId={tileEntity.designId} />
          <BoostedTerms entityId={tileEntity.designId} />
        </TemplateTileContent>
      </TemplateTile>
    </LinkWithQuery>
  )
}

export const DesignTile = memo(DesignTileComponent)
