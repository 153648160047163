import { forwardRef, memo, HTMLProps, } from 'react'
import { useSelector } from 'react-redux'
import { H1, Typography, Divider } from '@vp/swan'

import { getH1Title, booleanRenderPropertySelector, getGalleryIdSelector, getLocaleSelector, } from '~/client/store/config'
import { RenderProperty } from 'shared/renderProperties'
import { getMetadata } from '~/client/store/config/reducer'
import { BreadcrumbsContainer } from '~/client/components/Gallery/Header/BreadcrumbsContainer'
import { RatingsSummary } from '~/client/components/Gallery/Header/RatingsSummary'
import { useRatingsAndReviews } from '~/client/hooks/useRatingsAndReviews'
import { getExperienceType } from '~/client/store/debug'
import { EXPERIENCE_TYPE } from '~/shared/constants'
import { SurveyLink } from '~/client/components/Gallery/Header/SurveyLink'
import { hasSurveyLink } from '~/client/components/Gallery/Header/SurveyLink/utils'
// import { BoostedTermsHeader } from 'components/Gallery/PreviewArea/BoostedTerms/BoostedTermsHeader'

export const HeroHeader = memo(forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>((props, ref) => {
  const h1Title = useSelector(getH1Title)
  const booleanRenderProperty = useSelector(booleanRenderPropertySelector)
  const galleryMetadata = useSelector(getMetadata)
  // const debugEnabled = useSelector(getDebugModeEnabled)
  const experienceType = useSelector(getExperienceType)
  const galleryId = useSelector(getGalleryIdSelector)

  const locale = useSelector(getLocaleSelector)
  const shouldShowSurveyLink = experienceType === EXPERIENCE_TYPE.PRODUCT_PAGE && hasSurveyLink(locale, galleryId)

  const showBreadcrumbs = booleanRenderProperty(RenderProperty.ShowBreadcrumbs)
  const showProductHeaderInfo = booleanRenderProperty(RenderProperty.ShowProductHeaderInfo)

  const ratingsAndReviews = useRatingsAndReviews()
  //   const { pathname, search } = useLocation()

  return (
    <section className='gallery-hero-header' {...props} ref={ref}>
      <div className='hero-container'>
        {showBreadcrumbs && <BreadcrumbsContainer />}
        <div className='title-primary'>
          {!!h1Title && (
            <H1 fontSkin='title-headline'>
              {h1Title}
            </H1>
          )}
          {showProductHeaderInfo && !!galleryMetadata && !!galleryMetadata?.primaryContent && (
            <div className='main-content-container'>
              <Typography className='main-content'>
                {galleryMetadata?.primaryContent}
                {shouldShowSurveyLink && <SurveyLink />}
              </Typography>
            </div>
          )}
        </div>
        {/* {debugEnabled && (
                    <>
                        <div className="debug-page-url">{`${pathname}${search}`}</div>
                        <BoostedTermsHeader />
                    </>
                )} */}
        {ratingsAndReviews && (
          <RatingsSummary ratingsAndReviews={ratingsAndReviews} />
        )}
        <Divider mt='6' />
      </div>
    </section>
  )
}))
