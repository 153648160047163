import { DESIGN_CREATION_TYPE } from '~/shared/constants'
import { PreviouslyOrderedAction, PREVIOUSLY_ORDERED_UPDATE } from './actions'

const initialState: State.PreviouslyOrdered = {
  product: {
    id: '',
    options: [],
  },
  productOptions: {},
  designId: '',
  designConceptId: '',
  legacyComboId: '',
  fullProductOptions: {},
  studioUrl: '',
  colorSwatches: [],
  previewUrls: {
    size1x: '',
    size2x: '',
  },
  productKey: '',
  productVersion: 0,
  previewInfo: {
    templateToken: '',
    designId: '',
    dps: '',
    aspectRatio: 0,
    finishType: '',
    instructionsUrl: undefined,
    scenes: undefined,
  },
  isForceRanked: false,
  designCreationType: DESIGN_CREATION_TYPE.DYNAMIC,
  numberOfPlaceholders: '',
  availableTaxonomyVariationsForExpansion: {},
  keywords: [],
  categoryNames: [],
  categoryKeys: [],
  colorComposition: [],
  externalId: '',
  templateUseCase: '',
}

export function reducer (
  state: State.PreviouslyOrdered = initialState,
  action: PreviouslyOrderedAction
): State.PreviouslyOrdered {
  switch (action.type) {
    case PREVIOUSLY_ORDERED_UPDATE:
      return action.payload.previouslyOrdered
    default:
      return state
  }
}
