import { lruMemoize } from 'reselect'

import { defaultFilterOptionIdsSelector } from '../filterOption'
import { filterByOptionSelector } from '../filterByOption'
import {
  refinementIdsByFilter,
} from '.'
import { filterByCategorySelector } from '../filterByCategory'
import { REFINEMENT_DIMENSION } from 'shared/constants'
import { filterByTemplateUseCaseSelector } from '../filterByTemplateUseCase'

// Used to translate refinements from the URL into a format accepted by refinementIdsByFilter
export const refinementIdsToRefinementsDictionary = lruMemoize(
  (refinements: string[], dimension: string) => refinements.reduce(
    (memo, id) => ({
      ...memo,
      [id]: {
        dimension,
        value: id,
      },
    }),
    {} as Util.StringDictionary<State.Refinement>
  )
)

/**
 * Returns the input list of refinements plus default values added where no refinements exist for that filter
 * @param refinementIds List of string ids
 * @param state GlobalState
 */
export const addDefaultsToRefinements = (refinementIds: string[], state: State.GlobalState): string[] => {
  const defaultRefinements = defaultFilterOptionIdsSelector(state)
  const filterByOption = filterByOptionSelector(state)
  const filterByCategory = filterByCategorySelector(state)
  const filterByUseCase = filterByTemplateUseCaseSelector(state)
  const attributeRefinements = refinementIdsToRefinementsDictionary(
    refinementIds,
    REFINEMENT_DIMENSION.ATTRIBUTE
  )

  const refinementIdsByType = refinementIdsByFilter(
    attributeRefinements,
    filterByOption,
    filterByCategory,
    filterByUseCase
  )

  const defaultRefinementsToAdd = defaultRefinements.filter((defaultRefinement) => {
    const filter = filterByOption(defaultRefinement)

    return !refinementIdsByType.attributes[filter]
  })

  return defaultRefinementsToAdd.concat(refinementIds)
}
