import { AnyAction } from 'redux'

const initialState: State.FilterCategoryState = {}

export function reducer (
  state: State.FilterCategoryState = initialState,
  action: AnyAction
): State.FilterCategoryState {
  switch (action.type) {
    default:
      return state
  }
}

export const categoryByIdSelector =
  (state: State.GlobalState) =>
    (categoryId: string): State.Category =>
      state.filterCategories[categoryId]

export const taxonomyIdByCategoryIdSelector =
  (state: State.GlobalState) =>
    (categoryId: string): string => {
      return state.taxonomyIdByCategoryId[categoryId]
    }
