import config from 'config'
import qs from 'query-string'

import { AbstractService, AbstractServiceConfig, LoggerParameter } from 'services/AbstractService'
import { ServiceError } from 'services/errors'
import { AxiosError } from 'axios'

export class ProductCatalogPricingService extends AbstractService implements VP.PCT.Services.ProductCatalogPricingService.IProductCatalogPricingService {
  apiVersion: number

  constructor ({ version, ...rest }: AbstractServiceConfig, logger: LoggerParameter) {
    super(rest, logger)
    this.apiVersion = version
  }

  /**
    * Request Differential Price from PCT's Product Catalog Pricing Service
     * @param market
     * @param productKey
     * @param quantity
     * @param choiceGroups
     * @param pricingContext
     * @param couponCode
     * @param effectiveDateTime
     * @param customerGroups
     */
  public async getDifferentialPrice (
    market: string,
    productKey?: string,
    quantity?: number,
    productVersion?: number,
    choiceGroups?: VP.PCT.Models.ProductCatalogPricingService.ChoiceGroups,
    pricingContext?: string,
    couponCode?: string,
    effectiveDateTime?: string,
    customerGroups?: string[],
    selections?: VP.PCT.Models.ProductCatalogPricingService.ChoiceGroup
  ): Promise<VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult | null> {
    if (!quantity || !productKey || !productVersion) {
      throw new Error(
        'Unable to call getDifferentialPrice. quantity, productKey, and productVersion are required '
      )
    }

    const choiceGroupsObj = Object.entries(choiceGroups || {}).reduce((newObj, [key, value]) => {
      // If no product options in choice group, we still need to
      // return a non-empty object so choice group price is calculated
      const valueEntries = Object.entries(value)

      if (!valueEntries.length) {
        newObj[`ChoiceGroups[${key}][${''}]`] = ''
      } else {
        valueEntries.forEach(([key2, value2]) => {
          const choiceGroupKey = `ChoiceGroups[${key}][${key2}]`

          newObj[choiceGroupKey] = value2
        })
      }
      return newObj
    }, {} as VP.PCT.Models.ProductCatalogPricingService.ChoiceGroup)

    const selectionsObj = Object.entries(selections || {}).reduce((newObj, [key, value]) => {
      newObj[`Selections[${key}]`] = value

      return newObj
    }, {} as VP.PCT.Models.ProductCatalogPricingService.ChoiceGroup)

    const params = {
      ProductKey: productKey,
      Quantity: quantity,
      ProductVersion: productVersion,
      Market: market,
      MerchantId: 'vistaprint',
      PricingContext: pricingContext,
      CouponCode: couponCode,
      EffectiveDateTime: effectiveDateTime,
      CustomerGroups: customerGroups,
      ...selectionsObj,
      ...choiceGroupsObj,
    }

    const url = `v4/prices/startingAt/differential?${qs.stringify(params)}`

    try {
      const response = await this.api.get<VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult>(
        url,
        {
          params: {
            requestor: config.appName,
          },
        }
      )

      if (!response) {
        throw new Error('response was empty')
      }

      return response.data
    } catch (e) {
      this.logger.error(new ServiceError({
        url,
        message: `Bad response from differential pricing service: ${(e as Error).message}`,
        response: (e as AxiosError).response,
      }, e as Error))
      return null
    }
  }

  /**
    * Request Pricing for all quantities from PCT's Product Catalog Pricing Service
     * @param market
     * @param productKey
     * @param quantity
     * @param choiceGroups
     * @param pricingContext
     * @param couponCode
     * @param effectiveDateTime
     * @param customerGroups
     */
  public async getQuantityPrices (
    market: string,
    productKey?: string,
    quantities?: number[],
    productVersion?: number,
    pricingContext?: string,
    selections?: VP.PCT.Models.ProductCatalogPricingService.ChoiceGroup
  ): Promise<VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult | null> {
    if (!quantities || !productKey || !productVersion) {
      throw new Error(
        'Unable to call getQuantityPrices. quantities, productKey, and productVersion are required '
      )
    }

    const selectionsObj = Object.entries(selections || {}).reduce((newObj, [key, value]) => {
      newObj[`Selections[${key}]`] = value

      return newObj
    }, {} as VP.PCT.Models.ProductCatalogPricingService.ChoiceGroup)

    const params = {
      ProductKey: productKey,
      ProductVersion: productVersion,
      Market: market,
      MerchantId: 'vistaprint',
      PricingContext: pricingContext,
      OptionalPriceComponents: 'UnitPrice',
      Quantities: quantities,
      ...selectionsObj,
    }

    const url = `v4/prices/startingAt/estimated?${qs.stringify(params)}`

    try {
      const response = await this.api.get<VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult>(
        url,
        {
          params: {
            requestor: config.appName,
          },
        }
      )

      if (!response) {
        throw new Error('response was empty')
      }

      return response.data
    } catch (e) {
      this.logger.error(new ServiceError({
        url,
        message: `Bad response from differential pricing service: ${(e as Error).message}`,
        response: (e as AxiosError).response,
      }, e as Error))
      return null
    }
  }
}
