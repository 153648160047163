import {
  SWAN_BASE_URL_MAP, SWAN_PUBLIC_ASSET_FOLDER, SwanAssetFolders, getSwanAssetFolderName,
} from '@vp/swan'
import config from 'config'

export const ICON_SIZES = ['152x152', '120x120', '76x76', '60x60']
// TODO: repalce with SWAN responsive system
export const SMALL_SCREEN_WIDTH = 768

export const FAV_ICON_FOLDER = `${SWAN_BASE_URL_MAP.relative
}${SWAN_PUBLIC_ASSET_FOLDER}/${getSwanAssetFolderName(
    SwanAssetFolders.FAVICONS
)}`

export const DEFAULT_CONTENT_BACKGROUND_COLOR = 'rgb(#ffffff)'

export enum TILE_PREVIEW_SIZE {
  DEFAULT = 'Default',
  LARGE = 'Large',
}

export enum LOADING_TYPE {
  EAGER = 'eager',
  LAZY = 'lazy',
}

export const DEFAULT_EAGER_IMAGES = 8

export const DEFAULT_TENANT = 'vistaprint'

export const DEFAULT_TENANT_ID_PREFIX = 'VP'

export const WES_TENANT = `vp-template-favorites-${config.client.deployment.toLowerCase()}`

export const MAXIMUM_PREVIEW_HEIGHT = 300

export const MINIMUM_PREVIEW_HEIGHT = 165

export const MINIMUM_TILE_DEFAULT_WIDTH = 206

export const MINIMUM_TILE_LARGE_WIDTH = 258

// @pricing-line-height * 2
export const MINIMUM_PRICING_HEIGHT = 48

export const SINGLE_SPACE_ENCODED = '&nbsp;'

export enum PRICING_PRESENTATION_TYPES {
  QTY_FROM_PRICE = 'qtyFromPrice',
  AS_LOW_AS_PRICE_PER_PIECE = 'asLowAsPricePerPiece',
  PRICE_PER_UNIT = 'pricePerUnit',
  RAW_PRICE = 'rawPrice',
  QTY_DIFFERENTIAL_PRICE = 'plusOrMinus',
  DIFFERENTIAL_PRICE_PER_UNIT = 'plusOrMinusPerUnit',
  QTY_STARTING_AT_PRICE = 'qtyStartingAtPrice',
}

// Actual max is 10k, leaving a bit of wiggle room for rounding errors
export const MAXIMUM_RENDERED_IMAGE_DIMENSION = 9800

export const CHOICE_GROUP_BATCH_SIZE = 24

// 14 minutes - 1 minute less than cache time
export const PRICING_STALE_TIME = 840000
export const PRICING_CACHE_TIME = 900000

export enum PAGE_ACTION_TYPES {
  GALLERY_CONFIG_LOAD_FAILED = 'galleryConfigurationLoadFailed',
  GALLERY_CONFIG_METADATA_LOAD_FAILED = 'galleryConfigurationMetadataLoadFailed',
  CONTENT_LOAD_FAILED = 'contentLoadFailed',
  PREVIEW_IMAGE_FAILURE = 'previewImageFailure',
  FAVORITES_UPDATE_FAILED = 'favoritesUpdateFailed',
  LOADED_FAVORITES = 'loadedFavorites',
  FAVORITE_BUTTON_CLICKED = 'favoriteButtonClicked',
  PRODUCT_CONFIG_LOAD_FAILED = 'productConfigurationLoadFailed',
  QUICKVIEW_OPENED = 'quickViewOpened',
  VORTEX_RENDER_FAILURE = 'vortexFailure',
  FAVORITE_DUPLICATE_DETECTED = 'favoriteDuplicateDetected',
  ADD_TO_CART_SUCCESS = 'addToCart',
  ADD_TO_CART_FAILURE = 'addToCartFailure',
  IMAGE_UPLOAD_SUCCESS = 'imageUploadSuccess',
  IMAGE_UPLOAD_FAILURE = 'imageUploadFailure',
  IMAGE_PROCESSING_SUCCESS = 'imageProcessingSuccess',
  IMAGE_PROCESSING_FAILURE = 'imageProcessingFailure',
  IMAGE_LOADING_SUCCESS = 'imageLoadingSuccess',
  IMAGE_LOADING_FAILURE = 'imageLoadingFailure',
  SEGMENT_EVENT_FAILURE = 'segmentEventFailure',
}

export enum LOCAL_STORAGE_KEYS {
  PERSISTED_BRAND_APPLIED = 'PERSISTED_BRAND_APPLIED',
  PERSISTED_DPSESSION = 'PERSISTED_DPSession'
}

export const SELECTED_PRODUCT_QSP = 'selectedProduct'

export enum IMAGE_UPLOAD_STATES {
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

export const MAX_PHOTO_UPLOADS = 10

// Routes
export const NOT_FOUND_PATH = '/:locale/404'
export const ERROR_PATH = '/:locale/error'

export const QV_PREVIEW_ID = 'quickview-preview'

export const MAX_TEMPLATE_USE_CASE_DEPTH_LEVEL = 2

export const MAX_COLOR_WEIGHT_DIFFERENCE_PERCENT = 5

export const PRIMARY_COLOR_SWATCH_INDEX = 0
export const SECONDARY_COLOR_SWATCH_INDEX = 1
export const SIMILARITY_COLOR_SWATCH_RATIO = 0.5
export const COLOR_SWATCH_DIFFERENCE_THRESHOLD = 25
