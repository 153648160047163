/* eslint-disable camelcase */
import en_ie from 'assets/i18n/en-ie.json'
import pt_pt from 'assets/i18n/pt-pt.json'
import es_es from 'assets/i18n/es-es.json'
import en_in from 'assets/i18n/en-in.json'
import de_at from 'assets/i18n/de-at.json'
import fr_be from 'assets/i18n/fr-be.json'
import nl_be from 'assets/i18n/nl-be.json'
import fr_ca from 'assets/i18n/fr-ca.json'
import en_ca from 'assets/i18n/en-ca.json'
import de_ch from 'assets/i18n/de-ch.json'
import fr_ch from 'assets/i18n/fr-ch.json'
import it_ch from 'assets/i18n/it-ch.json'
import en_nz from 'assets/i18n/en-nz.json'
import en_gb from 'assets/i18n/en-gb.json'
import en_us from 'assets/i18n/en-us.json'
import es_us from 'assets/i18n/es-us.json'
import en_au from 'assets/i18n/en-au.json'
import de_de from 'assets/i18n/de-de.json'
import da_dk from 'assets/i18n/da-dk.json'
import fi_fi from 'assets/i18n/fi-fi.json'
import fr_fr from 'assets/i18n/fr-fr.json'
import it_it from 'assets/i18n/it-it.json'
import nl_nl from 'assets/i18n/nl-nl.json'
import nb_no from 'assets/i18n/nb-no.json'
import sv_se from 'assets/i18n/sv-se.json'
import en_sg from 'assets/i18n/en-sg.json'

import { ParameterizedContext } from 'koa'

export const DEFAULT_LOCALE = 'en-us'

// @ts-expect-error
const mapping: i18n.Translations = {
  'en-ie': en_ie,
  'pt-pt': pt_pt,
  'es-es': es_es,
  'en-in': en_in,
  'de-at': de_at,
  'fr-be': fr_be,
  'nl-be': nl_be,
  'fr-ca': fr_ca,
  'en-ca': en_ca,
  'de-ch': de_ch,
  'fr-ch': fr_ch,
  'it-ch': it_ch,
  'en-nz': en_nz,
  'en-gb': en_gb,
  'en-us': en_us,
  'es-us': es_us,
  'en-au': en_au,
  'de-de': de_de,
  'da-dk': da_dk,
  'fi-fi': fi_fi,
  'fr-fr': fr_fr,
  'it-it': it_it,
  'nl-nl': nl_nl,
  'nb-no': nb_no,
  'sv-se': sv_se,
  'en-sg': en_sg,
}

mapping.default = mapping[DEFAULT_LOCALE]

// This dictionary will need to be updated very infrequently, so put it directly in code
const defaultLocaleDict: { [tld: string]: i18n.Locale | string } = {
  com: 'en-us',
  uk: 'en-gb',
  de: 'de-de',
  biz: 'en-gb',
  ca: 'en-ca',
  au: 'en-au',
  fr: 'fr-fr',
  it: 'it-it',
  se: 'sv-se',
  nl: 'nl-nl',
  es: 'es-es',
  be: 'nl-be',
  ch: 'de-ch',
  ie: 'en-ie',
  nz: 'en-nz',
  no: 'nb-no',
  dk: 'da-dk',
  at: 'de-at',
  fi: 'fi-fi',
  pt: 'pt-pt',
  sg: 'en-sg',
  in: 'en-in',
}

export function getTranslationMap (locale: i18n.Locale): i18n.TranslationMap {
  return {
    ...mapping.default,
    ...mapping[locale] || null,
  }
}

export function findDefaultLocaleFromRequest (ctx: ParameterizedContext): i18n.Locale | null {
  const host = (ctx.request.header['x-forwarded-host'] as string) || ctx.request.host

  if (host) {
    let { length } = host

    // This is here so we can test with a port number in the host
    const colonPosition = host.lastIndexOf(':')

    if (colonPosition >= 0) {
      length = colonPosition
    }

    const locale = host && host.substring(host.lastIndexOf('.') + 1, length).toLowerCase()

    if (locale && locale in defaultLocaleDict) {
      return defaultLocaleDict[locale] as i18n.Locale
    }
  }

  return null
}

export function getLocaleFromUrl (ctx: ParameterizedContext): i18n.Locale {
  if (ctx.params && ctx.params.locale) {
    return ctx.params.locale.toLowerCase()
  }

  return findDefaultLocaleFromRequest(ctx) || DEFAULT_LOCALE
}
