import config from 'config'
import { QueryFunctionContext } from '@tanstack/react-query'
import { MsxService } from 'services/MsxService'
import { getLogger } from 'client/utils/gallery/logger'
import { DEFAULT_QUANTITIES_QUERY_KEY } from 'shared/constants'

export const QUERY_KEY = DEFAULT_QUANTITIES_QUERY_KEY

const msxService = new MsxService(config.services.msxService, getLogger)

export const queryDefaultQuantities = (
  context: QueryFunctionContext<VP.MTT.Queries.MsxService.DefaultQuantitiesQueryKey>
): Promise<VP.MTT.Models.MsxService.DefaultQuantitiesResult | null> => {
  const { queryKey } = context
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, {
    productKey,
    version,
    tenant,
    locales,
  }] = queryKey

  return msxService.getDefaultQuantities(
    tenant,
    locales,
    productKey,
    version
  )
}
