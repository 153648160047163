// TODO implement analytics??
// export const useAnalytics = (): Gallery.Analytics.IAnalytics => useContext(AnalyticsContext) as Gallery.Analytics.IAnalytics

export const useAnalytics = (): any => ({
  getPageProperties: () => ({
    pageSection: 'todo',
    pageStage: 'todo',
    pageName: 'todo'
  }),
  initialize: () => {
    // TODO: Add initialization logic
  },
  hydrate: () => {
    // TODO: Add hydration logic
  },
  updateState: () => {
    // TODO: Add state update logic
  },
  getIncrementedSessionStep: () => {
    // TODO: Return incremented session step
    return 0
  },
  trackEvent: () => {
    // TODO: Add event tracking logic
  },
  trackPage: () => {
    // TODO: Add page view tracking logic
  },
  trackNavigation: () => {
    // TODO: Add navigation tracking logic
  },
  trackProductViewed: () => {
    // TODO: Add product viewed tracking logic
  },
  trackImpression: () => {
    // TODO: Add impression tracking logic
  },
  trackExperiment: () => {
    // TODO: Add experiment tracking logic
  },
  trackAttributeSelected: () => {
    // TODO: Add attribute selected tracking logic
  },
  trackProductOptionsSelected: () => {
    // TODO: Add product options selected tracking logic
  },

})
