import { Action } from 'redux'
import { contentUpdate } from '../content'

export const REFINEMENT_UPDATE = 'REFINEMENT_UPDATE'

interface RefinementUpdatePayload {
  add?: State.Refinement[];
  remove?: State.Refinement[];
}

export interface RefinementUpdateAction extends Action {
  type: string;
  payload: RefinementUpdatePayload;
}

export const buildRefinement = (value: string, dimension: string, other?: Record<string, any>): State.Refinement => ({
  value,
  dimension,
  ...other,
})

export const refinementBulkUpdate = (refinementToAdd?: State.Refinement[], refinementToRemove?: State.Refinement[]): RefinementUpdateAction => ({
  payload: {
    add: refinementToAdd,
    remove: refinementToRemove,
  },
  type: REFINEMENT_UPDATE,
})

export const refinementAddAndRemove = (
  refinementToAdd?: State.Refinement[],
  refinementToRemove?: State.Refinement[]
): Gallery.ContentQuery.Action => contentUpdate({
  actions: refinementBulkUpdate(refinementToAdd, refinementToRemove),
})

export const refinementAdd = (refinement: State.Refinement[]): Gallery.ContentQuery.Action => refinementAddAndRemove(refinement, undefined)

export const refinementRemove = (refinement: State.Refinement[]): Gallery.ContentQuery.Action => refinementAddAndRemove(undefined, refinement)
