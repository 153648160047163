import { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { useRecoilValue } from 'recoil'
import { UploadFlow } from '@vp/upload-flow'
import { v4 as uuid } from 'uuid'

import { ANALYTICS_CATEGORY, ANALYTICS_EVENT_ACTIONS, ANALYTICS_LABEL } from '~/shared/constants'
import { instrumenter } from '~/client/utils/instrumentation'
import {
  getProductKey,
  getProductVersion,
  getLocaleSelector,
  getQuantitySelector,
  getGalleryNameSelector,
  getMpvid,
  getTenant,
  getH1Title,
} from 'client/store/config'
import { useAnalytics } from 'client/hooks/gallery/useAnalytics'
import { userOptionsSelector } from 'client/store/refinement/selectors'
// import { useAuth } from '~/client/hooks/useAuth'
import { pricingContextAtom } from '~/client/atoms/pricingContext'
import { UploadTile } from '~/client/components/Gallery/PreviewArea/FullBleedUploadTile/FullBleedUploadTile/UploadTile'

export type PropTypes = Gallery.Analytics.ButtonLocationProp & React.HTMLProps<HTMLElement> & {
  previewHeight: number;
  productOptionsByKey: Gallery.Product.ProductOptionsGroupsByProductKey | null;
  pricingPresentationType?: string | null;
}

export const FullBleedUploadTile = forwardRef<HTMLLIElement, PropTypes>((props: PropTypes, ref) => {
  /**
     * Selectors
     */
  const analytics = useAnalytics()
  const tenant = useSelector(getTenant)
  const locale = useSelector(getLocaleSelector)
  const mpvId = useSelector(getMpvid)
  const quantity = useSelector(getQuantitySelector)
  const productKey = useSelector(getProductKey)
  const name = useSelector(getGalleryNameSelector)
  const title = useSelector(getH1Title)
  const userOptions = useSelector(userOptionsSelector)
  const productVersion = useSelector(getProductVersion)

  const pricingContext = useRecoilValue(pricingContextAtom)
  const retrievedPricingContext = pricingContext?.getPricingContext()
  const vatInclusivity = retrievedPricingContext?.vatInclusive || false
  const pricingContextString = pricingContext?.getEncodedContextString()
  const useFullPath = true
  const { pageSection, pageStage, pageName } = analytics.getPageProperties()

  /**
     * Handlers
     */
  const handlePopupOpen = (selections: Record<string, string>): void => {
    analytics.trackEvent({
      userInteractionLocation: props.userInteractionLocation,
      action: ANALYTICS_EVENT_ACTIONS.DESIGN_POPUP_DISPLAYED,
      category: ANALYTICS_CATEGORY.DESIGN_UPLOAD,
      eventDetail: JSON.stringify(selections),
      eventLabel: ANALYTICS_LABEL.OPTIONS_DISPLAYED,
      ...analytics.buildFullBleedUploadMetaData(),
    })
  }

  // const auth = useAuth()

  const handleSubmit = (selections: Record<string, string>): void => {
    instrumenter.recordDesignSelection(selections, 'fbu')

    const fullBleedEngagementId = uuid()

    analytics.trackEvent({
      userInteractionLocation: props.userInteractionLocation,
      action: ANALYTICS_EVENT_ACTIONS.DESIGN_POPUP_DISPLAYED,
      category: ANALYTICS_CATEGORY.DESIGN_UPLOAD,
      eventDetail: JSON.stringify(selections),
      eventLabel: ANALYTICS_LABEL.OPTIONS_VALIDATION,
      fullbleedEngagementId: fullBleedEngagementId,
      ...analytics.buildFullBleedUploadMetaData(),
    })
  }

  return (
    <div className='full-bleed-upload-tile'>
      <UploadTile {...props} ref={ref} />
      {/* TODO UploadFlow doesn't want to work in the browser because of 'node-cache' */}
      {/* <UploadFlow
        analyticsPageData={{
          pageSection,
          pageStage,
          pageName,
          productName: name,
          coreProductId: productKey,
          position: 0,
        }}
        // TODO fix auth
        // authToken={auth?.accessToken ?? undefined}
        loader={<UploadTile {...props} ref={ref} />}
        locale={locale}
        mpvId={mpvId}
        pricingContextString={pricingContextString ?? ''}
        productKey={productKey}
        productName={title}
        productVersion={productVersion ?? 0}
        quantity={quantity as number}
        requestor='gallery6-client'
        tenant={tenant}
        useFullPath={useFullPath}
        userOptions={userOptions as Record<string, string>}
        vatInc={vatInclusivity}
        onPopupOpen={handlePopupOpen}
        onSubmit={handleSubmit}
      >
        <UploadTile {...props} ref={ref} />
      </UploadFlow> */}
    </div>
  )
})
