import { JSX, MouseEvent } from 'react'
import { FlexBox, Link } from '@vp/swan'
import { RatingReviewData } from '@vp/rating-reviews-component'
import { RatingSummary } from '@vp/rating-reviews-component/build/pure/index'

import { reviewsId } from '~/client/components/Gallery/Footer/ReviewsCallout'
import { scrollToElement } from '~/client/utils/scrollToElement'
import { ANALYTICS_CATEGORY } from '~/shared/constants'
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics'
import { useSelector } from 'react-redux'
import { getMpvid, getProductKey } from '~/client/store/config/reducer'

export interface PropTypes {
  ratingsAndReviews: RatingReviewData;
}

export const RatingsSummary = ({ ratingsAndReviews }: PropTypes): JSX.Element | null => {
  const productKey = useSelector(getProductKey)
  const mpvId = useSelector(getMpvid)

  const analytics = useAnalytics()

  const trackingData = {
    ...analytics.getPageProperties(),
    category: ANALYTICS_CATEGORY.GALLERY,
    productId: mpvId,
    coreProductId: productKey,
  }

  const handleScrollToReviews = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault()

    scrollToElement(reviewsId, { behavior: 'smooth' })
  }
  const ratingSummaryData = {
    ...ratingsAndReviews.summary,
    isClickable: true,
  }

  return (
    <FlexBox alignItems='center' fontSize='small'>
      {/* esl int -disable-next-line jsx-a11y/anchor-is-valid */}
      <Link className='rating-link' onClick={handleScrollToReviews}>
        <RatingSummary
          {...ratingSummaryData}
          trackingData={trackingData}
        />
      </Link>
    </FlexBox>
  )
}

RatingsSummary.displayName = 'RatingsSummary'
