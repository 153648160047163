import { useSelector } from 'react-redux'
import { RatingReviewData } from '@vp/rating-reviews-component'

import { getLocaleSelector, getMpvid } from '~/client/store/config'
import { useQueryRatingsAndReviews } from '~/client/hooks/useQueryRatingsAndReviews'
import { getTlpLevel } from '~/client/store/config/reducer'
import { useUserContext } from '@vp/ubik-context'

export const useRatingsAndReviews = (): RatingReviewData | undefined => {
  const locale = useSelector(getLocaleSelector)
  const mpvId = useSelector(getMpvid)
  const tlpLevel = useSelector(getTlpLevel)
  const tenant = useUserContext().tenant // todo check this

  const { data: ratingsData } = useQueryRatingsAndReviews(tenant, locale, mpvId, tlpLevel, {
    enabled: !!tenant && !!locale && !!mpvId,
    staleTime: Infinity,
    retry: false,
  })

  return ratingsData
}
