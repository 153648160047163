import { AnyAction } from 'redux'

const initialState: State.FilterByOptionState = {}

export function reducer (
  state: State.FilterByOptionState = initialState,
  action: AnyAction
): State.FilterByOptionState {
  switch (action.type) {
    default:
      return state
  }
}

export const optionNameByFilterNameSelector = (state: State.GlobalState) => (optionName: string): string => state.optionNameByFilterName[optionName]
