import { AnyAction } from 'redux'

export function reducer (
  state: State.ExperimentationState | null = null,
  action: AnyAction
): State.ExperimentationState | null {
  switch (action.type) {
    default:
      return state
  }
}

export const getRawExperiments = (state: State.GlobalState): string | undefined => state.experimentation?.rawExperiments
