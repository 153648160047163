import { RemovedTemplateAction, REMOVED_TEMPLATE_UPDATE } from './actions'

export function reducer (
  state: State.RemovedTemplateForPreviouslyOrdered = null,
  action: RemovedTemplateAction
): State.RemovedTemplateForPreviouslyOrdered {
  switch (action.type) {
    case REMOVED_TEMPLATE_UPDATE:
      return action.payload.removedTemplateForPreviouslyOrdered
    default:
      return state
  }
}
