import { FilterOption } from '~/client/components/Gallery/Filters/FilterOption'
import { MultiSelectFilter } from '~/client/components/Gallery/Filters/MultiSelect'

interface PropTypes {
  filter: State.Filter;
}

export const CheckboxFilter = (props: PropTypes) => {
  const { filter } = props

  return (
    <MultiSelectFilter filter={filter as State.AttributeFilter}>
      {(o: RenderableFilterOption) => (
        <FilterOption facetCount={o.facetCount} title={o.title} />
      )}
    </MultiSelectFilter>
  )
}
