import { filterByCategorySelector } from '../filterByCategory'
import { defaultFilterOptionIdsSelector, filterOptionByIdSelector } from '../filterOption'
import { filterByIdSelector } from '../filter'
import { booleanRenderPropertySelector, selectedOptionsSelector } from '../config'
import { filterByOptionSelector } from '../filterByOption'
import { filterByTemplateUseCaseSelector } from '../filterByTemplateUseCase'
import { createSelector } from 'reselect'
import { REFINEMENT_DIMENSION } from 'shared/constants'
import { RefinementsByType } from './types'
import { RenderProperty } from 'shared/renderProperties'
import { getCareData } from '../care'

export const filterRefinementsSelector = createSelector(
  (state: State.GlobalState) => state.refinements,
  (refinements: State.RefinementState) => Object.values(refinements).filter((refinement) => (
    refinement.dimension === REFINEMENT_DIMENSION.ATTRIBUTE ||
        refinement.dimension === REFINEMENT_DIMENSION.TEMPLATE_USE_CASE ||
        refinement.dimension === REFINEMENT_DIMENSION.CATEGORY))
)

export const attributeRefinementsSelector = createSelector(
  (state: State.GlobalState) => state.refinements,
  (refinements: State.RefinementState) => Object.values(refinements).filter((refinement) => (
    refinement.dimension === REFINEMENT_DIMENSION.ATTRIBUTE))
)

export const categoryRefinementsSelector = createSelector(
  (state: State.GlobalState) => state.refinements,
  (refinements: State.RefinementState) => Object.values(refinements).filter((refinement) => (
    refinement.dimension === REFINEMENT_DIMENSION.CATEGORY))
)

export const templateUseCaseRefinementsSelector = createSelector(
  (state: State.GlobalState) => state.refinements,
  (refinements: State.RefinementState) => Object.values(refinements).filter((refinement) => (
    refinement.dimension === REFINEMENT_DIMENSION.TEMPLATE_USE_CASE))
)

export const keywordRefinementSelector = (state: State.GlobalState): State.Refinement => (state.refinements.keyword)

export const collectionRefinementSelector = (state: State.GlobalState): State.Refinement => (state.refinements.collection)

export const refinementsSelector = (state: State.GlobalState): State.RefinementState => state.refinements

export const refinementsHasRefSelector = (state: State.GlobalState) => (refinement: string): boolean => state.refinements && (refinement in state.refinements)

// This is separated from the selector because the logic is reused when reading refinements from the URL
export const refinementIdsByFilter = (
  refinements: Util.StringDictionary<State.Refinement>,
  filterByOption: (id: string) => string,
  filterByCategory: (id: string) => string,
  filterByTemplateUseCase: (id: string) => string
): Refinements.RefinementsByFilter => Object.values(refinements).reduce((memo, ref: State.Refinement) => {
  const filterName = filterByOption(ref.value)
  const catFilterName = filterByCategory(ref.value)
  // TODO Remove due to  https://gitlab.com/vistaprint-org/gallery/gallery6-client/-/merge_requests/2905
  // const templateUseCaseFilterName = filterByTemplateUseCase(ref.value)
  // console.log('templateUseCaseFilterName', templateUseCaseFilterName)

  switch (ref.dimension) {
    case REFINEMENT_DIMENSION.ATTRIBUTE:
      return {
        ...memo,
        attributes: {
          ...memo.attributes,
          [filterName]: [...(memo.attributes[filterName] || [] as string[]), ref.value],
        },
      } as Refinements.RefinementsByFilter
    case REFINEMENT_DIMENSION.CATEGORY:
      return {
        ...memo,
        categories: {
          ...memo.categories,
          [catFilterName]: [...(memo.categories[catFilterName] || [] as string[]), ref.value],
        },
      } as Refinements.RefinementsByFilter
    // case REFINEMENT_DIMENSION.TEMPLATE_USE_CASE:
    //   return {
    //     ...memo,
    //     templateUseCases: {
    //       ...memo.templateUseCases,
    //       [templateUseCaseFilterName]: [
    //         ...(memo.templateUseCases[templateUseCaseFilterName] || [] as string[]), ref.value],
    //     },
    //   } as Refinements.RefinementsByFilter
    case REFINEMENT_DIMENSION.KEYWORD:
      return {
        ...memo,
        keyword: ref.value,
      }
    case REFINEMENT_DIMENSION.COLLECTION:
      return {
        ...memo,
        collection: ref.value,
      }
    default:
      return memo
  }
}, {
  attributes: {},
  categories: {},
  templateUseCases: {},
  keyword: undefined,
  collection: undefined,
} as Refinements.RefinementsByFilter)

export const refinementIdsByFilterSelector = createSelector(
  (state: State.GlobalState) => state.refinements,
  (state: State.GlobalState) => filterByOptionSelector(state),
  (state: State.GlobalState) => filterByCategorySelector(state),
  (state: State.GlobalState) => filterByTemplateUseCaseSelector(state),
  (
    refinements,
    curriedFilterByOptionSelector,
    curriedFilterByCategorySelector,
    curriedFilterByTemplateUseCaseSelector
  ) => refinementIdsByFilter(
    refinements,
    curriedFilterByOptionSelector,
    curriedFilterByCategorySelector,
    curriedFilterByTemplateUseCaseSelector
  )
)

/**
 * Returns a list of selected refinement ids excluding the default values
 * If an attribute has multiple values selected for a filter with a default included,
 *   this function returns all selected values including the default
 */
export const nonDefaultAttributeRefinementIdsSelector = createSelector(
  refinementIdsByFilterSelector,
  defaultFilterOptionIdsSelector,
  (
    refinementsByFilter: RefinementsByType,
    defaultFilterOptions: string[]
  ) => Object.values(refinementsByFilter.attributes || []).reduce(
    (memo, refinements) => {
      const nonDefaultRefinements = refinements.filter(
        (refinement) => (!defaultFilterOptions.includes(refinement))
      )

      // If we have any nonDefault refinements, we need to include all refinements
      // otherwise the defaults will be removed when we load this url
      if (nonDefaultRefinements.length > 0) {
        memo.push(...refinements)
      }

      return memo
    },
    [] as string[]
  )
)

/**
 * Returns a dictionary of filterOptions by filterId for filterIds with an implicit default option selected
 * Used to replace defaults with alternative options in the event of 0 results in Gallery
 * This only works if implicit default values can only be used for radio buttons
 */
export const alternativeToDefaultAttributeRefinementSelector = createSelector(
  (state: State.GlobalState) => state.refinements,
  filterByOptionSelector,
  filterByIdSelector,
  (
    refinements: State.RefinementState,
    filterByOption: (name: string) => string,
    filterById: (name: string) => State.Filter
  ) => Object.keys(refinements).reduce(
    (memo, refinementValue) => {
      const refinement = refinements[refinementValue]

      if (refinement.isImplicitDefault) {
        const filterId = filterByOption(refinement.value)
        // isImplicitDefault will only be set for attribute filters, so this is safe
        const filter = filterById(filterId) as State.AttributeFilter

        return {
          ...memo,
          [filter.name]: filter.options,
        }
      }
      return memo
    },
    {} as Util.StringDictionary<string[]>
  )
)

export const userOptionsSelector = createSelector(
  refinementIdsByFilterSelector,
  filterOptionByIdSelector,
  selectedOptionsSelector,
  (
    refinements: { attributes: Util.StringDictionary<string[]> },
    filterOptionById: (id: string) => Gallery.ConfigApi.FilterOption,
    selectedOptions: Util.StringDictionary<string | number>
  ): Refinements.UserOptions => {
    const { attributes } = refinements
    const filterOptions = Object.keys(attributes).reduce(
      (memo, key) => {
        const options = attributes[key]

        // options with more than one value selected can't be passed to the upload flow service
        if (options.length > 1 || options.length < 1) {
          return memo
        }

        const filterOption = filterOptionById(options[0])

        // make sure we don't add pure content driving attributes
        if (filterOption && filterOption.productOption) {
          const { optionName, optionValue } = filterOption.productOption

          return {
            ...memo,
            [optionName]: optionValue,
          }
        }
        return memo
      },
      {} as Util.StringDictionary<string>
    )

    const userOptions: Refinements.UserOptions = ({ ...filterOptions, ...selectedOptions })

    return userOptions
  }
)

export const shouldHideFbuTileSelector = createSelector(
  userOptionsSelector,
  (state: State.GlobalState) => booleanRenderPropertySelector(state),
  (userOptions, booleanRender): boolean => {
    const isFoilColorSelected = !!userOptions['Foil Color'] && userOptions['Foil Color'] !== 'None'
    const isDisableFullBleedUploadForFoilColor = booleanRender(RenderProperty.DisableFullBleedUploadForFoilColor)
    const isDisableFullBleed = booleanRender(RenderProperty.DisableFullBleed)

    return (isDisableFullBleedUploadForFoilColor && isFoilColorSelected) || isDisableFullBleed
  }
)

export const shouldHideCareTileSelector = createSelector(
  getCareData,
  (careData): boolean => careData?.designServicesAvailable == null || careData.designServicesAvailable === false
)
