const defaultInterpolation = {
  prefix: '<',
  suffix: String.raw`\s?\/>`,
}

export function formatString (
  message: string,
  replacements?: { [key: string]: string },
  suppressError?: boolean,
  interpolation?: { prefix: string, suffix: string }
): string {
  if (replacements) {
    const { prefix, suffix } = interpolation ?? defaultInterpolation
    const regex = String.raw`${prefix}([^>\s]+)${suffix}`

    return message.replace(new RegExp(regex, 'gi'), (token, tokenName) => {
      if (!(tokenName in replacements) && !suppressError) {
        throw new Error(`${token} doesn't exist in message ${message}`)
      }

      return replacements[tokenName]
    })
  }
  return message
}
