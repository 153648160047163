import { AnyAction } from 'redux'

import { CONTENT_UPDATE, CONTENT_REVERT, LOCATION_POP } from '../constants'
import { REFINEMENT_UPDATE } from '../refinement/actions'
import {
  PAGE_SIZE_UPDATE,
  PAGE_UPDATE,
} from '../paging/actions'
import { WARNING_TYPE } from 'shared/constants'
import { TEMPLATE_PURPOSES_UPDATE, SORTING_STRATEGY_UPDATE } from '../debug/actions'

const initialState: State.UxState = {
  showSpinner: false,
  warningType: null,
}

export function reducer (
  state: State.UxState = initialState,
  action: AnyAction
): State.UxState {
  switch (action.type) {
    case PAGE_SIZE_UPDATE:
    case PAGE_UPDATE:
    case REFINEMENT_UPDATE:
    case TEMPLATE_PURPOSES_UPDATE:
    case SORTING_STRATEGY_UPDATE:
    case LOCATION_POP:
      return {
        ...state,
        showSpinner: true,
      }
    case CONTENT_REVERT:
      return {
        ...state,
        showSpinner: false,
        warningType: WARNING_TYPE.Content,
      }
    case CONTENT_UPDATE:
      return {
        ...state,
        showSpinner: false,
        warningType: null,
      }
    default:
      return state
  }
}

export const getShowSpinnerSelector = (state: State.GlobalState): Pick<State.UxState, 'showSpinner'> => ({ showSpinner: state.ux.showSpinner })

export const getShowWarningSelector = (state: State.GlobalState): Pick<State.UxState, 'warningType'> => ({ warningType: state.ux.warningType as WARNING_TYPE })
