import { AnyAction } from 'redux'

export const DEBUG_MODE_ENABLE = 'DEBUG_MODE_ENABLE'
export const TEMPLATE_PURPOSES_UPDATE = 'TEMPLATE_PURPOSES_UPDATE'
export const SORTING_STRATEGY_UPDATE = 'SORTING_STRATEGY_UPDATE'
export const SORTING_STRATEGY_COLLAPSED_UPDATE = 'SORTING_STRATEGY_COLLAPSED_UPDATE'
export const QUICK_VIEW_ID_RESET = 'QUICK_VIEW_ID_RESET'

export const enableDebugMode = (): AnyAction => ({
  type: DEBUG_MODE_ENABLE,
})

export const resetQuickViewId = (): AnyAction => ({
  type: QUICK_VIEW_ID_RESET,
})
