import { combineReducers } from 'redux'

import { attributeNameByFilterNameReducer } from './attributeNameByFilterName'
import { authReducer } from './auth'
import { boostsReducer } from './boosts'
import { careReducer } from './care'
import { configReducer } from './config'
import { debugReducer } from './debug'
import { designReducer } from './design'
import { designPersonalizationReducer } from './designPersonalization'
import { experimentationReducer } from './experimentation'
import { facetReducer } from './facet'
import { filterReducer } from './filter'
import { filterByCategoryReducer } from './filterByCategory'
import { filterByOptionReducer } from './filterByOption'
// import { filterByTemplateUseCaseReducer } from './filterByTemplateUseCase'
import { filterCategoriesReducer } from './filterCategories'
import { filterOptionReducer } from './filterOption'
// import { filterTemplateUseCasesReducer } from './filterTemplateUseCases'
import { optionNameByFilterNameReducer } from './optionNameByFilterName'
import { pagingReducer } from './paging'
// import { parentByChildTemplateUseCaseIdStateReducer } from './parentByChildTemplateUseCaseId'
import { productOptionsByProductKeyReducer } from './productOptionsByProductKey'
import { quickViewFilterReducer } from './quickViewFilter'
import { refinementReducer } from './refinement'
// import { templateUseCaseIdByFilterNameReducer } from './templateUseCaseIdByFilterName'
import { tileEntityReducer } from './tileEntity'
import { uxReducer } from './ux'
import { previouslyOrderedReducer } from './previouslyOrdered'
import { removedTemplateReducer } from './removedTemplateForPreviouslyOrdered'
import { taxonomyIdByFilterNameReducer } from './refinement/taxonomyIdByFilterName'
import { taxonomyIdByCategoryIdStateReducer } from './taxonomyIdByCategoryId'
import { qvFilterNameByTaxonomyIdReducer } from './qvFilterNameByTaxonomyId'

export const rootReducer = combineReducers<State.GlobalState>({
  care: careReducer,
  config: configReducer,
  debug: debugReducer,
  designs: designReducer,
  facets: facetReducer,
  filterByCategory: filterByCategoryReducer,
  filterByOption: filterByOptionReducer,
  optionNameByFilterName: optionNameByFilterNameReducer,
  attributeNameByFilterName: attributeNameByFilterNameReducer,
  filterCategories: filterCategoriesReducer,
  filterOptions: filterOptionReducer,
  filters: filterReducer,
  quickViewFilters: quickViewFilterReducer,
  paging: pagingReducer,
  previouslyOrdered: previouslyOrderedReducer,
  removedTemplateForPreviouslyOrdered: removedTemplateReducer,
  productOptionsByProductKey: productOptionsByProductKeyReducer,
  refinements: refinementReducer,
  tileEntities: tileEntityReducer,
  ux: uxReducer,
  experimentation: experimentationReducer,
  designPersonalization: designPersonalizationReducer,
  taxonomyIdByCategoryId: taxonomyIdByCategoryIdStateReducer,
  taxonomyIdByFilterName: taxonomyIdByFilterNameReducer,
  qvFilterNameByTaxonomyId: qvFilterNameByTaxonomyIdReducer,
  boosts: boostsReducer,
  authData: authReducer,
} as any) // todo missing reducers
