import { Action } from 'redux'

export const BOOSTS_UPDATE = 'BOOSTS_UPDATE'

interface BoostsUpdatePayload {
  boosts: State.BoostsState;
}

export interface BoostsUpdateAction extends Action {
  type: string;
  payload: BoostsUpdatePayload;
}

export const updateBoosts = (boosts: State.BoostsState): BoostsUpdateAction => ({
  payload: {
    boosts,
  },
  type: BOOSTS_UPDATE,
})
