import { createSelector } from 'reselect'
import { AnyAction } from 'redux'

const INITIAL_STATE: State.FilterOptionState = {
  byId: {},
  allIds: [],
}

export function reducer (
  state: State.FilterOptionState = INITIAL_STATE,
  action: AnyAction
): State.FilterOptionState {
  switch (action.type) {
    default:
      return state
  }
}

export const filterOptionByIdSelector = createSelector(
  (state: State.GlobalState) => state.filterOptions,
  (filterOptions) => (id: string): Gallery.ConfigApi.FilterOption => filterOptions.byId[id]
)

export const defaultFilterOptionIdsSelector = createSelector(
  (state: State.GlobalState) => state.filterOptions.byId,
  (
    filterOptionById: Util.StringDictionary<Gallery.ConfigApi.FilterOption>
  ) => Object.values(filterOptionById).reduce(
    (memo, filterOption: Gallery.ConfigApi.FilterOption) => {
      if (filterOption.isDefault) {
        memo.push(filterOption.value)
      }
      return memo
    },
    [] as string[]
  )
)
