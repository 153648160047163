import classnames from 'classnames'
import { JSX, memo } from 'react'

import { useTranslations } from '~/client/hooks/useTranslations'
import { CircularLoader, SpinnerVariant } from '~/client/components/common/LoadingSpinner/CircularLoader'

interface PropTypes extends React.HTMLProps<HTMLElement> {
  /**
     * The animation.
     * If `false` the animation effect is disabled.
     */
  animation?: 'pulse' | 'wave' | false;
  /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
  component?: JSX.Element | string;
  /**
     * Height of the skeleton.
     * Useful when you don't want to adapt the skeleton to a text element but for instance a card.
     */
  height?: number | string;
  /**
     * The type of content that will be rendered.
     */
  variant?: 'text' | 'rect' | 'circle' | 'img';
  /**
     * Width of the skeleton.
     * Useful when the skeleton is inside an inline element with no width of its own.
     */
  width?: number | string;

  isLoading?: boolean;
}

export const Skeleton = memo((props: PropTypes): JSX.Element => {
  const localize = useTranslations()
  const {
    animation = 'wave',
    className,
    component: Component = 'span',
    height,
    isLoading,
    variant = 'text',
    width,
    ...rest
  } = props

  const style = {
    width,
    height,
    ...rest.style,
  }

  return (
  // @ts-ignore
    <Component
      className={classnames(
        'skeleton',
        variant,
        {
          [`${animation}`]: animation !== false,
        },
        className
      )}
      {...rest}
      style={style}
    >
      {(variant === 'img') && (
        <svg name={localize('SkeletonName')} viewBox='0 0 135 105' xmlns='http://www.w3.org/2000/svg'>
          <g>
            <path
              className='placeholder-element'
              d='m0,-0.25l0,106.5l135.37,0l0,-106.5l-135.37,0l0,0zm124.465,94.953l-112.916,0l0,-83.404l112.916,0l0,83.404l0,0z'
            />
            <polygon
              className='placeholder-element'
              points='17.714996337890625,86.864990234375 41.08799743652344,62.295013427734375 49.84700012207031,66.0880126953125 77.47698974609375,36.66099548339844 88.35800170898438,49.675994873046875 93.26199340820312,46.71099853515625 119.82699584960938,86.864990234375'
            />
            <circle className='placeholder-element' cx='44.719' cy='33.417' r='10.21' />
          </g>
        </svg>
      )}
      {variant === 'img' && isLoading && <CircularLoader centering className='circular-loader' variant={SpinnerVariant.Small} />}
    </Component>
  )
})

Skeleton.displayName = 'Skeleton'
