import { contentUpdate } from 'client/store/content'
import { addDefaultsToRefinements } from 'client/store/refinement/refinementTranslation'
import { LOCATION_POP } from 'client/store/constants'

export const stateFromQueryStateUpdate = (queryState: Gallery.Models.Url.QueryStringState) => (
  dispatch: State.GalleryThunkDispatch,
  getState: () => State.GlobalState
): void => {
  const state = getState()

  dispatch(contentUpdate({
    actions: {
      type: LOCATION_POP,
      payload: {
        ...queryState,
        refinements: {
          ...queryState.refinements,
          attributes: addDefaultsToRefinements(queryState.refinements.attributes, state),
        },
      },
    },
    shouldUpdateUrl: false,
  }))
}
