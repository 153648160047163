import { AnyAction } from 'redux'

const initialState: State.QvFilterNameByTaxonomyIdState = {}

export function reducer (
  state: State.QvFilterNameByTaxonomyIdState = initialState,
  action: AnyAction
): Util.StringDictionary<string> {
  switch (action.type) {
    default:
      return state
  }
}

export const qvFilterNameByTaxonomyIdSelector = (state: State.GlobalState): Util.StringDictionary<string> => state.qvFilterNameByTaxonomyId
