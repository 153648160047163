import { AnyAction } from 'redux'
import { createSelector } from 'reselect'

const initialState: State.FilterByTemplateUseCaseState = {}

export function reducer (
  state: State.FilterByTemplateUseCaseState = initialState,
  action: AnyAction
): State.FilterByTemplateUseCaseState {
  switch (action.type) {
    default:
      return state
  }
}

export const filterByTemplateUseCaseSelector = createSelector(
  (state: State.GlobalState) => state.filterByTemplateUseCase,
  (filterByTemplateUseCase) => (id: string): string => filterByTemplateUseCase[id]
)
