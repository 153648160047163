import config from 'config'
import qs from 'query-string'

import { AbstractService } from 'services/AbstractService'
import { ServiceError } from 'services/errors'
import { SCENE_SOUCE } from '~/shared/constants'

const DEFAULT_DESIGN_VARIATIONS_QUERY = {} as DesignVariations.DesignVariationsQuery

export class DesignVariationsService extends AbstractService implements Services.IDesignVariationsService {
  public async getDesignVariations (
    query: DesignVariations.DesignVariationsQuery = DEFAULT_DESIGN_VARIATIONS_QUERY
  ): Promise<DesignVariations.Response> {
    // Note that currently accessing a DPC in design variations causes issues with favorites
    // As we save the diamond preview urls to WES
    // Do not enable until this is solved somehow
    const {
      templateToken,
      locale,
      productKey,
      selectedOptions,
      variableOptions,
      variableAttributes,
      offset,
      limit,
      noCache,
      useConstraints,
      mpvId,
      sceneSource,
      searchBackend,
      useRealisationEngineService,
      designCreationTypes,
      variableTaxonomyIds,
      debug,
    } = query

    const url = `v2/Designs/${templateToken}/Culture/${locale}/DesignVariations`
    const selectedOptionsObj: Util.StringDictionary<string> = {}

    Object.entries(selectedOptions).forEach(([key, value]) => {
      const objKey = `selectedOptions[${key}]`

      selectedOptionsObj[objKey] = value
    })

    try {
      const response = await this.api.get(url, {
        params: {
          productKey,
          offset,
          limit,
          variableOptions,
          variableAttributes,
          useRealisationEngineService,
          designCreationTypes,
          requestor: noCache ? config.noCacheRequestor : config.appName,
          noCache,
          useConstraints,
          mpvId,
          includeColorSwatches: true,
          includePreview: true,
          sceneSource: sceneSource ?? SCENE_SOUCE.GALLERY_SOURCED,
          searchBackend,
          variableTaxonomyIds,
          debug,
          ...selectedOptionsObj,
        },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
      })

      const resp = response.data

      return resp
    } catch (e) {
      throw new ServiceError({
        url,
        message: `Bad response from content query service: ${(e as Error).message}`,
        query,
      }, e as Error)
    }
  }
}
