import { AnyAction } from 'redux'

const initialState: State.TaxonomyIdByFilterNameState = {}

export function reducer (
  state: State.TaxonomyIdByFilterNameState = initialState,
  action: AnyAction
): State.TaxonomyIdByFilterNameState {
  switch (action.type) {
    default:
      return state
  }
}

export const taxonomyIdByFilterNameSelector = (state: State.GlobalState) => (filterName: string): string => state.taxonomyIdByFilterName[filterName]
