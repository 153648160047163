import classNames from 'classnames'

export enum SpinnerVariant {
  Uncontrolled = 'uncontrolled',
  Small = 'small',
  Large = 'large',
  Button = 'button'
}
interface Props extends React.SVGProps<SVGSVGElement> {
  circleColor?: string;
  fragmentColor?: string;
  variant?: SpinnerVariant;
  centering?: boolean;
}
export const CircularLoader = (props: Props): JSX.Element => {
  const {
    circleColor = '#dfdfdf',
    fragmentColor = '#666666',
    variant = SpinnerVariant.Uncontrolled,
    centering = false,
    className,
    ...rest
  } = props
  let classes = classNames(className, { 'easel-loading-spinner-centering': centering })
  let spinColor = fragmentColor

  switch (variant) {
    // current lint rules are aginst cases falling through
    case SpinnerVariant.Button:
      spinColor = '#ffffff'
      classes = classNames(classes, 'easel-loading-spinner-small')
      break
    case SpinnerVariant.Small:
      classes = classNames(classes, 'easel-loading-spinner-small')
      break
    case SpinnerVariant.Large:
      classes = classNames(classes, 'easel-loading-spinner-large')
      break
    case SpinnerVariant.Uncontrolled:
    default:
      break
  }

  return (
    <svg className={classes} preserveAspectRatio='xMidYMid' role='status' viewBox='0 0 100 100' {...rest}>
      <circle
        cx='50'
        cy='50'
        fill='none'
        r='35'
        stroke={circleColor}
        strokeDasharray='164.93361431346415 56.97787143782138'
        strokeWidth='10'
      />
      <circle
        cx='50'
        cy='50'
        fill='none'
        r='35'
        stroke={spinColor}
        strokeDasharray='164.93361431346415 56.97787143782138'
        strokeDashoffset='56.97787143782138'
        strokeWidth='10'
      />
    </svg>
  )
}
CircularLoader.displayName = 'CircularLoader'
