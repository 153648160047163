import { useLogger, useStyles, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { Provider as ReduxProvider } from 'react-redux'
import { RecoilRoot } from 'recoil'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { StaticRouter } from 'react-router-dom/server'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { IntlContext } from '~/client/contexts/IntlContext'
import { useMemo, useEffect } from 'react'
import { configureStore } from '~/client/store/configureStore'
import { Gallery } from './Gallery'
import { stylesheet } from './Gallery/gallery.scss'

export interface GalleryProps {
  initialReduxState: State.GlobalState;
  // Todo is this in redux somewhere?
  translations: i18n.TranslationMap;
  url: string; // URL для сервера
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const Fragment = ({ initialReduxState, translations, url }: GalleryProps) => {
  const logger = useLogger()
  const userContext = useUserContext()
  const isServer = typeof window === 'undefined'
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys([
    'core', 'accordion', 'alertBox', 'button', 'carousel',
    'grid', 'hidden', 'icon', 'listbox', 'modalDialog', 'pagination', 'progressiveImage',
    'selectionSet', 'standardTile', 'toggleSwitch', 'ratingsStars', 'progressBar', 'utility',
    'visible', 'spinner', 'pricing'])

  useStyles(stylesheet)

  const store = configureStore(
    initialReduxState,
    true
  )

  const localizations = useMemo(
    () => ({ messages: translations }),
    [translations]
  )

  const routes = (
    <Routes>
      <Route path='*' element={<Gallery />} />
    </Routes>
  )

  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <IntlContext.Provider value={localizations}>
            <>
              {isServer && <StaticRouter location={url}>{routes}</StaticRouter>}
              {!isServer && <BrowserRouter>{routes}</BrowserRouter>}
            </>
            {/* <Gallery /> */}
          </IntlContext.Provider>
        </RecoilRoot>
      </QueryClientProvider>
    </ReduxProvider>
  )
}
