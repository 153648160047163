import { memo } from 'react'
import { Typography } from '@vp/swan'

interface PropTypes {
  title: string;
  facetCount: number | undefined;
}

export const FilterOption = memo((props: PropTypes) => {
  const { title, facetCount } = props

  return (
    <Typography component='span' fontSize='small'>
      {title}
      {!!facetCount && (<span className='facet-count'>{facetCount}</span>)}
    </Typography>
  )
})

FilterOption.displayName = 'FilterOption'
