import { FunctionComponent } from 'react'
import { Spinner } from '@vp/swan'
import { useTranslations } from '~/client/hooks/useTranslations'

export const LoadingSpinner: FunctionComponent = () => {
  const localize = useTranslations()

  return (
    <div className='loading-spinner'>
      <Spinner accessibleText={localize('Loading')} size='super' />
    </div>
  )
}

LoadingSpinner.displayName = 'LoadingSpinner'
