import { useState } from 'react'
import { ColorSwatchesParams } from '~/client/components/Gallery/DesignTile/interface'
import { getIsColorSwatchesSimilarColors } from '~/client/utils/getColorSwatchColors'

export const useColorSwatches = (
  handleColorSwatchChange: (selectedValue: string) => void,
  maxColorSwatchesPerRow: number,
  colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[]
): ColorSwatchesParams => {
  const [isExpanded, setIsExpanded] = useState(false)

  const shouldShowColorSwatches = colorSwatchObjects.length > 1
  const onColorSwatchChangeWrapper = (selectedValue: string | number | null): void => {
    const stringifiedValue = selectedValue?.toString() || ''

    handleColorSwatchChange(stringifiedValue)
  }

  const handleClickShowMoreButton = (): void => {
    setIsExpanded((prevProps) => !prevProps)
  }

  const shouldShowShowMoreButton = maxColorSwatchesPerRow > 0
    ? maxColorSwatchesPerRow < colorSwatchObjects.length
    : false

  const showMoreIcon = isExpanded ? 'caretUp' : 'caretDown'

  const isColorSwatchColorsSimilar = getIsColorSwatchesSimilarColors(colorSwatchObjects)

  return {
    shouldShowColorSwatches,
    onColorSwatchChangeWrapper,
    handleClickShowMoreButton,
    showMoreIcon,
    isExpanded,
    shouldShowShowMoreButton,
    isColorSwatchColorsSimilar,
  }
}
