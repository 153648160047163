import classNames from 'classnames'
import { forwardRef } from 'react'

export { TileHeader } from '~/client/components/common/Tile/TileHeader'

export interface PropTypes extends React.HTMLProps<HTMLLIElement> {
  type: string;
}

export const Tile = forwardRef<HTMLLIElement, PropTypes>((props: PropTypes, ref) => {
  const { type, className, ...rest } = props

  return (
    <li className={classNames('tile', { [`${type}`]: !!type }, className)} {...rest} ref={ref}>
      {props.children}
    </li>
  )
})

Tile.displayName = 'Tile'
