import { useDispatch, useSelector } from 'react-redux'
import {
  ClearSelectionButtons, VisuallyHidden, Button, ColorSwatch, Typography,
} from '@vp/swan'

import { ClearAllRefinementsButton } from '~/client/components/common/ClearAllRefinementsButton'
import { findAllRemovableRefinements } from '~/client/components/Gallery/Subheader/RefinementsList/findAllRemovableRefinements'
import { refinementRemove, buildRefinement } from '~/client/store/refinement'
import { useTranslations } from '~/client/hooks/useTranslations'
// import { useRedirectToL0 } from '~/hooks/RedirectToL0/useRedirectToL0'
import { excludeRefinement } from '~/client/utils/refinement'
import { getIsL1orL2 } from '~/client/store/config'
import { REFINEMENT_DIMENSION } from '~/shared/constants'

export const RefinementsList = () => {
  const refinements = useSelector(findAllRemovableRefinements)
  const isL1orL2 = useSelector(getIsL1orL2)
  //   const redirectToL0 = useRedirectToL0()

  const dispatch = useDispatch()

  const refinementList = isL1orL2
    ? excludeRefinement(refinements, REFINEMENT_DIMENSION.CATEGORY)
    : Object.values(refinements)

  const hasRefinements = !!refinementList.length
  const localize = useTranslations()

  const curryClearRefinement = (refinementItem: Refinements.RefinementItem) => (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()

    const {
      value,
      dimension,
    } = refinementItem

    const refinementsToRemove = [buildRefinement(value, dimension)]

    // if (redirectToL0([], refinementsToRemove)) {
    //   return
    // }

    dispatch(refinementRemove(refinementsToRemove))
  }

  return (
    <>
      <VisuallyHidden as='h3'>{localize('FiltersRegionLabel')}</VisuallyHidden>
      <ClearSelectionButtons aria-label={localize('AppliedFiltersRegionLabel')} className='refinements-list' role='region'>
        {hasRefinements && (
          <>
            {refinementList.map((refinement) => (
              <Button
                aria-label={localize('ClearRefinementSelection')}
                className='refinement-item-button'
                key={refinement.value}
                skin='clear-selection'
                value={refinement.value}
                onClick={curryClearRefinement(refinement)}
              >
                {refinement.value.startsWith('designcolor') &&
                                    (
                                      <ColorSwatch
                                        primaryColor={refinement.value.split('designcolor_')[1] === 'cream'
                                          ? '#FBEED3'
                                          : refinement.value.split('designcolor_')[1]}
                                      />
                                    )}
                <Typography fontWeight='bold'>
                  {refinement.title}
                </Typography>
              </Button>
            ))}
            <ClearAllRefinementsButton refinements={refinements} />
          </>
        )}
      </ClearSelectionButtons>
    </>
  )
}
