import config from 'config'
import qs from 'query-string'

export class PersonalizedUrlBuilder {
  public static buildDiamondUri (
    templateToken: string,
    culture: string,
    productId: string,
    productOptions: Record<string, string>,
    productVersion: number,
    designPersonalizationContextId?: string
  ): string {
    const params = {
      culture,
      productId,
      productVersion,
      productOptions: qs.stringify(productOptions),
      designPersonalizationContextId,
      requestor: 'TODO' // config.appName,
    }

    return `${config.client.rendering.diamondURL}/v1/templates/${templateToken}/renderableDocument?${qs.stringify(params)}`
  }

  public static buildDiamondInstructionsUri (
    templateToken: string,
    culture: string,
    productId: string,
    productOptions: Record<string, string>,
    productVersion: number,
    designPersonalizationContextId?: string
  ): string {
    const diamondUri = this.buildDiamondUri(
      templateToken,
      culture,
      productId,
      productOptions,
      productVersion,
      designPersonalizationContextId
    )

    return this.buildInstructionsUri(diamondUri)
  }

  public static buildDiamondStudioUrl = (
    instructions: string,
    studioUrl: URL
  ): URL => {
    const diamondUrlString = new URL(instructions).searchParams.get('documentUri')

    if (!diamondUrlString) {
      return studioUrl
    }

    const diamondUrl = new URL(diamondUrlString)
    const updatedUrl = studioUrl
    const path = diamondUrl.pathname.split('/')

    // Safely replace renderableDocument in the path with editableDocument for Studio use
    path[path.indexOf('renderableDocument')] = 'editableDocument'
    diamondUrl.pathname = path.join('/')

    updatedUrl.searchParams.set('documentUrl', diamondUrl.toString())

    return updatedUrl
  }

  private static buildInstructionsUri (realizationUri: string): string {
    const params = {
      documentUri: realizationUri,
      type: 'preview',
    }

    return `${config.client.rendering.udsBaseURL}/v2/transient/vp?${qs.stringify(params)}`
  }
}
