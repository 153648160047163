interface GetDefaultQuantityParams {
  locale: i18n.Locale;
  quantity: Gallery.Models.Url.ValidParsedQsValue<number>,
  quantitiesData?: VP.MTT.Models.MsxService.DefaultQuantitiesResult | null,
}
export const getDefaultQuantity = ({ locale, quantity, quantitiesData }: GetDefaultQuantityParams): number | undefined => {
  const quantities = quantitiesData && quantitiesData[locale]?.quantities

  // Quantity Priority: URL parameter > MSX default quantity > MSX first quantity
  return quantity || quantities?.default
}
