import { AnyAction } from 'redux'

export function reducer (
  state: VP.Care.CareData | null = null,
  action: AnyAction
): VP.Care.CareData | null {
  switch (action.type) {
    default:
      return state
  }
}

export const getCareData = (state: State.GlobalState): VP.Care.CareData | null => state.care
