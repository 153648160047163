import { byId } from 'client/utils/byId'
import { getProductOptionsHash } from '~/client/utils/getProductOptionsHash'
import { buildFullyQualifiedVistaprintUrl } from 'client/utils/vistaprintUrlBuilder'

export type ContentState = {
  designs: State.DesignState;
  productOptionsByProductKeyMap: Gallery.Product.ProductOptionsGroupsByProductKey;
  tileEntities: State.TileEntityState;
}

/**
 * Builds the `facets` state properties from the content result facets
 * @param {Gallery.ContentQuery.Facet[]} facets
 * @return {State.FacetState} Facet State
 */
export function buildFacetState (facets: Gallery.ContentQuery.Facet[]): { facets: State.FacetState } {
  // make sure facet is lower case
  facets.forEach((f) => {
    f.facet = f.facet.toLowerCase()
  })

  return {
    facets: {
      byId: byId<Gallery.ContentQuery.Facet>(facets, 'facet').byId,
    },
  }
}

function buildTaxonomyState (
  tileCategories: string[],
  selectedTaxonomyIds: string[],
  getParentByChildTaxonomyId: (taxonomyId: string) => string
): Record<string, string> {
  const taxonomyVariants = (tileCategories).reduce((accum: Record<string, string>, v: string) => {
    const parent = getParentByChildTaxonomyId(v)
    if (parent) {
      return {
        ...accum,
        [parent]: v,
      }
    }

    return accum
  }, {} as Record<string, string>)

  for (const selectedTaxonomyId of selectedTaxonomyIds || []) {
    if (tileCategories.includes(selectedTaxonomyId)) {
      const parent = getParentByChildTaxonomyId(selectedTaxonomyId)

      if (parent) {
        taxonomyVariants[parent] = selectedTaxonomyId
      }
    }
  }

  return taxonomyVariants
}

function buildTileEntity (
  tileEntity: Gallery.ContentQuery.TileEntityResponse,
  index: number,
  designs: State.Design[],
  productOptionsByProductKeyMap: Gallery.Product.ProductOptionsGroupsByProductKey,
  isQSPEntity: boolean,
  locale: i18n.Locale,
  quantity: Gallery.Models.Url.ValidParsedQsValue<number>,
  getTaxonomyIdByCategoryId: (categoryId: string) => string,
  selectedTaxonomyIds?: string[]
): State.TileEntity {
  let colorSwatchIds: string[] = []
  const {
    colorSwatches, fullProductOptions, productKey, productVersion, ...rest
  } = tileEntity

  rest.studioUrl = buildFullyQualifiedVistaprintUrl({
    path: rest.studioUrl,
    locale,
    quantity,
  })

  if (colorSwatches?.length) {
    colorSwatchIds = colorSwatches.map((colorSwatch) => {
      const updatedColorSwatch = {
        ...colorSwatch,
        entityId: tileEntity.designId,
        studioUrl: buildFullyQualifiedVistaprintUrl({
          path: colorSwatch.studioUrl,
          locale,
          quantity,
        }),
      }

      designs.push(updatedColorSwatch)
      return updatedColorSwatch.designId
    })
  } else {
    designs.push({
      ...rest,
      entityId: tileEntity.designId,
    })
  }

  // Each tileEntity maps to a single pricing option based on the
  // product options. Build the productOptionsHash to add to the tileEntity
  // state for lookup against the pricing state
  const productOptionsHash = getProductOptionsHash(fullProductOptions)

  // Build up the productOptionsByProductKeyMap state
  // productOptionsByProductKeyMap is a linking table between tileEntities and other content
  // that requires associating tileEntitie's productOptions to data (i.e. pricing)
  if (!(productKey in productOptionsByProductKeyMap)) {
    productOptionsByProductKeyMap[productKey] = {}
  }

  productOptionsByProductKeyMap[productKey][productOptionsHash] = fullProductOptions

  return {
    ...rest,
    productKey,
    productVersion: +productVersion,
    productOptionsHash,
    fullProductOptions,
    colorSwatches: colorSwatchIds,
    isQSPEntity,
    position: index,
    taxonomyVariants: buildTaxonomyState(
      tileEntity?.categoryKeys || [],
      selectedTaxonomyIds || [],
      getTaxonomyIdByCategoryId
    ),
    colorComposition: tileEntity.colorComposition,
  }
}

/**
 * Builds the "content" state.
 * The "content" state actually encompasses two states in our store:
 * `designs` and `tileEntities`. As both of those states rely
 * on data from the content query, this function filters through the content
 * result and returns both states as a store fragment.
 * @param content
 */
export function buildContentState (
  content: Gallery.ContentQuery.TileEntityResponse[],
  locale: i18n.Locale,
  quantity: Gallery.Models.Url.ValidParsedQsValue<number>,
  getTaxonomyIdByCategoryId: (categoryId: string) => string,
  qvContent?: Gallery.ContentQuery.TileEntityResponse,
  selectedTaxonomyIds?: string[]
): ContentState {
  const designs: State.Design[] = []
  const productOptionsByProductKeyMap: Gallery.Product.ProductOptionsGroupsByProductKey = {}

  const tileEntities = content.map((tileEntity, index): State.TileEntity => buildTileEntity(
    tileEntity,
    index,
    designs,
    productOptionsByProductKeyMap,
    false,
    locale,
    quantity,
    getTaxonomyIdByCategoryId,
    selectedTaxonomyIds
  ))

  const designsById = byId<State.Design>(designs, 'designId')
  const tileEntitiesById = byId<State.TileEntity>(tileEntities, 'designId')

  if (qvContent) {
    const qvDesigns: State.Design[] = []
    const qvTileEntity = buildTileEntity(
      qvContent,
      0,
      qvDesigns,
      productOptionsByProductKeyMap,
      true,
      locale,
      quantity,
      getTaxonomyIdByCategoryId,
      selectedTaxonomyIds
    )

    // eslint-disable-next-line no-return-assign
    qvDesigns.forEach((qvDesign) => designsById.byId[qvDesign.designId] = qvDesign)
    tileEntitiesById.byId[qvTileEntity.designId] = {
      ...qvTileEntity,
      position: tileEntitiesById.byId[qvTileEntity.designId]?.position ?? qvTileEntity.position,
    }
  }

  return {
    productOptionsByProductKeyMap,
    designs: designsById,
    tileEntities: tileEntitiesById,
  }
}
