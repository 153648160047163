import { AnyAction } from 'redux'

import { CONTENT_UPDATE } from '../constants'

const initialState: State.FacetState = {
  byId: {},
}

export function reducer (
  state: State.FacetState = initialState,
  action: AnyAction
): State.FacetState {
  switch (action.type) {
    case CONTENT_UPDATE:
      return {
        ...state,
        byId: action.payload.facets.byId,
      }
    default:
      return state
  }
}

export const facetByIdSelector = (state: State.GlobalState) => (
  facet: string
): Gallery.ContentQuery.Facet => state.facets.byId[facet.toLowerCase()]
