import { AnyAction } from 'redux'

const initialState: State.TaxonomyIdByCategoryIdState = {}

export function reducer (
  state: State.TaxonomyIdByCategoryIdState = initialState,
  action: AnyAction
): State.TaxonomyIdByCategoryIdState {
  switch (action.type) {
    default:
      return state
  }
}
