import { Fragment, JSX, memo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { tileEntityAllIdsSelector } from '~/client/store/tileEntity'
import { currentPageSelector, totalEntityCountSelector } from '~/client/store/paging'
import { usePreviewDimensions } from '~/client/hooks/usePreviewDimensions'
import { CareTile } from '~/client/components/Gallery/PreviewArea/CareTile'
import { currentPageInfoSelector } from '~/client/store/paging/selectors'
import { getCareData } from '~/client/store/care'
import { DesignTileWrapper } from '~/client/components/Gallery/PreviewArea/DesignTileWrapper'
// import { useExperimentation } from '~/hooks/useExperimentation'
import { useColorSwatchResizeObserve } from '~/client/hooks/useColorSwatchResizeObserve'
// import { TWO_DIFY_TILE_POSITIONS, THREE_DIFY_TILE_POSITIONS, NEW_DIFY_TILE_POSITION_VARIATIONS } from '~/experiments/NewDIFYTilePosition/constants'
// TODO

const DesignTilesListComponent = (): JSX.Element => {
//   const isExperimentActive = useExperimentation()
  const resultCount = useSelector(totalEntityCountSelector)
  const tileEntityIds = useSelector(tileEntityAllIdsSelector)
  const previewHeight = usePreviewDimensions()
  const currentPage = useSelector(currentPageSelector)
  const { pageSize } = useSelector(currentPageInfoSelector)
  // const isLoadMoreAvailable = useSelector(getIsLoadMoreAvailable)
  // const loadMoreCount = useSelector(loadMoreCountSelector)
  const care = useSelector(getCareData)
  const gridRef = useRef<HTMLDivElement>(null)
  const maxColorSwatchesPerRow = useColorSwatchResizeObserve(gridRef)

  // todo
  //   const NewDIFYTileVariant1Enabled = isExperimentActive(NEW_DIFY_TILE_POSITION_VARIATIONS.Enabled1)
  //   const NewDIFYTileVariant2Enabled = isExperimentActive(NEW_DIFY_TILE_POSITION_VARIATIONS.Enabled2)
  // const DIFYTileExpEnabled = false // NewDIFYTileVariant1Enabled || NewDIFYTileVariant2Enabled

  const isFirstPage = currentPage === 1

  const isFirstPageAndTemplatesExist = isFirstPage && resultCount > 0
  const shouldShowCareTile = isFirstPageAndTemplatesExist && care?.designServicesAvailable

  const careTilePosition = pageSize - 1

  const isCareTileAtEnd = careTilePosition >= tileEntityIds.length
  // const difyPositions = TWO_DIFY_TILE_POSITIONS
  // NewDIFYTileVariant2Enabled ? TWO_DIFY_TILE_POSITIONS : THREE_DIFY_TILE_POSITIONS

  return (
    <>
      {tileEntityIds.map((entityId: string, index: number) => {
        const shouldPinCareTile = shouldShowCareTile && index === careTilePosition && !isCareTileAtEnd
        // !NewDIFYTileVariant1Enabled && !NewDIFYTileVariant2Enabled

        const shouldShowTileCareExperiment1 = false
        //   shouldShowCareTile &&
        //             DIFYTileExpEnabled &&
        //             (
        //               !isLoadMoreAvailable && (
        //                 difyPositions.indexOf(index) > -1 &&
        //                     difyPositions.indexOf(index) < loadMoreCount ||
        //                     tileEntityIds.length === index + 1
        //               ) ||
        //                 isLoadMoreAvailable && difyPositions.includes(index)
        //             ) && !(tileEntityIds.length < pageSize && NewDIFYTileVariant2Enabled)

        const refValue = index === 0 ? gridRef : null

        return (
          <Fragment key={entityId}>
            <li className='design-tile-wrapper'>
              <DesignTileWrapper
                entityId={entityId}
                maxColorSwatchesPerRow={maxColorSwatchesPerRow}
                refValue={refValue}
              />
            </li>
            {(shouldPinCareTile || shouldShowTileCareExperiment1) &&
              <CareTile previewHeight={previewHeight} />}
          </Fragment>
        )
      })}
    </>
  )
}

export const DesignTilesList = memo(DesignTilesListComponent)
