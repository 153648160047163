import { CheckboxFilter } from '~/client/components/Gallery/Filters/Checkbox'
import { RadioFilter } from '~/client/components/Gallery/Filters/Radio'
import { ColorFilter } from '~/client/components/Gallery/Filters/Color'
import { FlatTaxonomy } from '~/client/components/Gallery/Filters/FlatTaxonomy'
import { FacetedTaxonomy } from '~/client/components/Gallery/Filters/FacetedTaxonomy'
import { FILTER_TYPE } from 'shared/constants'

interface PropTypes {
  filter: State.Filter;
}

export const Filter = (props: PropTypes) => {
  const { filter: { type } } = props

  switch (type.toLowerCase()) {
    case FILTER_TYPE.CHECKBOX:
      return <CheckboxFilter {...props} />
    case FILTER_TYPE.RADIO:
      return <RadioFilter {...props} />
    case FILTER_TYPE.COLOR:
      return <ColorFilter {...props} />
    case FILTER_TYPE.FLAT_TAXONOMY:
      return <FlatTaxonomy {...props} />
    case FILTER_TYPE.FACETED_TAXONOMY:
      return <FacetedTaxonomy {...props} />
    default:
      return null
  }
}
