import { FilterOption } from '~/client/components/Gallery/Filters/FilterOption'
import { SingleSelectFilter } from '~/client/components/Gallery/Filters/SingleSelect'

export interface PropTypes {
  filter: State.Filter;
}

export const RadioFilter = (props: PropTypes) => {
  const { filter } = props

  return (
    <SingleSelectFilter filter={filter as State.AttributeFilter}>
      {(o: RenderableFilterOption) => (
        <FilterOption facetCount={o.facetCount} title={o.title} />
      )}
    </SingleSelectFilter>
  )
}
