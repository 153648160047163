import { ComponentProps } from 'react'
import { PaginationButton as SwanPaginationButton } from '@vp/swan'

import { useLinkParameters } from '~/client/hooks/useLinkParameters'

export interface PropTypes extends ComponentProps<typeof SwanPaginationButton> {
  pageNumber: number;
}

export const PaginationButton = (props: PropTypes) => {
  const {
    disabled,
    onClick,
    pageNumber,
    ...rest
  } = props

  const linkHref = useLinkParameters(pageNumber)

  return (
    <SwanPaginationButton
      {...rest}
      disabled={disabled}
      href={linkHref}
      onClick={disabled ? undefined : onClick}
    />
  )
}
