import { AnyAction } from 'redux'
import { createSelector } from 'reselect'

const initialState: State.FilterByOptionState = {}

export function reducer (
  state: State.FilterByOptionState = initialState,
  action: AnyAction
): State.FilterByOptionState {
  switch (action.type) {
    default:
      return state
  }
}

export const filterByOptionSelector = createSelector(
  (state: State.GlobalState) => state.filterByOption,
  (filterByOption) => (id: string): string => filterByOption[id]
)
