import { AnyAction } from 'redux'
import {
  REFINEMENT_UPDATE,
  RefinementUpdateAction,
} from './actions'
import { CONTENT_REVERT, LOCATION_POP } from '../constants'
import { REFINEMENT_DIMENSION } from 'shared/constants'
import { buildRefinementStateFromUrlRefinements } from '../buildState'
import { INITIAL_STATE } from './constants'

export function reducer (
  state: State.RefinementState = INITIAL_STATE,
  action: AnyAction
): State.RefinementState {
  let updatedState: State.RefinementState
  const { payload } = action as RefinementUpdateAction

  switch (action.type) {
    case CONTENT_REVERT:
      return {
        ...action.payload.refinements,
      }
    case LOCATION_POP:
      return {
        ...buildRefinementStateFromUrlRefinements(
          action.payload.refinements as Gallery.Models.Url.Refinements,
          []
        ),
      }
    case REFINEMENT_UPDATE:
      // Add new filter options to refinements first, hopefully we'll never add and remove the same option
      updatedState = {
        ...state,
      }

      if (payload.add) {
        payload.add.forEach((item) => {
          if (item.dimension === REFINEMENT_DIMENSION.KEYWORD ||
                        item.dimension === REFINEMENT_DIMENSION.COLLECTION) {
            updatedState[item.dimension] = item
          } else {
            updatedState[item.value] = item
          }
        })
      }

      if (payload.remove) {
        payload.remove.forEach((item) => {
          if (item.dimension === REFINEMENT_DIMENSION.KEYWORD ||
                        item.dimension === REFINEMENT_DIMENSION.COLLECTION) {
            delete updatedState[item.dimension]
          } else {
            delete updatedState[item.value]
          }
        })
      }

      return updatedState
    default:
      return state
  }
}
