import { hash } from '~/client/utils/hash'

/**
 * Generates a `getProductOptionsHash` hash from the combined name/values of each
 * product option.
 * @param productOptions
 */
export function getProductOptionsHash (productOptions: Gallery.ContentQuery.ProductOptions): string {
  // QV doesn't guarantee the order it builds product options, this needs to return the same hash regardless of order
  const array = Object.entries(productOptions).sort(([key], [key2]) => (key > key2 ? 1 : -1))

  return hash(JSON.stringify(array))
}
