import { BoundedContent, Column, GridContainer, Row } from '@vp/swan'

// import './gallery.css'

import { HeroHeader } from './Header/HeroHeader'
import { PreviewArea } from './PreviewArea'
import { Subheader } from './Subheader'
import { Pagination } from './Pagination'
import { FiltersSidebar } from './Filters/FiltersSidebar'
import { Header } from './Header'

export const Gallery = () => {
  return (
    <BoundedContent>
      <GridContainer>
        <Row>
          <Column span={12}>
            <HeroHeader />
            <Header />
          </Column>
        </Row>
        <Row>
          <Column
            className='left-nav-wrapper'
            span={2}
            spanLg={3}
            spanMd={3}
            spanSm={4}
            spanXl={2}
            spanXs={null}
          >
            <FiltersSidebar />
          </Column>
          <Column
            span={10}
            spanLg={9}
            spanMd={9}
            spanSm={8}
            spanXl={10}
            spanXs={12}
          >
            <Subheader />
            <PreviewArea />
            <Pagination />
            {/* {!!galleryMetadata && showProductInfo && <SecondaryContent />} */}
            <section className='footer'>
              {/* <Faq />
                  <ReviewsCallout /> */}
            </section>
            {/* <nav className='external-links'>
                  <ProductOptionsDescriptions />
                  <QuickLinks />
                  {shouldShowTlp0Callout && <TLPL0Callout />}
                </nav> */}
            <section className='care-callout-wrapper'>
              {/* TODO this is not working adn three are console error */}
              {/* <CareCallout /> */}
            </section>
          </Column>
        </Row>
      </GridContainer>
    </BoundedContent>
  )
}
