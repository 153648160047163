import type { DesignPersonalizationContext } from '@vp/personalization-types'
// import { dpcToAppliedUploads } from '~/client/components/Gallery/Filters/PersonalizationV1/dpcConverters'

export const designPersonalizationContextSelector = (
  state: State.GlobalState
): DesignPersonalizationContext => state.designPersonalization.designPersonalizationSession?.designPersonalizationContext.designPersonalizationData

export const designPersonalizationContextIdSelector = (
  state: State.GlobalState
): string | undefined => state.designPersonalization?.designPersonalizationSession?.designPersonalizationContext.designPersonalizationContextId

export const designPersonalizationSessionSelector = (
  state: State.GlobalState
): VP.DesignPersonalization.Models.DesignPersonalizationContextService.PersonalizationSessionResponse | undefined => state.designPersonalization?.designPersonalizationSession

export const dpcsLoadingSelector = (
  state: State.GlobalState
): boolean => state.designPersonalization.dpcsLoading

// TODO uncoment
// export const appliedUploadsSelector = (
//   state: State.GlobalState
// ): Gallery.Models.Personalization.UploadIdentifier[] => dpcToAppliedUploads(
//   state.designPersonalization.designPersonalizationSession?.designPersonalizationContext.designPersonalizationData
// )

export const logoAppliedSelector = (
  state: State.GlobalState
): boolean => (state.designPersonalization.designPersonalizationSession?.designPersonalizationContext.designPersonalizationData.images &&
    (state.designPersonalization.designPersonalizationSession?.designPersonalizationContext.designPersonalizationData.images.length > 0)) ?? false
