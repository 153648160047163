import {
  useCallback, useEffect, useState,
} from 'react'
import { BaseImageProps, UseImageResult } from '~/client/components/Gallery/DesignTile/interface'
import { resolveSrc, resolveSrcSet } from '~/client/components/common/McpImage/utils'
import { instrumenter } from 'client/utils/instrumentation'
import { useCallbackRef } from '~/client/hooks/useRef'
import { useTranslations } from '~/client/hooks/useTranslations'
import { useDebouncedCallback } from 'use-debounce'
import { LOADING_TYPE } from '~/client/constants'
import { useSelector } from 'react-redux'
import { dpcsLoadingSelector } from '~/client/store/designPersonalization/selectors'
import { stringRenderPropertySelector } from '~/client/store/config/reducer'
import { RenderProperty } from 'shared/renderProperties'

export const useImage = (imageProps: BaseImageProps): UseImageResult => {
  const localize = useTranslations()
  const stringRenderProperty = useSelector(stringRenderPropertySelector)
  const tilePreviewSize = stringRenderProperty(RenderProperty.TilePreviewSize) || ''

  const {
    previewsUrls,
    alt = '',
    loading = LOADING_TYPE.LAZY,
    srcSet,
    bypassedApproval,
    fetchpriority,
    isLoading: isImageLoading,
  } = imageProps

  const imageSrc = resolveSrc(previewsUrls?.size1x) || ''
  const imageSrcSet = resolveSrcSet(previewsUrls, srcSet) || ''

  const isLazyLoading = loading === LOADING_TYPE.LAZY
  const [isLoading, setIsLoading] = useState<boolean>(isLazyLoading)
  const [currentSrcSet, setCurrentSrcSet] = useState<string>(imageSrcSet)
  const [showLoader, setShowLoader] = useState<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)
  const isDpcsLoading = useSelector(dpcsLoadingSelector)

  const debouncedLoaded = useDebouncedCallback(() => {
    setIsError(false)
    setIsLoading(false)
  }, 50)

  const debouncedShowLoader = useDebouncedCallback(() => {
    setShowLoader(isLoading || isDpcsLoading)
  }, 100)

  const imgRef = useCallbackRef<HTMLImageElement>(null, (current) => {
    if (current?.complete && current?.naturalWidth === 0) {
      setIsError(true)
    }
    setIsLoading(!current?.complete)
  })

  useEffect(() => {
    const newSrcSet = resolveSrcSet(previewsUrls, srcSet) || ''

    if (newSrcSet !== currentSrcSet) {
      setIsLoading(true)
      setIsError(false)
      setCurrentSrcSet(newSrcSet)
    }
  }, [currentSrcSet, srcSet, previewsUrls])

  useEffect(() => {
    if (isLoading || isDpcsLoading) {
      debouncedShowLoader()
    } else {
      setShowLoader(false)
    }
    // eslint -disable-next-line react-hooks/exhaustive-deps todo something not working
  }, [isLoading, isDpcsLoading])

  const onError = useCallback((): void => {
    if (!bypassedApproval) {
      instrumenter.recordImageFailure({
        srcSet,
        ...previewsUrls,
      })
    }

    setIsError(true)
    setIsLoading(false)
  }, [bypassedApproval, srcSet, previewsUrls])

  const onLoad = (): void => {
    debouncedLoaded()
  }

  const isEagerLoading = LOADING_TYPE.EAGER && isLoading
  const showSpinner = isImageLoading || (showLoader && (isLazyLoading || isEagerLoading)) || isDpcsLoading

  return {
    imgRef,
    showSpinner,
    alt,
    imageSrc,
    imageSrcSet,
    loading,
    onLoad,
    onError,
    accessibilityText: localize('Loading'),
    isError: isError && !isImageLoading,
    fetchpriority,
    size: tilePreviewSize,
    emptyPreviewText: localize('NoPreviewAvailable'),
  }
}
