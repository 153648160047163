import { ComponentProps } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  BasicCollapsible,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
} from '@vp/swan'

import { useFilterOptions } from '~/client/components/Gallery/Filters/SingleSelect/useFilterOptions'
import {
  buildRefinement,
  refinementAddAndRemove,
} from '~/client/store/refinement'
import { ANALYTICS_LABEL, REFINEMENT_DIMENSION } from 'shared/constants'
import { scrollUpToElement } from '~/client/utils/scrollToElement'
import { galleryHeaderId } from '~/client/components/Gallery/Header/constants'
// import { isFilterModalHidden } from '~/components/Gallery/Header/FilterMenu/utils'
// import { useRedirectToL0 } from '~/hooks/RedirectToL0/useRedirectToL0'
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics'
// import { TRACKING_BEHAVIOR, useExperimentation } from '~/hooks/useExperimentation'
// import { getRawExperiments } from '~/store/experimentation'
// import { PERSONALIZATION_UX_BRAND_VARIATIONS, PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME } from '~/experiments/Personalization/constants'
// import { trackExperimentEngagement } from '~/shared/ab-testing'

interface PropTypes {
  children: (o: RenderableFilterOption) => React.ReactNode;
  CollapsibleProps?: ComponentProps<typeof BasicCollapsible>;
  filter: State.AttributeFilter;
  SelectionSetProps?: Omit<ComponentProps<typeof SelectionSet>, 'defaultSelectedValue' | 'variant' | 'selectedValue' | 'selectedValues' | 'onSelectedValueChange' | 'onSelectedValuesChange' | 'skin'> & {
    variant: 'single-select';
  };
}

export const SingleSelectFilter = (props: PropTypes): React.ReactNode => {
  const {
    children,
    CollapsibleProps = {},
    filter,
    SelectionSetProps = {},
  } = props
  const { options, selected, visible } = useFilterOptions(filter)
  const dispatch = useDispatch()

  //   const redirectToL0 = useRedirectToL0()

  // hooks added for temporary Personalization UX experiment tracking
  //   const isExperimentActive = useExperimentation()
  //   const rawExperiments = useSelector(getRawExperiments)
  const analytics = useAnalytics()

  const handleSelectedValueChange = (newSelectedValue: string | number | null): void => {
    // shouldn't happen, but let's not update a radio button with an invalid value
    if (!newSelectedValue) {
      return
    }

    //   TODO uncomment analytics
    // if (isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Suppress)) {
    //   trackExperimentEngagement(
    //     PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
    //     rawExperiments,
    //     analytics,
    //     ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
    //     'Filter Update',
    //     PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
    //     filter.name
    //   )
    // } else if (isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Control, TRACKING_BEHAVIOR.Suppress)) {
    //   trackExperimentEngagement(
    //     PERSONALIZATION_UX_BRAND_VARIATIONS.Control,
    //     rawExperiments,
    //     analytics,
    //     ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
    //     'Filter Update',
    //     PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
    //     filter.name
    //   )
    // }

    const value = newSelectedValue ? newSelectedValue.toString() : ''
    const refinement = buildRefinement(value, REFINEMENT_DIMENSION.ATTRIBUTE)
    const oldRefinement = buildRefinement(selected, REFINEMENT_DIMENSION.ATTRIBUTE)

    // if (redirectToL0([refinement], [oldRefinement])) {
    //   return
    // }

    dispatch(refinementAddAndRemove([refinement], [oldRefinement]))
    // if (isFilterModalHidden()) {
    //   scrollUpToElement(galleryHeaderId)
    // }
  }

  return (
    <>
      {(!visible || options.length === 1) && null}
      {(visible && options.length > 1) && (
        <BasicCollapsible
          aria-label={filter.title}
          className='filter'
          collapsibleId={filter.name}
          heading={filter.title}
          {...CollapsibleProps}
        >
          <SelectionSet
            aria-label={filter.title}
            className='selection-set-layout-override'
            selectedValue={selected}
            skin='simple-column'
            variant='single-select'
            onSelectedValueChange={handleSelectedValueChange}
            {...SelectionSetProps}
          >
            {options.map((o) => (
              <div className='selection-set-item' key={o.value}>
                <SelectionSetInput
                  disabled={o.disabled}
                  value={o.value}
                >
                  <SelectionSetLabel>
                    {children(o)}
                  </SelectionSetLabel>
                </SelectionSetInput>
              </div>
            ))}
          </SelectionSet>
        </BasicCollapsible>
      )}
    </>
  )
}

SingleSelectFilter.displayName = 'SingleSelectFilter'
