import { createBrowserHistory, History } from 'history'

function getHistory (): History | null {
  if (process.env.IS_CLIENT) {
    // If we're not in a browser (server side), don't expose history since
    // attemping to manipulate history on the server risks major bugs.
    // Consumers of this module should be prepared to handle the `null` case
    return createBrowserHistory()
  }

  return null
}

export const history: History | null = getHistory()
