import { Action } from 'redux'

export const PREVIOUSLY_ORDERED_UPDATE = 'PREVIOUSLY_ORDERED_UPDATE'

interface PreviouslyOrderedPayload {
  previouslyOrdered: State.PreviouslyOrdered;
}

export interface PreviouslyOrderedAction extends Action {
  type: string;
  payload: PreviouslyOrderedPayload;
}

export const updatePreviouslyOrdered = (previouslyOrdered: State.PreviouslyOrdered): PreviouslyOrderedAction => ({
  payload: {
    previouslyOrdered,
  },
  type: PREVIOUSLY_ORDERED_UPDATE,
})
