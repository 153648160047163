import { tokensRaw } from '@vp/swan'
import {
  MAXIMUM_RENDERED_IMAGE_DIMENSION,
  MINIMUM_TILE_DEFAULT_WIDTH, MINIMUM_TILE_LARGE_WIDTH, TILE_PREVIEW_SIZE,
} from 'client/constants'
import { PREVIEW_TYPE, SCENE_SOUCE, SCENE_TYPE } from 'shared/constants'
import { PersonalizedUrlBuilder } from '~/client/utils/tilePersonalization/personalizedUrlBuilder'
import config from 'config'
import qs from 'query-string'
import { addQueryParamToUrl } from '~/shared/url'

type RenderingURLQueryParams = {
  url: string;
  contentBackgroundColor: Preview.BackgroundColorInput;
  locale: string;
  templateToken: string;
  bypassApproval: Gallery.Models.Url.ValidParsedQsValue<boolean>;
  finishType: string;
  previewWidth: number;
  aspectRatio: number;
  sceneSource: Gallery.Models.Url.ValidParsedQsValue<string>;
  sceneUri?: string;
  imageCacheVersion: string | null;
  sceneType?: Gallery.ContentQuery.SCENE_TYPE;
  isPersonalizationSidebarEnabled?: boolean;
  designPersonalizationContextId?: string;
  productOptions: Record<string, string>;
  productVersion: number,
  productId: string,

}

type TemplateRealizationParams = {
  backgroundColor: Preview.BackgroundColorInput,
  locale: string,
  templateToken: string,
  bypassApproval?: Gallery.Models.Url.ValidParsedQsValue<boolean>,
  finishType?: string,
  imageCacheVersion?: string | null,
}

export const DEFAULT_BACKGROUND_COLOR = tokensRaw.SwanSemColorBgStrong.substring(1)

const DEFAULT_DYNAMIC_PARAMS: Record<string, string> = {
  format: 'auto',
  quality: '95',
  showerr: 'true',
  category: 'gal6',
}

const buildTemplateRealizationUri = ({
  backgroundColor,
  locale,
  templateToken,
  bypassApproval = false,
  finishType = 'None',
  imageCacheVersion = null,
}: TemplateRealizationParams): string => {
  const params = {
    backgroundColor,
    bypassApproval: bypassApproval ? 'true' : undefined,
    finish: finishType,
    icv: imageCacheVersion,
    culture: locale,
    templateToken,
    requestor: config.appName,
  }

  return `${config.client.rendering.contentDeliveryBaseURL}/api/v1/accounts/ozoDdrmewShEcbUDWX8J3V/contentAreas/IoBX4KKMc0uyUyA0tFQDg/assets:deliver?${qs.stringify(params)}`
}

const addRenderingURLQueryParams = ({
  url,
  contentBackgroundColor,
  locale,
  templateToken,
  bypassApproval = false,
  finishType = 'None',
  previewWidth,
  aspectRatio,
  sceneUri,
  sceneSource,
  imageCacheVersion = null,
  sceneType,
  isPersonalizationSidebarEnabled = false,
  designPersonalizationContextId,
  productOptions,
  productVersion,
  productId,
}: RenderingURLQueryParams): string => {
  const updatedURL = new URL(url.startsWith('//') ? `https:${url}` : url)
  const isLookupSourcedScene = sceneSource === SCENE_SOUCE.SCENE_LOOKUP_SOURCED
  const isVortexScene = sceneType === SCENE_TYPE.VORTEX_RASTER
  const isDynamicScene = sceneType === SCENE_TYPE.DYNAMIC

  const instructionsUriParam = isVortexScene
    ? 'renderingInstructionsUrl'
    : 'instructions_uri'

  const instructionsUrlString = updatedURL.searchParams.get(instructionsUriParam)

  if (!instructionsUrlString) {
    throw new Error('No instructions URI')
  }
  const instructionsUrl = new URL(instructionsUrlString)

  function prepareScene (): void {
    if (isDynamicScene) {
      // Ignore projections for dynamic scenes
      instructionsUrl.searchParams.set('ignoreProjection', 'true')
    }
  }

  if (isPersonalizationSidebarEnabled && designPersonalizationContextId) {
    prepareScene()
    const diamondUri = PersonalizedUrlBuilder.buildDiamondUri(
      templateToken,
      locale,
      productId,
      productOptions,
      productVersion,
      designPersonalizationContextId
    )

    instructionsUrl.searchParams.set('documentUri', diamondUri)
  } else if (isLookupSourcedScene) {
    prepareScene()
  } else {
    const newTemplateRealizationUri = buildTemplateRealizationUri({
      backgroundColor: contentBackgroundColor,
      locale,
      templateToken,
      bypassApproval,
      finishType,
      imageCacheVersion,
    })

    instructionsUrl.searchParams.set('documentUri', newTemplateRealizationUri)
  }

  // rendering has a maximum image size of 10K pixels in either direction
  // this logic downsizes a requested image that would be too big to be rendered
  let calculatedWidth = previewWidth

  if (calculatedWidth > MAXIMUM_RENDERED_IMAGE_DIMENSION) {
    calculatedWidth = MAXIMUM_RENDERED_IMAGE_DIMENSION
  }

  if (calculatedWidth / aspectRatio > MAXIMUM_RENDERED_IMAGE_DIMENSION) {
    calculatedWidth = Math.floor(MAXIMUM_RENDERED_IMAGE_DIMENSION * aspectRatio)
  }

  const newSceneUri = sceneUri && imageCacheVersion
    ? addQueryParamToUrl(sceneUri, 'icv', imageCacheVersion)
    : sceneUri

  const newParams: Record<string, string | undefined> = isVortexScene
    ? {
        height: Math.round(calculatedWidth / aspectRatio).toString(),
        bgColor: DEFAULT_BACKGROUND_COLOR,
      }
    : {
        ...DEFAULT_DYNAMIC_PARAMS,
        merchant_metadata: templateToken,
        scene: newSceneUri,
        bgcolor: DEFAULT_BACKGROUND_COLOR,
      }

  newParams[instructionsUriParam] = instructionsUrl.toString()
  newParams.width = calculatedWidth.toString()

  for (const [key, value] of Object.entries(newParams)) {
    if (value !== undefined) {
      updatedURL.searchParams.set(key, value)
    }
  }

  return updatedURL.toString()
}

export const getPreviewData = (
  previewInfo: Gallery.ContentQuery.PreviewInfo,
  locale: i18n.Locale,
  contentBackgroundColor: Gallery.Models.Url.ValidParsedQsValue<string>,
  previewType: PREVIEW_TYPE,
  previewUrls: Gallery.ContentQuery.PreviewUrls,
  sceneSource: Gallery.Models.Url.ValidParsedQsValue<string>,
  isPersonalizationSidebarEnabled: boolean,
  previewSize: Gallery.Models.Url.ValidParsedQsValue<string> = TILE_PREVIEW_SIZE.DEFAULT,
  bypassApproval: Gallery.Models.Url.ValidParsedQsValue<boolean> = false,
  imageCacheVersion: Gallery.Models.Url.ValidParsedQsValue<string> = null,
  productKey = '',
  productVersion = 0,
  fullProductOptions: Record<string, string> = {},
  designPersonalizationContextId: string | undefined = undefined
): Gallery.Designs.RenderablePreviewInfo => {
  let previewWidth = ((previewSize && previewSize === TILE_PREVIEW_SIZE.LARGE)
    ? MINIMUM_TILE_LARGE_WIDTH
    : MINIMUM_TILE_DEFAULT_WIDTH) * 2

  if (previewType === PREVIEW_TYPE.QUICKVIEW) {
    previewWidth *= 3
  }

  const aspectRatio = (previewInfo.scenes &&
        previewInfo.scenes[PREVIEW_TYPE.TILE]?.aspectRatio) || previewInfo.aspectRatio || 1
  const sceneUri = (previewInfo.scenes &&
        previewInfo.scenes[PREVIEW_TYPE.TILE]?.uri) || undefined
  const sceneType = previewInfo.scenes && previewInfo.scenes[PREVIEW_TYPE.TILE]?.sceneType
  const commonPreviewURLParams = {
    contentBackgroundColor,
    locale,
    templateToken: previewInfo.templateToken,
    bypassApproval,
    finishType: previewInfo.finishType,
    previewWidth,
    aspectRatio,
    imageCacheVersion,
    sceneUri,
    sceneType,
    sceneSource,
    isPersonalizationSidebarEnabled,
    productVersion,
    productOptions: fullProductOptions,
    productId: productKey,
    designPersonalizationContextId,
  }

  const builtPreviewUrls = {
    size1x: addRenderingURLQueryParams({
      ...commonPreviewURLParams,
      url: previewUrls?.size1x,
    }),
    size2x: addRenderingURLQueryParams({
      ...commonPreviewURLParams,
      url: previewUrls?.size2x,
    }),
  }

  let personalizedInstructionsUrl

  if (isPersonalizationSidebarEnabled && designPersonalizationContextId) {
    personalizedInstructionsUrl = PersonalizedUrlBuilder.buildDiamondInstructionsUri(
      previewInfo.templateToken,
      locale,
      productKey,
      fullProductOptions,
      productVersion,
      designPersonalizationContextId
    )
  }

  return {
    previewInfo,
    previewWidth,
    personalizedInstructionsUrl,
    aspectRatio: previewInfo.aspectRatio,
    previewsUrls: builtPreviewUrls,
  }
}
