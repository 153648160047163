import { HTMLProps } from 'react'

import { SINGLE_SPACE_ENCODED } from 'client/constants'

export interface PropTypes extends HTMLProps<HTMLDivElement> {
  lines: number;
}

export const SpacingBlock = (props: PropTypes) => {
  const { lines, ...rest } = props

  return (
    <div {...rest} className='price-block'>
      {}
      <span className='pricing-display-spacing-block' dangerouslySetInnerHTML={{ __html: SINGLE_SPACE_ENCODED }} />
      {lines > 1 && (

        <span className='pricing-display-spacing-block' dangerouslySetInnerHTML={{ __html: SINGLE_SPACE_ENCODED }} />
      )}
    </div>
  )
}
