// TODO

// import { Logger } from '@vp/js-logger'
// import { buildLogger } from 'src/client/utils/buildLogger'

// export function getLogger (): Logger {
//   return buildLogger('gallery')
// }

export function getLogger () { return { error: console.log } }
