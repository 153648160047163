import { PaginationEllipses } from '@vp/swan'

import {
  PaginationStep,
  PropTypes as PaginationStepProps,
} from '~/client/components/Gallery/Pagination/PaginationStep'
import { isSmallScreen } from '~/client/utils/deviceDetection'

export interface PropTypes extends Omit<PaginationStepProps, 'pageText' | 'pageNumber' | 'accessibleText' | 'onClick'> {
  currentPage: number;
  maxPageLinks: number;
  numberOfPages: number;
  onClick: Gallery.Pagination.HandlePageChangeFactory;
  pageLinkPrefix: string;
  pageNumberLocalizer: Gallery.Pagination.PageNumberLocalizer;
  startingPageNumber: number;
}

/**
 * Utility function to generate an array of numbers from a integer range
 */
const range = (start: number, stop: number): number[] => Array.from(
  { length: (stop - start) / 1 + 1 },
  (k, v) => start + (v * 1)
)

function generatePageNumbers (
  currentPage: number,
  maxPageLinks: number,
  numberOfPages: number,
  startingPageNumber: number
): number[] {
  if (numberOfPages <= 2) {
    return []
  }

  let pageNumbers: number[]

  // Don't count the first and last page
  const remainingPages = Math.min(maxPageLinks, numberOfPages) - 2

  // Set the range of contiguous pages from the starting page and ending
  // page to see if the current page falls within it. If so, then we
  // display a run of length remainingPages from the start or end.
  // Otherwise we display three pages centered around the current page.
  const leftOffset = startingPageNumber + remainingPages
  const rightOffset = numberOfPages - remainingPages

  if (currentPage >= leftOffset && currentPage <= rightOffset && leftOffset !== rightOffset) {
    pageNumbers = [currentPage - 1, currentPage, currentPage + 1]
  } else if (leftOffset > currentPage) {
    pageNumbers = range(startingPageNumber + 1, leftOffset)
  } else {
    pageNumbers = range(rightOffset, numberOfPages - 1)
  }

  return pageNumbers
}

export const PaginationSteps = (props: PropTypes) => {
  const {
    currentPage,
    maxPageLinks,
    numberOfPages,
    onClick,
    pageLinkPrefix,
    pageNumberLocalizer,
    startingPageNumber,
    ...rest
  } = props

  const pageNumbers = generatePageNumbers(currentPage, maxPageLinks, numberOfPages, startingPageNumber)

  const mobilePageNumber = currentPage +
        // increse by 1 for the first page
        +!(currentPage - startingPageNumber) -
        // reduce by 1 for the last page
        +!(currentPage - numberOfPages)

  const paginationSteps = isSmallScreen()
    ? (
      <>
        {(currentPage - 1 > startingPageNumber) && (<PaginationEllipses />)}
        <PaginationStep
          {...rest}
          accessibleText={`${pageLinkPrefix} ${mobilePageNumber}`}
          current={mobilePageNumber === currentPage}
          pageNumber={mobilePageNumber}
          pageText={pageNumberLocalizer(mobilePageNumber)}
          onClick={onClick(mobilePageNumber)}
        />
        {(currentPage + 1 < numberOfPages) && (<PaginationEllipses />)}
      </>
      )
    : (
      <>
        {(pageNumbers[0] > startingPageNumber + 1) && (<PaginationEllipses key={-1} />)}
        {pageNumbers.map((pageNumber) => (
          <PaginationStep
            {...rest}
            accessibleText={`${pageLinkPrefix} ${pageNumber}`}
            current={pageNumber === currentPage}
            key={pageNumber}
            pageNumber={pageNumber}
            pageText={pageNumberLocalizer(pageNumber)}
            onClick={onClick(pageNumber)}
          />
        ))}
        {(pageNumbers[pageNumbers.length - 1] < numberOfPages - 1) && (<PaginationEllipses key={-2} />)}
      </>
      )

  return paginationSteps
}
