import deepequals from 'fast-deep-equal/es6/index'
import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Accordion } from '@vp/swan'
import { Filter } from '~/client/components/Gallery/Filters/Filter'
import {
  selectFilters,
  selectFilterExpandedState,
  setExpandedForFilter,
} from '~/client/store/filter'
import { useTranslations } from '~/client/hooks/useTranslations'

interface FiltersProps {
  showColorFirst?: boolean;
}

export const Filters = memo(({ showColorFirst }: FiltersProps) => {
  const dispatch = useDispatch()
  const filters = useSelector(selectFilters, deepequals)
  const filterExpandedMap = useSelector(selectFilterExpandedState, deepequals)
  const localize = useTranslations()

  const handleExpandChange = (collapsibleId: string, expanded: boolean): void => {
    dispatch(setExpandedForFilter(collapsibleId, expanded))
  }

  if (showColorFirst) {
    const colorFilterIndex = filters.findIndex((f) => f.type.toUpperCase() === 'COLOR')

    if (colorFilterIndex > -1) {
      const [item] = filters.splice(colorFilterIndex, 1)

      filters.unshift(item)
    }
  }

  return (
    <section aria-label={localize('AvailableFiltersRegionLabel')} className='filter-container'>
      <Accordion
        expandedCollapsibles={filterExpandedMap}
        onRequestExpandedChange={handleExpandChange}
      >
        {filters?.filter((f) => !f.hidden).map((f: State.Filter) => <Filter filter={f} key={f.name} />)}
      </Accordion>
    </section>
  )
})
