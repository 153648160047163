import { REFINEMENT_DIMENSION } from 'shared/constants'
import { INITIAL_STATE as DEFAULT_PAGE_STATE, INITIAL_STATE } from './paging/constants'
import { buildFiltersAndOptions } from './buildFilterState'
import { DEFAULT_TENANT } from '~/client/constants'
import { RenderProperty } from 'shared/renderProperties'
import { getFormattedLocale } from '~/shared/localeUtils'
import { buildRenderProperties } from '~/client/utils/buildRenderProperties'
import { v4 } from 'uuid'

type RefinementState = {
  refinements: State.RefinementState;
}

export interface GalleryContext extends Gallery.Models.Url.Context {
  url: URL;
  id: string;
  careContact: VP.Care.CareData | null;
  locale: i18n.Locale;
  config: State.ModifiedGalleryConfigState;
  paging: Gallery.Models.Url.Paging;
  refinements: Gallery.Models.Url.Refinements;
  translations: i18n.TranslationMap;
  taxonomy: Util.StringDictionary<Services.Category>;
  templateUseCases: Util.StringDictionary<Services.TemplateUseCase>;
  quantity: Gallery.Models.Url.ValidParsedQsValue<number>;
  experimentationData: Gallery.ABExperimentation.RawGalleryExperiments;
  invalidCategories: string[];
  forwardedQuery?: string;
  pageTitle: string;
  boosts: State.BoostsState;
}

/**
 * Builds the `config` state, which is the contextual static properties used as
 * configuration values in various parts of the app that have not already been
 * dispersed into other parts of the state (i.e. global config)
 * @param context
 */
export function buildConfigState (context: GalleryContext): { [key: string]: State.ConfigState } {
  return {
    config: {
      ...context.config,
      locale: context.locale,
      name: context.id,
      market: getFormattedLocale(context.locale).region,
      mpvId: context.mpvId || context.config.mpv,
      designId: context.designId,
      renderProperties: buildRenderProperties(context.config.renderProperties),
      selectedOptions: context.selectedOptions,
      tenant: DEFAULT_TENANT,
      quantity: context.quantity,
      url: context.url,
      forcedRankingStrategy: context.forcedRankingStrategyOverride ?? context.config.forcedRankingStrategy,
      viewId: v4(),
      tlpLevel: context.tlpLevel as State.TlpLevel,
      forwardedQuery: context.forwardedQuery,
      pageTitle: context.pageTitle,
      rawContent: [],
      lastRawContent: [],
    },
  }
}

export function buildDebugState (context: GalleryContext): { debug: State.DebugState } {
  return {
    debug: {
      bypassApproval: context.bypassApproval,
      debug: context.debug,
      lastRequestUrl: undefined,
      noCache: context.noCache,
      useConstraints: context.useConstraints,
      renderPropsOverride: context.renderPropsOverride,
      forcedRankingStrategyOverride: context.forcedRankingStrategyOverride,
      quickViewId: context.quickViewId,
      useRealisationEngineService: context.useRealisationEngineService,
      enrich: context.enrich,
      isProduct: context.isProduct,
      experienceType: context.experienceType,
      templatePurposes: context.templatePurposes,
      sortingStrategy: context.sortingStrategy,
      imagePlaceholderAspectRatio: context.imagePlaceholderAspectRatio,
      imagePlaceholderAspectRatioTolerance: context.imagePlaceholderAspectRatioTolerance,
      placeholderPurposes: context.placeholderPurposes,
      isSortingFilterCollapsed: false,
      useAlternateSearchProvider: context.useAlternateSearchProvider,
      searchBackend: context.searchBackend,
      source: context.source,
      designCreationTypes: context.designCreationTypes,
      enableTemplateUseCases: context.enableTemplateUseCases,
      highlightKeywords: context.highlightKeywords,
      highlightCategories: context.highlightCategories,
      aspExperimentFlags: context.aspExperimentFlags,
      takeOverQV: context.takeOverQV,
      selectedDesignId: context.selectedDesignId,
    },
  }
}

export function buildExperimentationState (context: GalleryContext): { experimentation: State.ExperimentationState } {
  return {
    experimentation: {
      rawExperiments: context.experimentationData.rawExperiments,
    },
  }
}

/**
 * Builds the `paging` state properties from the content results
 * @return {State.PagingState} Facet State
 * @param page
 * @param pageSize
 * @param configuredDefaultPageSize
 * @param experimentationData
 */
export function buildPagingState (
  page: Gallery.Models.Url.ValidParsedQsValue<number>,
  pageSize: Gallery.Models.Url.ValidParsedQsValue<number>,
  configuredDefaultPageSize: State.RenderPropertyValue
): { paging: Omit<State.PagingState, 'totalEntities' | 'hasMoreTemplates'> } {
  const currentPageSize = configuredDefaultPageSize
    ? parseInt(configuredDefaultPageSize as string, 10)
    : INITIAL_STATE.pageSize

  return {
    paging: {
      page: page || DEFAULT_PAGE_STATE.page,
      pageSize: pageSize || currentPageSize,
      loadMoreCount: DEFAULT_PAGE_STATE.loadMoreCount,
    },
  }
}

const buildRefinement = (value: string, dimension: string, isImplicitDefault?: boolean): State.Refinement => ({
  dimension,
  value: value.toLocaleLowerCase(),
  isImplicitDefault,
})

/**
 * Builds refinements state from the url context refinements
 * @param refinements
 * @param invalidCategories
 */
export function buildRefinementStateFromUrlRefinements (
  refinements: Gallery.Models.Url.Refinements,
  invalidCategories: string[]
): State.RefinementState {
  const refState = {} as State.RefinementState

  // first add all of the refinements from the url
  if (refinements.attributes) {
    refinements.attributes.forEach((att) => {
      const ref = buildRefinement(att, REFINEMENT_DIMENSION.ATTRIBUTE)

      refState[ref.value] = ref
    })
  }

  if (refinements.categories) {
    refinements.categories.filter((value) => !invalidCategories.includes(value)).forEach((att) => {
      const ref = buildRefinement(att, REFINEMENT_DIMENSION.CATEGORY)

      refState[ref.value] = ref
    })
  }

  if (refinements.templateUseCases) {
    refinements.templateUseCases.forEach((att) => {
      const ref = buildRefinement(att, REFINEMENT_DIMENSION.TEMPLATE_USE_CASE)

      refState[ref.value] = ref
    })
  }

  if (refinements.keyword) {
    refState.keyword = buildRefinement(refinements.keyword, REFINEMENT_DIMENSION.KEYWORD)
  }

  if (refinements.collection) {
    refState.collection = buildRefinement(refinements.collection, REFINEMENT_DIMENSION.COLLECTION)
  }

  return refState
}

/**
 * converts the refinements array into a refinement state object
 * @param config
 * @param {string[]} refinements
 * @param invalidCategories
 * @return {RefinementState} Refinement State
 */
export function buildRefinementState (
  config: Gallery.ConfigApi.Config,
  refinements: Gallery.Models.Url.Refinements,
  invalidCategories: string[]
): RefinementState {
  const refState = buildRefinementStateFromUrlRefinements(refinements, invalidCategories)

  config.attributeFilters.forEach((f) => {
    // don't add defaults if an option for that filter was in the url
    if (f.options.find((o) => !!refState[o.value.toLocaleLowerCase()])) {
      return
    }

    f.options
      .filter((o) => o.isDefault)
      .forEach((o) => {
        refState[o.value.toLocaleLowerCase()] = buildRefinement(o.value, REFINEMENT_DIMENSION.ATTRIBUTE, true)
      })
  })

  return { refinements: refState }
}

export function buildState (context: GalleryContext): State.GlobalState {
  return {
    care: context.careContact,
    boosts: context.boosts,
    ...buildConfigState(context),
    ...buildDebugState(context),
    ...buildFiltersAndOptions(context.config, context.taxonomy, context.templateUseCases),
    ...buildPagingState(
      context.paging.page,
      context.paging.pageSize,
      context.config.renderProperties[RenderProperty.DefaultPageSize]
    ),
    ...buildRefinementState(context.config, context.refinements, context.invalidCategories),
    ...buildExperimentationState(context),
  } as State.GlobalState
}
