export const DEFAULT_PAGING_SIZES = [24, 48, 96]

export const MINIMUM_TEMPLATES_SIZE = DEFAULT_PAGING_SIZES[0]

export const INITIAL_STATE: State.PagingState = {
  page: 1,
  pageSize: DEFAULT_PAGING_SIZES[2],
  totalEntities: 0,
  loadMoreCount: 0,
  hasMoreTemplates: false,
}
