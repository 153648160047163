// import { useQuery, UseQueryResult } from '@tanstack/react-query'
// import { getProductOptionsHash } from 'client/utils/getProductOptionsHash'
// // import { filterDesignVariationToVariableSelections } from '~/components/Gallery/PreviewArea/GalleryQuickView/utils/filterDesignVariationToVariableSelections'
// import { queryDesignVariations } from '~/queries/queryDesignVariations'

export const DESIGN_VARIATIONS_QUERY_KEY_NAME = 'DESIGN_VARIATIONS'

export const DESIGN_VARIATIONS_QUERY_SETTINGS = {
  staleTime: Infinity,
}

// type ResponseDesignVariation = DesignVariations.DesignVariationResponse[]

// type DesignVariationsResult = Omit<UseQueryResult<ResponseDesignVariation, Error>, 'data'> & {
//   data: DesignVariations.DesignVariationsByHash;
// }

// export const useDesignVariations = (
//   query: DesignVariations.DesignVariationsInput
// ): DesignVariationsResult => {
//   const queryKey: DesignVariations.QueryKey = [DESIGN_VARIATIONS_QUERY_KEY_NAME, query]
//   const { data, ...rest } = useQuery(queryKey, queryDesignVariations, {
//     ...DESIGN_VARIATIONS_QUERY_SETTINGS,
//     refetchOnWindowFocus: false,
//   })

//   let dvResponse = data || []

//   // The same TT can have multiple categories within a taxonomy, if we show all of those categories the user will have
//   // a seemingly broken experience because the previews won't update when switching options.
//   // Remove duplicates directly from the DV response so we never attempt to show.
//   if (query.variableTaxonomyIds?.length) {
//     // Sort by value for each parent taxonomyId in order
//     const sortedTaxonomyIds = query.variableTaxonomyIds.sort()
//     const sortedDv = dvResponse.sort(
//       (dv1, dv2) => sortedTaxonomyIds.reduce((accum, parentTaxonomyId) => {
//         if (accum !== 0) {
//           return accum
//         }

//         const dv1Tuc = dv1.taxonomyVariations.find((tuc) => tuc.rootTaxonomyId === parentTaxonomyId)?.childCategoryId ?? ''
//         const dv2Tuc = dv2.taxonomyVariations.find((tuc) => tuc.rootTaxonomyId === parentTaxonomyId)?.childCategoryId ?? ''

//         return dv1Tuc.localeCompare(dv2Tuc)
//       }, 0)
//     )

//     const dedupedDv = sortedDv.reduce((accum, dv) => {
//       const key = {
//         ...dv.productOptions,
//         numPlaceholders: dv.numberOfPlaceholders,
//         templateToken: dv.templateToken,
//       }
//       const hash = getProductOptionsHash(key)

//       return query.taxonomyIds.includes(dv.taxonomyVariations[0]?.childCategoryId) || !accum[hash]
//         ? {
//             ...accum,
//             [hash]: dv,
//           }
//         : accum
//     }, {} as Record<string, DesignVariations.DesignVariationResponse>)

//     dvResponse = Object.values(dedupedDv)
//   }

//   const designVariations = dvResponse.reduce((accum, dv) => {
//     const designVariationsTaxonomyIdDict = dv.taxonomyVariations
//       .reduce((dvAccum, { childCategoryId, rootTaxonomyId }) => ({
//         ...dvAccum,
//         [rootTaxonomyId]: childCategoryId,
//       }), {} as Record<string, string>)

//     const variationSelections = filterDesignVariationToVariableSelections(
//       dv.productOptions,
//       query.variableOptions,
//       designVariationsTaxonomyIdDict,
//       dv.numberOfPlaceholders,
//       query.variableAttributes,
//       query.variableTaxonomyIds
//     )

//     return {
//       ...accum,
//       [getProductOptionsHash(variationSelections)]: {
//         ...dv,
//         variationSelections,
//       },
//     }
//   }, {} as DesignVariations.DesignVariationsByHash)

//   return { data: designVariations, ...rest } as DesignVariationsResult
// }
