import { ComponentProps } from 'react'
import { Icon, Link, Typography } from '@vp/swan'

import { useTranslations } from '~/client/hooks/useTranslations'

export const ShowAllCategoriesButton = (props: ComponentProps<typeof Link>) => {
  const { ...rest } = props
  const localize = useTranslations()
  const showAllCategoriesText = localize('ShowAllCategories')

  return (
    <Link
      className='filter-link'
      component='button'
      {...rest}
      aria-label={showAllCategoriesText}
    >
      <Icon iconType='chevronLeft' size='20p' />
      <Typography component='span' fontSize='small' fontWeight='bold'>
        {showAllCategoriesText}
      </Typography>
    </Link>
  )
}
