import { Action, AnyAction } from 'redux'

export const REMOVED_TEMPLATE_UPDATE = 'REMOVED_TEMPLATE_UPDATE'

interface RemovedTemplatePayload {
  removedTemplateForPreviouslyOrdered: State.RemovedTemplateForPreviouslyOrdered;
}

export interface RemovedTemplateAction extends Action {
  type: string;
  payload: RemovedTemplatePayload;
}

export const updateRemovedTemplate = (
  removedTemplateForPreviouslyOrdered: State.RemovedTemplateForPreviouslyOrdered
): RemovedTemplateAction => ({
  payload: {
    removedTemplateForPreviouslyOrdered,
  },
  type: REMOVED_TEMPLATE_UPDATE,
})

export const checkIfReloadFromScratch = (
  actions?: AnyAction | AnyAction[]
): boolean => {
  const isClearAllAction = actions &&
        !Array.isArray(actions) &&
        actions.type === 'REFINEMENT_UPDATE' &&
        actions.payload.removeAll
  const isFirstPage = actions &&
        !Array.isArray(actions) &&
        actions.type === 'PAGE_UPDATE' &&
        actions.payload.page === 1

  return isClearAllAction || isFirstPage
}
