export function stringSort (property: string) {
  return (a: Record<string, any>, b: Record<string, any>): number => {
    const stringA = (a as any)[property].toUpperCase()
    const stringB = (b as any)[property].toUpperCase()
    let comparison = 0

    if (stringA > stringB) {
      comparison = 1
    } else if (stringA < stringB) {
      comparison = -1
    }
    return comparison
  }
}
