import { QueryFunctionContext } from '@tanstack/react-query'
import { getChoiceGroupsPricingText } from '~/client/utils/getChoiceGroupPricingText'

export const QUERY_KEY = 'pricing_text'

export const queryPricingText = (
  context: QueryFunctionContext<VP.MTT.Queries.TokenEngine.PricingTextQueryKey>
): Promise<VP.MTT.Models.TokenEngine.PricingTextResponse | null> => {
  const { queryKey } = context
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, {
    pricingResponse,
    quantity,
    locale,
    vatInc,
    hideVatMessage,
    pricingPresentationType,
    hideShippingMessageInVatMessage,
  }] = queryKey

  return getChoiceGroupsPricingText(
    pricingPresentationType,
    locale,
    hideVatMessage,
    vatInc,
    pricingResponse,
    quantity,
    hideShippingMessageInVatMessage
  )
}
