import { createSelector } from 'reselect'

import { facetByIdSelector } from '~/client/store/facet'
import { filterOptionByIdSelector } from '~/client/store/filterOption'
import { refinementsHasRefSelector } from '~/client/store/refinement'
import { categoryByIdSelector } from '~/client/store/filterCategories'
import { stringSort } from '~/client/utils/sort'

/**
 * Builds a `RenderableFilterOption` for each option value, getting the label
 *   and other details like facet count, selected state, and disabled status
 * @param {State.GlobalState} state
 * @param {string[]} options
 */
export const buildFilterOptions = (state: State.GlobalState, options: string[]): RenderableFilterOption[] => {
  const getFacet = facetByIdSelector(state)
  const getOption = filterOptionByIdSelector(state)
  const isSelected = refinementsHasRefSelector(state)

  return options.map((o: string) => {
    const facet = getFacet(o)
    const option = getOption(o) as RenderableFilterOption

    option.selected = !!isSelected(o)
    option.facetCount = 0
    option.disabled = !facet || facet.count <= 0
    return option
  })
}

/**
 * Returns the first selected child category for the given root
 */

export const selectedL1SelectorFactory = () => createSelector(
  categoryByIdSelector,
  refinementsHasRefSelector,
  (_state: State.GlobalState, rootId: string) => rootId,
  (getCategory, getSelected, rootId): { selectedCategoryId: string | null } => {
    const root = getCategory(rootId)

    if (!root || !root.children || root.children.length <= 0) {
      return {
        selectedCategoryId: null,
      }
    }

    const selectedId = root.children.find((c: string) => !!getSelected(c))

    return {
      selectedCategoryId: selectedId || null,
    }
  }
)

export const selectedChildrenSelectorFactory = () => createSelector(
  categoryByIdSelector,
  refinementsHasRefSelector,
  (_state: State.GlobalState, rootId: string) => rootId,
  (getCategory, getSelected, rootId): SelectedValues => {
    const root = getCategory(rootId)

    if (!root || !root.children || root.children.length <= 0) {
      return {}
    }

    return root.children
      .reduce((accum, c) => {
        const selected = !!getSelected(c)

        return {
          ...accum,
          [c]: selected,
        }
      }, {} as SelectedValues)
  }
)

/**
 * Returns a selector to generate sorted filter options for a given category filter
 */

export const categoryOptionsSelectorFactory = () => createSelector(
  categoryByIdSelector,
  facetByIdSelector,
  refinementsHasRefSelector,
  (_state: State.GlobalState, rootId: string, shouldAddDisabled: boolean) => ({ rootId, shouldAddDisabled }),
  (getCategory, getFacet, getSelected, { rootId, shouldAddDisabled }) => {
    const root = getCategory(rootId)
    const selectedValues: SelectedValues = {}

    if (!root || !root.children || root.children.length <= 0) {
      return {
        options: [],
        selectedValues,
        visible: false,
      }
    }

    let enabled = false // the filter will be rendered if there is at least one
    let options = root.children.map((c: string) => {
      const category = getCategory(c)
      const facet = getFacet(c)
      const disabled = !facet || facet.count <= 0

      if (!disabled) {
        enabled = true
      }

      const selected = !!getSelected(c)

      if (selected) {
        selectedValues[c] = selected
      }

      return {
        disabled,
        selected,
        value: c,
        title: category.title,
        facetCount: 0,
      }
    }) as RenderableCategoryFilterOption[]

    // sort, rank alphabetically, removing unavailable options
    options = options.filter((o) => shouldAddDisabled || !o.disabled)
      .sort(stringSort('title'))
      .map((c: RenderableCategoryFilterOption, i: number) => ({
        ...c,
        rank: i,
      }))

    return {
      options,
      selectedValues,
      visible: enabled,
    }
  }
)
