import { useSelector } from 'react-redux'

import { buildFilterOptions } from '~/client/components/Gallery/Filters/util'

export const useFilterOptions = (
  filter: State.AttributeFilter
): State.FilterOption => useSelector((state: State.GlobalState): State.FilterOption => {
  const options = buildFilterOptions(state, filter.options).filter((o) => o.facetCount != null)

  // only render the filter if there is at least one enabled item
  const visible = options.some((o) => !o.disabled)
  const selected = options.find((o) => !!o.selected)

  return {
    options,
    selected: selected?.value || '',
    visible,
  }
})
