import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { CONTENT_UPDATE } from '../constants'
import {
  getLocaleSelector,
  stringRenderPropertySelector,
  booleanRenderPropertySelector,
} from '../config'
import { RenderProperty } from 'shared/renderProperties'
import { MAXIMUM_PREVIEW_HEIGHT, MINIMUM_PREVIEW_HEIGHT, TILE_PREVIEW_SIZE } from '~/client/constants'
import { getBypassApproval } from '../debug'
import { PREVIEW_TYPE } from 'shared/constants'
import { calculateHeightFromAspectRatio, calculateWidthFromAspectRatio } from '~/client/utils/aspectRatio'
import { tileEntityByIdSelector } from '../tileEntity'
import { getPreviewData } from '~/client/utils/previewData'
// import { getLogger } from '~/client/utils/gallery/logger'
import { designPersonalizationContextIdSelector } from '../designPersonalization/selectors'

const initialState: State.DesignState = {
  byId: {},
  allIds: [],
}

const updateState = (
  action: AnyAction,
  state: State.DesignState = initialState
): State.DesignState => ({
  ...state,
  byId: action.payload.designs.byId,
  allIds: action.payload.designs.allIds,
})

export function reducer (
  state: State.DesignState = initialState,
  action: AnyAction
): State.DesignState {
  switch (action.type) {
    // CK THIS IS THE THING
    case CONTENT_UPDATE:
      return updateState(action, state)
    default:
      return state
  }
}

export const designsSelector = (state: State.GlobalState): State.DesignState => state.designs
export const designByIdSelector = createSelector(
  (state: State.GlobalState) => state.designs,
  (designs) => (id: string): State.Design => {
    return designs.byId[id]
  }
)

export const previewDimensionSelector = createSelector(
  designByIdSelector,
  stringRenderPropertySelector,
  (designById, stringRenderProperty) => (
    designId: string,
    previewType: PREVIEW_TYPE = PREVIEW_TYPE.TILE
  ): Preview.PreviewDimension => {
    const { previewInfo } = designById(designId)
    const tileSize = stringRenderProperty(RenderProperty.TilePreviewSize) as TILE_PREVIEW_SIZE
    const scenes = previewInfo.scenes || {}

    // fall back to tile scene if the requested scene doesn't exist
    const sceneData = scenes[previewType] || scenes[PREVIEW_TYPE.TILE] || {}
    const aspectRatio = sceneData.aspectRatio || previewInfo.aspectRatio

    const previewWidth = calculateWidthFromAspectRatio(aspectRatio, tileSize)
    const previewHeight = calculateHeightFromAspectRatio(aspectRatio, previewWidth)

    return {
      aspectRatio,
      previewInfo,
      previewHeight,
      previewWidth,
      sceneData,
    }
  }
)

export const renderablePreviewInfoSelector = createSelector(
  getLocaleSelector,
  booleanRenderPropertySelector,
  stringRenderPropertySelector,
  designByIdSelector,
  tileEntityByIdSelector,
  getBypassApproval,
  designPersonalizationContextIdSelector,
  (
    locale,
    booleanRenderProperty,
    stringRenderProperty,
    designById,
    tileEntityById,
    bypassApproval,
    designPersonalizationContextId
  ) => (
    currentDesignId: string,
    entityId: string,
    previewType: PREVIEW_TYPE = PREVIEW_TYPE.TILE,
    ignorePersonalization = false, // For instance, if selecting from favorites
    previewInfoProp?: Gallery.ContentQuery.PreviewInfo
  ): Gallery.Designs.RenderablePreviewInfo => {
    const contentBackgroundColor = stringRenderProperty(RenderProperty.ContentBackgroundColor)
    const previewInfo = previewInfoProp ?? designById(currentDesignId)?.previewInfo
    const { previewUrls } = designById(currentDesignId)
    const showPersonalizationUI = booleanRenderProperty(RenderProperty.ShowPersonalizationUI)
    const tilePreviewSize = stringRenderProperty(RenderProperty.TilePreviewSize)
    const imageCacheVersion = stringRenderProperty(RenderProperty.ImageCacheVersion)
    const sceneSource = stringRenderProperty(RenderProperty.SceneSource)

    const {
      productKey, productVersion, fullProductOptions,
    } = tileEntityById(entityId)

    return getPreviewData(
      previewInfo,
      locale,
      contentBackgroundColor,
      previewType,
      previewUrls,
      sceneSource,
      showPersonalizationUI,
      tilePreviewSize,
      bypassApproval,
      imageCacheVersion,
      productKey,
      productVersion,
      fullProductOptions,
      !ignorePersonalization ? designPersonalizationContextId : undefined
    )
  }
)

export const maxPreviewHeightSelector = createSelector(
  (state: State.GlobalState) => state.designs.allIds,
  previewDimensionSelector,
  designByIdSelector,
  tileEntityByIdSelector,
  (allIds, getPreviewDimensions, getDesign, getTileEntity): [number, string] => {
    const heightIndex = new Set()
    const heights = allIds.filter((id) => {
      const design = getDesign(id)
      const entity = getTileEntity(design.entityId)

      return !entity?.isQSPEntity
    }).map((id) => {
      const { previewHeight } = getPreviewDimensions(id, PREVIEW_TYPE.TILE)

      heightIndex.add(previewHeight)

      return previewHeight
    })

    // Use 100% height when all designs are the same or different size
    // When no dimensions available yet, use a base minimum height
    const heightSize = heightIndex.size === 0 ? `${MINIMUM_PREVIEW_HEIGHT}px` : '100%'

    return [Math.min(Math.max(...heights), MAXIMUM_PREVIEW_HEIGHT), heightSize]
  }
)

export const maxTileWidthSelector = createSelector(
  (state: State.GlobalState) => state.designs.allIds,
  previewDimensionSelector,
  (allIds, getPreviewDimensions) => Math.max(
    ...allIds.map((id) => getPreviewDimensions(id, PREVIEW_TYPE.TILE).previewWidth)
  )
)

export const colorSwatchSelector = createSelector(
  renderablePreviewInfoSelector,
  designByIdSelector,
  tileEntityByIdSelector,
  (state: State.GlobalState) => state.designs,
  (renderablePreviewInfo, designById, tileEntityById, designs) => (
    designId: string,
    entityId: string,
    previewType: PREVIEW_TYPE,
    ignorePersonalization = false
  ): Gallery.Designs.CurrentColorSwatch => {
    const renderablePreviews = renderablePreviewInfo(
      designId,
      entityId,
      previewType,
      ignorePersonalization
    )
    const { color, studioUrl } = designById(designId)
    const { colorSwatches } = tileEntityById(entityId)
    const currentColorSwatchId = colorSwatches.find((cs) => cs === designId) as string
    const currentColorSwatch = designs.byId[currentColorSwatchId]

    return {
      ...currentColorSwatch,
      color,
      designId,
      previewType,
      renderablePreviews,
      studioUrl,
      colorSwatches: colorSwatches.map((cs) => designs.byId[cs]).filter(Boolean),
    }
  }
)

// export const colorSwatchSelectorWithDesignVariation = (state: State.GlobalState) => (
//   designId: string,
//   entityId: string,
//   designVariation: DesignVariations.DesignVariationResponse | undefined,
//   previewType: PREVIEW_TYPE,
//   impressionId?: string,
//   selectedProductOptions?: Gallery.ContentQuery.ProductOptions
// ): Gallery.Designs.CurrentColorSwatch => {
//   const currentColorSwatch = designVariation?.colorSwatches.length
//     ? designVariation?.colorSwatches.find((c) => c.designId === designId)
//     : (designVariation || {}) as Gallery.ContentQuery.ColorSwatch

//   if (!currentColorSwatch || !designVariation) {
//     try {
//       return colorSwatchSelector(state)(designId, entityId, previewType)
//     } catch (e) {
//       console.log(e)
//       // TODO uncomment
//       // getLogger().error({
//       //   designId,
//       //   entityId,
//       //   designVariation,
//       //   code: 'SD001',
//       //   message: `Current color swatch is null and old designID is being used: ${(e as Error).message}`,
//       // }, e as Error)
//       throw e
//     }
//   }
//   const { colorSwatches, studioUrl } = designVariation
//   const locale = getLocaleSelector(state)
//   const contentBackgroundColor = stringRenderPropertySelector(state)(RenderProperty.ContentBackgroundColor)
//   const bypassApproval = getBypassApproval(state)
//   const tilePreviewSize = stringRenderPropertySelector(state)(RenderProperty.TilePreviewSize)
//   const imageCacheVersion = stringRenderPropertySelector(state)(RenderProperty.ImageCacheVersion)
//   const showPersonalizationUI = booleanRenderPropertySelector(state)(RenderProperty.ShowPersonalizationUI)
//   const sceneSource = stringRenderPropertySelector(state)(RenderProperty.SceneSource)
//   const designPersonalizationContextId = designPersonalizationContextIdSelector(state)

//   const {
//     productKey, productVersion, fullProductOptions,
//   } = tileEntityByIdSelector(state)(entityId)
//   const quantity = getQuantitySelector(state)
//   const { color, previewUrls, isLightColor } = currentColorSwatch
//   const renderablePreviews = getPreviewData(
//     currentColorSwatch ? currentColorSwatch.previewInfo : designVariation.previewInfo,
//     locale,
//     contentBackgroundColor,
//     PREVIEW_TYPE.QUICKVIEW,
//     previewUrls,
//     sceneSource,
//     showPersonalizationUI,
//     tilePreviewSize,
//     bypassApproval,
//     imageCacheVersion,
//     productKey,
//     productVersion,
//     {
//       // Contains the initial, complete selections. These need to be complete for DSS calls
//       ...fullProductOptions,
//       // Overwrite with the most correct selections (e.g., selections in QV)
//       ...selectedProductOptions,
//     },
//     designPersonalizationContextId
//   )

//   return {
//     color,
//     colorSwatches,
//     designId,
//     previewType,
//     renderablePreviews,
//     previewUrls,
//     isLightColor,
//     externalId: currentColorSwatch.externalId,
//     designColors: currentColorSwatch.designColors,
//     colorComposition: currentColorSwatch.colorComposition,
//     previewInfo: currentColorSwatch ? currentColorSwatch.previewInfo : designVariation.previewInfo,
//     studioUrl: buildFullyQualifiedVistaprintUrl({
//       path: currentColorSwatch ? currentColorSwatch.studioUrl : studioUrl,
//       locale,
//       quantity,
//       impressionId,
//     }),
//   }
// }
