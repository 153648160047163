import { useSelector, useDispatch } from 'react-redux'
import {
  Dropdown, DropdownOption, FormField, FormLabel, FormInputGroup,
} from '@vp/swan'

import {
  currentPageSelector,
  currentPageSizeSelector,
  pageSizeAndContentUpdate,
  pageSizeOptionsSelector,
} from '~/client/store/paging'
import { useTranslations } from '~/client/hooks/useTranslations'
import { scrollUpToElement } from '~/client/utils/scrollToElement'
import { galleryHeaderId } from '~/client/components/Gallery/Header/constants'

interface PropTypes {
  className?: string;
}

export const PerPage = (props: PropTypes) => {
  const { className } = props
  const localize = useTranslations()
  const dispatch = useDispatch()
  const pageSizeOptions = useSelector(pageSizeOptionsSelector)
  const page = useSelector(currentPageSelector)
  const pageSize = useSelector(currentPageSizeSelector)

  const onPageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    event.preventDefault()

    const newSize = parseInt(event.currentTarget.value, 10)

    dispatch(pageSizeAndContentUpdate(page, pageSize, newSize))
    scrollUpToElement(galleryHeaderId)
  }

  return (
    <section className={className || 'perpage'}>
      <FormField>
        <FormLabel visuallyHidden>
          {localize('DesignsPerPage')}
        </FormLabel>
        <FormInputGroup>
          <Dropdown
            value={pageSize.toString()}
            onChange={onPageSizeChange}
          >
            {pageSizeOptions.map((pageSizeOption: number) => (
              <DropdownOption
                aria-selected={pageSize.toString() === pageSizeOption.toString()}
                key={pageSizeOption}
                value={pageSizeOption}
              >
                {localize('PerPageSelection', {
                  size: pageSizeOption.toString(),
                })}
              </DropdownOption>
            ))}
          </Dropdown>
        </FormInputGroup>
      </FormField>
    </section>
  )
}
