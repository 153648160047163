import {
  memo, MouseEvent,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Pagination as SwanPagination } from '@vp/swan'

import { PerPage } from '~/client/components/Gallery/Subheader/PerPage'
import { useTranslations } from '~/client/hooks/useTranslations'
import { PaginationButton } from '~/client/components/Gallery/Pagination/PaginationButton'
import { PaginationStep } from '~/client/components/Gallery/Pagination/PaginationStep'
import { PaginationSteps } from '~/client/components/Gallery/Pagination/PaginationSteps'
import {
  currentPageSelector,
  pageAndContentUpdate,
  numberOfPagesSelector,
  totalEntityCountSelector,
} from '~/client/store/paging'
import { galleryHeaderId } from '~/client/components/Gallery/Header/constants'
import { handleBlur } from '~/client/utils/navigation'
import { scrollUpToElement } from '~/client/utils/scrollToElement'

const MAX_PAGE_LINKS = 5
const STARTING_PAGE_NUMBER = 1

export const Pagination = memo(() => {
  const localize = useTranslations()
  const dispatch = useDispatch()

  const currentPage = useSelector(currentPageSelector)
  const numberOfPages = useSelector(numberOfPagesSelector)
  const resultCount = useSelector(totalEntityCountSelector)

  // localization
  const nextPageLabel = localize('PageNext')
  const previousPageLabel = localize('PagePrevious')
  const pageLinkPrefix = localize('PageTitle')
  const pageNumberLocalizer = (page: number): string => page.toString()
  const paginationLabel = localize('PaginationTitle')

  const shouldRenderMiddleSteps = numberOfPages > 2

  const handlePageChange = (page: number): void => {
    dispatch(pageAndContentUpdate(page))
    scrollUpToElement(galleryHeaderId)
  }

  const nextPage = (currentPage === numberOfPages) ? numberOfPages : currentPage + 1
  const handleNextPage = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault()

    handleBlur()

    handlePageChange(nextPage)
  }

  const handlePageChangeFactory: Gallery.Pagination.HandlePageChangeFactory = (page: number) => (
    event: MouseEvent<HTMLAnchorElement>
  ): void => {
    event.preventDefault()

    handleBlur()

    handlePageChange(page)
  }

  const prevPage = (currentPage === STARTING_PAGE_NUMBER) ? STARTING_PAGE_NUMBER : currentPage - 1
  const handlePreviousPage = (event: MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault()

    handleBlur()

    handlePageChange(prevPage)
  }

  return (
    <section className='pagination-container'>
      {!!numberOfPages && (
        <SwanPagination accessibleText={paginationLabel} className='pagination-component'>
          <PaginationButton
            accessibleText={previousPageLabel}
            disabled={(numberOfPages === 0 || currentPage === STARTING_PAGE_NUMBER)}
            pageNumber={prevPage}
            variant='previous'
            onClick={handlePreviousPage}
          />
          <PaginationStep
            accessibleText={`${pageLinkPrefix} ${STARTING_PAGE_NUMBER}`}
            current={STARTING_PAGE_NUMBER === currentPage}
            key={STARTING_PAGE_NUMBER}
            pageNumber={STARTING_PAGE_NUMBER}
            pageText={pageNumberLocalizer(STARTING_PAGE_NUMBER)}
            onClick={handlePageChangeFactory(STARTING_PAGE_NUMBER)}
          />
          {/* Remaining list of page links */}
          {shouldRenderMiddleSteps && (
            <PaginationSteps
              currentPage={currentPage}
              maxPageLinks={MAX_PAGE_LINKS}
              numberOfPages={numberOfPages}
              pageLinkPrefix={pageLinkPrefix}
              pageNumberLocalizer={pageNumberLocalizer}
              startingPageNumber={STARTING_PAGE_NUMBER}
              onClick={handlePageChangeFactory}
            />
          )}

          {(numberOfPages > STARTING_PAGE_NUMBER) && (
            <PaginationStep
              accessibleText={`${pageLinkPrefix} ${numberOfPages}`}
              current={numberOfPages === currentPage}
              key={numberOfPages}
              pageNumber={numberOfPages}
              pageText={pageNumberLocalizer(numberOfPages)}
              onClick={handlePageChangeFactory(numberOfPages)}
            />
          )}
          <PaginationButton
            accessibleText={nextPageLabel}
            disabled={(numberOfPages === 0 || currentPage === numberOfPages)}
            pageNumber={nextPage}
            variant='next'
            onClick={handleNextPage}
          />
        </SwanPagination>
      )}
      {!!resultCount && <PerPage className='header-hierarchy-perpage' />}
    </section>
  )
})
