import classnames from 'classnames'
import { HTMLProps, memo, } from 'react'
// import { FilterMenu } from '~/components/Gallery/Header/FilterMenu'
import { SearchBox } from '~/client/components/Gallery/Header/Searchbox'
// import { FavoritesCTA } from '~/components/Gallery/Header/Favorites'
// import { useAuth } from '~/hooks/useAuth'
// import { useFavorites } from '~/hooks/features/useFavorites'
// import { SearchButton } from '~/components/Gallery/Header/SearchButton'
// import { HeaderPersonalizationButton } from '~/components/Gallery/Header/PersonalizationV1/MobilePersonalization/HeaderPersonalizationButton'
// import { useIsPersonalizationEnabled } from '~/hooks/useIsPersonalizationEnabled'

export const StickyHeader = memo((props: HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props
  //   const showFavorites = useFavorites()
  //   const auth = useAuth()
  //   const shouldShowFavorites = showFavorites && auth
  //   const { isMobilePersonalizationEnabled, shouldShowColorFilterFirst } = useIsPersonalizationEnabled()

  return (
    <div
      className={classnames('sticky-header', 'mobile-sticky-header-visible', className)}
      {...rest}
    >
      <div className='mobile-sticky-header-bounded-content'>
        <div className='mobile-sticky-header-actions'>
          <div className='subheader-actions'>
            <SearchBox />
            {/* <SearchButton shouldHideText={isMobilePersonalizationEnabled} />
            {shouldShowFavorites && (<FavoritesCTA userInteractionLocation='staticHeader' />)}
            <FilterMenu
              shouldRenderSmallMobileIcon={isMobilePersonalizationEnabled}
              showColorFilterFirst={shouldShowColorFilterFirst}
            />
            {isMobilePersonalizationEnabled && <HeaderPersonalizationButton />} */}
          </div>
        </div>
      </div>
    </div>
  )
})

StickyHeader.displayName = 'StickyHeader'
