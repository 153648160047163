import {
  JSX,
  useCallback, useEffect, useState,
} from 'react'
import { tileEntityByIdSelector } from '~/client/store/tileEntity'
import { useSelector } from 'react-redux'
import { colorSwatchSelector, designsSelector } from '~/client/store/design'
import {
  AnalyticsNames, ANALYTICS_EVENT_ACTIONS, ANALYTICS_LABEL, PREVIEW_TYPE,
} from 'shared/constants'
import { resolveInitialSwatchId } from '~/shared/resolveInitialSwatchId'
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics'
import { getBypassApproval } from '~/client/store/debug'
// import { galleryImpressionIdSelector } from '~/client/store/analytics/reducer'
// import { useGreetingDescription } from '~/hooks/useGreetingDescription/useGreetingDescription'
// import { useSocialMediaGallery } from '~/hooks/useSocialMediaGallery'
import { designPersonalizationContextSelector } from '~/client/store/designPersonalization/selectors'
// import { useNewTemplateAltText } from '~/hooks/useNewTemplateAltText'
import { NUMBER_OF_IMAGES_WITH_HIGH_PRIORITY } from '~/client/components/Gallery/PreviewArea/constants'
import { BaseImageProps } from '~/client/components/Gallery/DesignTile/interface'
import { usePrefetchQuery } from '~/client/components/Gallery/PreviewArea/hooks'

export type PropTypes = {
  tileEntityId: string;
  children: (
    tileEntity: State.TileEntity,
    imageProps: BaseImageProps,
    handleColorSwatchChange: (newDesignId: string) => void,
    colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[],
    currentColorSwatch: Gallery.Designs.CurrentColorSwatch,
    currentDesignId: string,
    greetingOptions: string,
    greetingTitle: string,
    shouldShowGreetingOptions: boolean,
    shouldHideForSocialMedia: boolean,
    isPreviouslyOrdered: boolean,
    onMouseEnter?: () => void,
  ) => JSX.Element | null;
}

function createTileAnalyticsData (
  tileEntity: State.TileEntity
): Gallery.Analytics.DesignAnalyticsObject {
  return {
    tileEntity,
    position: tileEntity.position,
    location: AnalyticsNames.DesignTile,
  }
}

export const GalleryTileEntityProvider = (props: PropTypes): JSX.Element | null => {
  const { tileEntityId, children } = props
  const tileEntityById = useSelector(tileEntityByIdSelector)
  const selectColorSwatch = useSelector(colorSwatchSelector)
  const analytics = useAnalytics()
  const designs = useSelector(designsSelector)
  const tileEntity = tileEntityById(tileEntityId)
  const [currentDesignId, setCurrentDesignId] = useState(resolveInitialSwatchId(tileEntity))
  const shouldHideForSocialMedia = true // useSocialMediaGallery()
  const dpc = useSelector(designPersonalizationContextSelector)
  const isPreviouslyOrdered = !!tileEntity.isPreviouslyOrdered

  const {
    greetingTitle,
    greetingOptions,
    shouldShowGreetingOptions,
  } = {
    greetingTitle: 'TODO',
    greetingOptions: 'TODO',
    shouldShowGreetingOptions: false,
  } // useGreetingDescription(tileEntity)

  const currentColorSwatch = selectColorSwatch(
    currentDesignId,
    tileEntityId,
    PREVIEW_TYPE.TILE
  )

  //   const previewAlt = useNewTemplateAltText(tileEntity, currentColorSwatch.colorSwatches)
  const onMouseEnter = usePrefetchQuery(tileEntity)
  const bypassedApproval = useSelector(getBypassApproval)
  const impressionId = 'TODO' // useSelector((state: State.GlobalState) => galleryImpressionIdSelector(state, tileEntityId))

  const { previewsUrls } = currentColorSwatch.renderablePreviews

  const imageProps: BaseImageProps = {
    alt: 'TODO',
    previewsUrls,
    bypassedApproval,
  }

  if (tileEntity.position < NUMBER_OF_IMAGES_WITH_HIGH_PRIORITY) {
    imageProps.fetchpriority = 'high'
  }

  // Update currentDesignId when tileEntityId changes to ensure color filters affect the selected color swatch
  useEffect(() => {
    setCurrentDesignId(resolveInitialSwatchId(tileEntity))
    // eslint -disable-next-line react-hooks/exhaustive-deps TODO
  }, [tileEntity?.selectedColorSwatchDesignId])

  const handleColorSwatchChange = useCallback((newDesignId: string): void => {
    const color = designs.byId[newDesignId].color?.split('#')[1]
    const analyticsData = createTileAnalyticsData(tileEntity)
    const colorSwatchPersonalizedDetail = dpc ? 'Tile color selection with personalized design' : ''

    analytics.trackEvent({
      action: ANALYTICS_EVENT_ACTIONS.GALLERY_DESIGN_ENGAGEMENT,
      eventLabel: ANALYTICS_LABEL.SELECT_COLOR_SWATCH,
      eventDetail: colorSwatchPersonalizedDetail,
      ...analytics.buildDesignEngagement({
        engagementAction: ANALYTICS_LABEL.SELECT_COLOR_SWATCH,
        selectedDesign: newDesignId,
        color,
        tileEntity: analyticsData.tileEntity,
        colorSwatchObjects: currentColorSwatch.colorSwatches,
        impressionId,
        location: analyticsData.location,
        position: analyticsData.tileEntity.position,
      }),
    })

    setCurrentDesignId(newDesignId)
  }, [designs.byId, tileEntity, dpc, analytics, currentColorSwatch.colorSwatches, impressionId])

  return children(
    tileEntity,
    imageProps,
    handleColorSwatchChange,
    currentColorSwatch.colorSwatches,
    currentColorSwatch,
    currentDesignId,
    greetingOptions,
    greetingTitle,
    shouldShowGreetingOptions,
    shouldHideForSocialMedia,
    isPreviouslyOrdered,
    onMouseEnter
  )
}
