import React, { forwardRef } from 'react'
import { ContextualBatchPricingDisplay } from '~/client/components/common/PricingDisplay/ContextualBatchPricingDisplay'
import { Tile } from '~/client/components/common/Tile'
import { Icon, Typography } from '@vp/swan'
import { useSocialMediaGallery } from '~/client/hooks/useSocialMediaGallery'
import { useTranslations } from '~/client/hooks/useTranslations'
import { useSelector } from 'react-redux'
import {
  getProductKey,
  getProductVersion,
  getLocaleSelector,
  getMarket,
  getQuantitySelector,
} from 'client/store/config'
import { PRICING_PRESENTATION_TYPES } from '~/client/constants'

export type PropTypes = Gallery.Analytics.ButtonLocationProp & React.HTMLProps<HTMLElement> & {
  previewHeight: number;
  productOptionsByKey: Gallery.Product.ProductOptionsGroupsByProductKey | null;
  pricingPresentationType?: string | null;
}

export const UploadTile = forwardRef<HTMLLIElement, PropTypes>((props: PropTypes, ref) => {
  const {
    previewHeight,
    productOptionsByKey,
    pricingPresentationType = PRICING_PRESENTATION_TYPES.QTY_FROM_PRICE,
  } = props

  const localize = useTranslations()
  const locale = useSelector(getLocaleSelector)
  const market = useSelector(getMarket)
  const productKey = useSelector(getProductKey)
  const productVersion = useSelector(getProductVersion)
  const quantity = useSelector(getQuantitySelector)
  const shouldHideForSocialMedia = useSocialMediaGallery()

  return (
    <Tile
      ref={ref}
      style={{
        minHeight: `${previewHeight}px`,
        height: '100%',
      }}
      type='fbu'
    >
      <div className='clickable tile-action-area fbu-action-area' role='presentation'>
        <div className='fbu-content'>
          <div className='fbu-upload-icon-circle'>
            <Icon className='fbu-upload-icon' iconType='upload' />
          </div>

          <Typography
            className='fbu-header-text'
            fontWeight='bold'
          >
            {localize('FullBleedUploadTileHeader')}
          </Typography>
          <Typography className='caption-text'>
            {!shouldHideForSocialMedia && (
              <ContextualBatchPricingDisplay
                lines={1}
                locale={locale}
                market={market}
                pricingPresentationType={pricingPresentationType}
                productKey={productKey}
                productOptionsByProductKey={productOptionsByKey}
                productOptionsHash={Object.keys(productOptionsByKey?.[productKey] ?? {})?.[0]}
                productVersion={productVersion || undefined}
                quantity={quantity}
              />
            )}
          </Typography>
        </div>
      </div>
    </Tile>
  )
})
