import { useState } from 'react'
import { List, ListItem, Button, } from '@vp/swan'
import { useDispatch, useSelector } from 'react-redux'
import { ShowMoreButton } from '~/client/components/Gallery/Filters/Shared/ShowMoreButton'
import { FilterOption } from '~/client/components/Gallery/Filters/FilterOption'
import { buildRefinement, refinementAdd } from '~/client/store/refinement'
import { ANALYTICS_LABEL, REFINEMENT_DIMENSION } from 'shared/constants'
import { scrollUpToElement } from '~/client/utils/scrollToElement'
import { galleryHeaderId } from '~/client/components/Gallery/Header/constants'
// import { isFilterModalHidden } from '~/components/Gallery/Header/FilterMenu/utils'
// import { useRedirectToL0 } from '~/~/hooks/RedirectToL0/useRedirectToL0'
// import { useAnalytics } from '~/~/hooks/gallery/useAnalytics'
// import { TRACKING_BEHAVIOR, useExperimentation } from '~/~/hooks/useExperimentation'
// import { getRawExperiments } from '~/~/store/experimentation'
// import { PERSONALIZATION_UX_BRAND_VARIATIONS, PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME } from '~/experiments/Personalization/constants'
// import { trackExperimentEngagement } from '~/shared/ab-testing'

export interface L1FilterProps {
  filter: State.Filter;
  options: RenderableFilterOption[];
  refinementDimension: REFINEMENT_DIMENSION;
}

export const L1Filter = (props: L1FilterProps) => {
  const { options, filter, refinementDimension } = props
  const dispatch = useDispatch()
  const [showMore, setShowMore] = useState(false)
  const bestSellingCount = (refinementDimension === REFINEMENT_DIMENSION.CATEGORY)
    ? (filter as State.CategoryFilter).bestSellingCount || Number.MAX_SAFE_INTEGER
    : Number.MAX_SAFE_INTEGER
  const availableOptions = options.filter((o) => showMore || !o.rank || o.rank < bestSellingCount)

  //   const redirectToL0 = useRedirectToL0()

  // hooks added for temporary Personalization UX experiment tracking
  //   const isExperimentActive = useExperimentation()
  //   const rawExperiments = useSelector(getRawExperiments)
  //   const analytics = useAnalytics()

  const curryHandleL1Selection = (id: string) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      // TODO uncomment analytics
    //   if (isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Suppress)) {
    //     trackExperimentEngagement(
    //       PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
    //       rawExperiments,
    //       analytics,
    //       ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
    //       'Filter Update',
    //       PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
    //       filter.name
    //     )
    //   } else if (isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Control, TRACKING_BEHAVIOR.Suppress)) {
    //     trackExperimentEngagement(
    //       PERSONALIZATION_UX_BRAND_VARIATIONS.Control,
    //       rawExperiments,
    //       analytics,
    //       ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
    //       'Filter Update',
    //       PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
    //       filter.name
    //     )
    //   }
      event.preventDefault()

      const refinement = buildRefinement(id, refinementDimension)

      //   if (redirectToL0([refinement], [])) {
      //     return
      //   }

      dispatch(refinementAdd([refinement]))
    //   if (isFilterModalHidden()) {
    //     scrollUpToElement(galleryHeaderId)
    //   }
    }

  return (
    <>
      <List aria-label={filter.title} skin='minimal'>
        {availableOptions.map((o) => (
          <ListItem key={o.value}>
            <Button
              disabled={o.disabled}
              skin='unstyled'
              onClick={curryHandleL1Selection(o.value)}
            >
              <FilterOption facetCount={o.facetCount} title={o.title} />
            </Button>
          </ListItem>
        ))}
      </List>

      {options.length > bestSellingCount && (
        <ShowMoreButton showMore={showMore} title={filter.title} onClick={(): void => setShowMore(!showMore)} />
      )}
    </>
  )
}

L1Filter.displayName = 'L1Filter'
