import { AnyAction } from 'redux'

const initialState: State.AttributeNameByFilterNameState = {}

export function reducer (
  state: State.AttributeNameByFilterNameState = initialState,
  action: AnyAction
): State.AttributeNameByFilterNameState {
  switch (action.type) {
    default:
      return state
  }
}

export const attributeNameByFilterNameSelector = (state: State.GlobalState) => (filterName: string): string => state.attributeNameByFilterName[filterName]
