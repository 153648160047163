import classnames from 'classnames'
import { ClearAllButton } from '@vp/swan'

import { useClearRefinements } from '~/client/hooks/useClearRefinements'
import { useTranslations } from '~/client/hooks/useTranslations'

interface PropTypes extends React.ComponentProps<typeof ClearAllButton> {
  refinements: Refinements.Refinements;
}

/**
 *
 * @param props
 */
export const ClearAllRefinementsButton = (props: PropTypes) => {
  const { refinements, ...rest } = props
  const localize = useTranslations()
  const { curryClearAll } = useClearRefinements()

  return (
    <ClearAllButton
      {...rest}
      aria-label={localize('ClearAllRefinementsSelection')}
      as='button'
      className={classnames('clear-all-button', 'secondary')}
      onClick={curryClearAll(refinements)}
    >
      {localize('SelectedRefinementsClearAll')}
    </ClearAllButton>
  )
}
