import { getBreadcrumbs } from '~/client/store/config/reducer'
import { useSelector } from 'react-redux'
import { Breadcrumb, Breadcrumbs } from '@vp/swan'
import { useTranslations } from '~/client/hooks/useTranslations'
import { JSX } from 'react'

export const BreadcrumbsContainer = (): JSX.Element | null => {
  const breadcrumbs = useSelector(getBreadcrumbs)
  const localize = useTranslations()

  const hasValidBreadcrumbs = !!breadcrumbs?.length && breadcrumbs?.some((crumb) => crumb.available === true)

  return hasValidBreadcrumbs
    ? (
      <Breadcrumbs accessibleTextForMore={localize('BreadcrumbsMoreAccessibleText')} aria-label='breadcrumbs'>
        {breadcrumbs?.map((crumb) => (
          crumb.available && (
            <Breadcrumb key={crumb.name}>
              <a href={crumb.url}>{crumb.name}</a>
            </Breadcrumb>
          )
        ))}
      </Breadcrumbs>
      )
    : null
}

BreadcrumbsContainer.displayName = 'BreadcrumbsContainer'
