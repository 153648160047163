import { contentUpdate } from '../content'
import { AnyAction } from 'redux'

export const PAGE_SIZE_UPDATE = 'PAGE_SIZE_UPDATE'
export const PAGE_UPDATE = 'PAGE_UPDATE'

/**
 * Given that `pageSize` has possibly changed, we need to find the page the
 * page the user is supposed to be on.
 * If the pageSize didn't actually change, the short-circuit and return the
 * current page. Otherwise, the new page is found by taking the total number
 * of tileEntities seen and dividing it by the new `pageSize` to get what
 * total number of tileEntities should be shown now. For example,
 * if we've seeing 48 tileEntities with `pageSize` of 24, we're currently
 * on the second page. If we change the `pageSize` to 48, we should now be on
 * page 1.
 *
 * @param curentPage
 * @param currentPageSize
 * @param newPageSize
 * @param numberOfPages
 */
export function calculateCurrentPage (curentPage: number, currentPageSize: number, newPageSize: number): number {
  if (newPageSize === currentPageSize) {
    return curentPage
  }

  return Math.ceil((currentPageSize * (curentPage - 1) + 1) / newPageSize)
}

// /////////////////////////////////////////////////////////////////////////////
// Action Creators
// /////////////////////////////////////////////////////////////////////////////

/**
 * An ActionCreator that dispatches the PAGE_SIZE_UPDATE action to update the
 * pagination state with the new `pageSize` and the recalculated `page` the user
 * is now on (based on the change in `pageSize`).
 *
 * @param newPageSize
 * @param state
 */
export const pageSizeUpdate = (newPageSize: number, newPage: number): AnyAction => ({
  type: PAGE_SIZE_UPDATE,
  payload: {
    page: newPage,
    pageSize: newPageSize,
  },
})

/**
 * An ActionCreator that dispatches the PAGE_UPDATE action to update the
 * pagination state with the new `page` the user wishes to show.
 *
 * @param nextPage
 * @param state
 */
export const pageUpdate = (nextPage: number): AnyAction => ({
  type: PAGE_UPDATE,
  payload: {
    page: nextPage,
  },
})

// /////////////////////////////////////////////////////////////////////////////
// Dispatchers
// /////////////////////////////////////////////////////////////////////////////

/**
 * A thunk to facilitate the selection of a new `page`.
 * Given a new `page`, first dispatch the `pageUpdate` action, then dispatch
 * the `contentUpdate` action.
 *
 * @param newPage
 */
export const pageAndContentUpdate = (newPage: number): Gallery.ContentQuery.Action => (contentUpdate({
  actions: pageUpdate(newPage),
}))

/**
 * A thunk to facilitate the selection of a new `pageSize`.
 * Given a new `pageSize`, first dispatch the `pageSizeChange` action,
 * then dispatch the `contentUpdate` action.
 *
 * @param page
 * @param pageSize
 * @param newPageSize
 */
export const pageSizeAndContentUpdate = (page: number, pageSize: number, newPageSize: number): Gallery.ContentQuery.Action => {
  const newPage = calculateCurrentPage(page, pageSize, newPageSize)

  return contentUpdate({
    actions: pageSizeUpdate(newPageSize, newPage),
  })
}
