import config from 'config'
import qs from 'query-string'

import { AbstractService } from 'services/AbstractService'
import { ServiceError, InvalidResponseError } from 'services/errors'
import { AxiosError } from 'axios'

const DEFAULT_AUTOSUGGEST_QUERY = {} as KeywordsAutoSuggest.AutoSuggestQuery

export class KeywordsAutoSuggestService extends AbstractService implements Services.IKeywordsAutoSuggestService {
  /**
     * Given a AutoSuggestQuery object, request new suggestions from the KeywordsAutoSuggest Service
     *
     * @param query
     * @throws ServiceError
     */
  public async getSuggestions (
    query: KeywordsAutoSuggest.AutoSuggestQuery = DEFAULT_AUTOSUGGEST_QUERY
  ): Promise<KeywordsAutoSuggest.Response> {
    const {
      locale,
      galleryName,
      keyword,
      limit,
      noCache,
      useAlternateSearchProvider,
      searchBackend,
      ...rest
    } = query

    const url = `v2/Galleries/${encodeURIComponent(galleryName)}/Culture/${encodeURIComponent(locale)}`

    try {
      const response = await this.api.get<string[]>(url, {
        params: {
          keywordFragment: keyword,
          limit,
          requestor: noCache ? config.noCacheRequestor : config.appName,
          useAlternateSearchProvider,
          searchBackend,
          ...rest,
        },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
      })

      if (!response || !response.data || typeof response.data === 'string') {
        throw new InvalidResponseError({
          message: 'Empty Response from content API',
          query,
          response,
          url,
        })
      }

      const resp = {
        suggestions: response.data,
        requestUrl: url,
      }

      return resp
    } catch (e) {
    //   this.logger.warning(new ServiceError({
    //     url,
    //     query,
    //     response: (e as AxiosError).response,
    //     message: `Failed to fetch suggestions from AutoSuggest service: ${(e as Error).message}`,
    //   }, e as Error))

      return {
        suggestions: [],
        requestUrl: url,
      }
    }
  }
}
