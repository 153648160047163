import { useSelector } from 'react-redux'
// import { useSearchParams } from 'react-router-dom'

import { getForwardedQuery, getUrlPathSelector } from '~/client/store/config/reducer'

// Handle links href
export const useLinkParameters = (page: number): string => {
  const forwardedTLPQuery = useSelector(getForwardedQuery)
  const galleryLink = useSelector(getUrlPathSelector)
  // const [searchParams] = useSearchParams()

  const galleryUrl = new URL(typeof galleryLink === 'string' ? galleryLink : galleryLink?.href ?? '')

  if (typeof forwardedTLPQuery !== 'undefined') {
    galleryUrl.searchParams.forEach((value, key) => {
      galleryUrl.searchParams.delete(key)
    });

    (new URLSearchParams(forwardedTLPQuery)).forEach((value, key) => {
      galleryUrl.searchParams.set(key, value)
    })
  } else {
    // searchParams.forEach((value, key) => {
    //   galleryUrl.searchParams.set(key, value)
    // })
  }

  galleryUrl.searchParams.delete('page')

  if (page > 1) {
    galleryUrl.searchParams.set('page', page.toString())
  }

  return galleryUrl.toString()
}
