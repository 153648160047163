import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { createSelector } from 'reselect'

import { refinementsSelector, refinementRemove, buildRefinement, } from '~/client/store/refinement'
import { FILTER_TYPE, REFINEMENT_DIMENSION } from 'shared/constants'
import { getLogger } from 'client/utils/gallery/logger'

const LOGGER = getLogger()

function getL1Action (
  l1Ref: State.Refinement,
  allRefs: Util.StringDictionary<State.Refinement>,
  categoryById: Util.StringDictionary<State.Category>
): ThunkAction<void, State.GlobalState, undefined, AnyAction> {
  const l1Cat = categoryById[l1Ref.value]

  const toRemove = l1Cat.children.reduce(
    (acc, c) => {
      if (allRefs[c]) {
        acc.push(buildRefinement(c, REFINEMENT_DIMENSION.CATEGORY))
      }
      return acc
    },
    [] as State.Refinement[]
  )

  toRemove.push(l1Ref)

  return refinementRemove(toRemove)
}

/**
 * Returns a mapping of refinement key (filterOption ID) -> filterOption from
 * the state for valid (non-radio) pillbox refinement types
 *
 * @param state
 */
export const findAllRemovableRefinements = createSelector(
  (state: State.GlobalState) => state.filterByCategory,
  (state: State.GlobalState) => state.filterCategories,
  (state: State.GlobalState) => state.filterByOption,
  (state: State.GlobalState) => state.filterOptions,
  (state: State.GlobalState) => state.filters,
  (state: State.GlobalState) => state.filterCategories,
  refinementsSelector,
  (
    filterByCategory,
    filterCategories,
    filterByOption,
    filterOptions,
    filters,
    categoryById,
    refinements
  ) => Object.keys(refinements).reduce((accum, id) => {
    if (id === REFINEMENT_DIMENSION.KEYWORD || id === REFINEMENT_DIMENSION.COLLECTION) {
      return {
        ...accum,
        [id]: {
          ...refinements[id],
          title: refinements[id].value,
        },
      }
    }

    const canonicalizedId = id.toLowerCase()
    const { dimension } = refinements[id]
    let filterId

    if (dimension === REFINEMENT_DIMENSION.CATEGORY) {
      filterId = filterByCategory[canonicalizedId]
    } else {
      filterId = filterByOption[canonicalizedId]
    }

    const filter = filters.byId[filterId]

    if (!filter) {
      return accum
    }

    try {
      const type = filter.type ?? FILTER_TYPE.UNKNOWN

      if (type !== FILTER_TYPE.RADIO) {
        let title

        if (dimension === REFINEMENT_DIMENSION.CATEGORY) {
          title = filterCategories[canonicalizedId].title
        } else {
          title = filterOptions.byId[canonicalizedId].title
        }

        const refinement = {
          title,
          dimension,
          value: refinements[id].value,
        } as Refinements.RefinementItem

        // L1 needs to also remove it's L2s
        if (type === FILTER_TYPE.FACETED_TAXONOMY && categoryById[id].level === 1) {
          refinement.action = getL1Action(refinements[id], refinements, categoryById)
        }

        return {
          ...accum,
          [id]: refinement,
        }
      }
    } catch (e) {
      // TODO uncomment
    //   LOGGER.warning(`Error occurred for filter ${filterId}`, e as Error, {
    //     filterId,
    //     filters: filters.byId,
    //     refinement: refinements[id],
    //   })
    }

    return accum
  }, {} as Refinements.Refinements)
)
