import { ComponentProps, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { history as galleryHistory } from 'client/utils/history'

export type PropTypes = ComponentProps<typeof Link> & {
  noFollow?: boolean;
  shouldShowRevisedPdp?: boolean;
}

export const LinkWithQuery = (props: PropTypes): JSX.Element => {
  const {
    children,
    noFollow,
    to: toProp,
    shouldShowRevisedPdp,
    ...rest
  } = props
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useState(search)

  // TODO Figure out what to do with this
  // galleryHistory?.listen(({ location, action }) => {
  //   if (action === 'PUSH') {
  //     setSearchParams(location.search)
  //   }
  // })

  return (
    shouldShowRevisedPdp
      ? (
        <a
          href={`${toProp}`}
          style={{ display: 'inherit' }}
        >
          {children}
        </a>
        )
      : (
        <Link
          rel={noFollow ? 'nofollow' : undefined}
          style={{ display: 'inherit' }}
          to={`${toProp}${searchParams}`}
          {...rest}
        >
          {children}
        </Link>
        )
  )
}
