import { createSelector } from 'reselect'
import qs from 'query-string'

import {
  categoryRefinementsSelector,
  nonDefaultAttributeRefinementIdsSelector,
  keywordRefinementSelector,
  collectionRefinementSelector,
  templateUseCaseRefinementsSelector,
} from 'client/store/refinement/selectors'
import { nonDefaultPageSelector, nonDefaultPageSizeSelector } from 'client/store/paging/selectors'
import { updateQuery } from 'client/store/locationState/utils'
import { getIsL1orL2, getMpvid, getQuantitySelector } from 'client/store/config'
import {
  getNoCache,
  getBypassApproval,
  getUseConstraints,
  getForcedRankingStrategyOverride,
  getQuickViewId,
  getEnrich,
  getIsProduct,
  getExperienceType,
  getTemplatePurposes,
} from 'client/store/debug'
import { REFINEMENT_DIMENSION } from 'shared/constants'
import {
  getImagePlaceholderAspectRatio, getImagePlaceholderAspectRatioTolerance,
  getPlaceholderPurposes, getUseAlternateSearchProvider, getSearchBackend,
  getDesignCreationTypes,
  getUseRealisationEngineService,
  getEnableTemplateUseCases,
  getHighlightKeywords,
  getHighlightCategories,
  getTakeOverQV,
  getSelectedDesignId,
} from 'client/store/debug/reducer'

export const selectQueryState = createSelector(
  nonDefaultAttributeRefinementIdsSelector,
  categoryRefinementsSelector,
  templateUseCaseRefinementsSelector,
  keywordRefinementSelector,
  collectionRefinementSelector,
  nonDefaultPageSelector,
  nonDefaultPageSizeSelector,
  getIsL1orL2,
  (_state: State.GlobalState, options?: { isRedirectToL0?: boolean }) => options?.isRedirectToL0,
  (
    attributeIds,
    categories,
    templateUseCases,
    keyword,
    collection,
    page: number | null,
    pageSize: number | null,
    isL1orL2,
    isRedirectToL0
  ): Gallery.Models.Url.SelectQueryState => ({
    parameters: {
      page,
      pageSize,
      [REFINEMENT_DIMENSION.ATTRIBUTE_PLURAL]: attributeIds.map((attr) => attr),
      [REFINEMENT_DIMENSION.CATEGORY_PLURAL]: (isL1orL2 && !isRedirectToL0)
        ? []
        : categories.map((attr) => attr.value),
      [REFINEMENT_DIMENSION.TEMPLATE_USE_CASES]: templateUseCases.map((uc) => uc.value),
      [REFINEMENT_DIMENSION.KEYWORD]: keyword ? keyword.value : null,
      [REFINEMENT_DIMENSION.COLLECTION]: collection ? collection.value : null,
    },
    replace: keyword && !!keyword.replace,
  })
)

export function selectQueryString (newQueryState: Gallery.Models.Url.SelectQueryState, queryString: string): string {
  const query = { ...qs.parse(queryString) }

  for (const [key, value] of Object.entries(newQueryState.parameters)) {
    updateQuery(key, value, query)
  }
  return Object.keys(query).length
    ? `?${qs.stringify(query, { arrayFormat: 'comma' })}`
    : ''
}

export const selectFullQueryStringState = createSelector(
  nonDefaultAttributeRefinementIdsSelector,
  categoryRefinementsSelector,
  templateUseCaseRefinementsSelector,
  keywordRefinementSelector,
  collectionRefinementSelector,
  nonDefaultPageSelector,
  nonDefaultPageSizeSelector,
  getQuantitySelector,
  getMpvid,
  getNoCache,
  getUseConstraints,
  getBypassApproval,
  getForcedRankingStrategyOverride,
  getQuickViewId,
  getUseRealisationEngineService,
  getEnrich,
  getIsProduct,
  getExperienceType,
  getTemplatePurposes,
  getImagePlaceholderAspectRatio,
  getImagePlaceholderAspectRatioTolerance,
  getPlaceholderPurposes,
  getUseAlternateSearchProvider,
  getSearchBackend,
  getDesignCreationTypes,
  getEnableTemplateUseCases,
  getHighlightKeywords,
  getHighlightCategories,
  getTakeOverQV,
  getSelectedDesignId,
  (
    attributeIds,
    categories,
    templateUseCases,
    keyword,
    collection,
    pageParam,
    pageSizeParam,
    quantityParam,
    mpvIdParam,
    noCacheParam,
    useConstraintsParam,
    bypassApprovalParam,
    forcedRankingStrategyParam,
    quickViewId,
    useRealisationEngineService,
    enrich,
    isProduct,
    experienceType,
    templatePurposes,
    imagePlaceholderAspectRatio,
    imagePlaceholderAspectRatioTolerance,
    placeholderPurposes,
    useAlternateSearchProvider,
    searchBackend,
    designCreationTypes,
    enableTemplateUseCases,
    highlightKeywords,
    highlightCategories,
    takeOverQV,
    selectedDesignId
  ): Gallery.Models.Url.ParamsQueryState => ({
    bypassApproval: bypassApprovalParam || undefined,
    mpvId: mpvIdParam || undefined,
    noCache: noCacheParam || undefined,
    useConstraints: useConstraintsParam || undefined,
    page: pageParam || undefined,
    pageSize: pageSizeParam || undefined,
    quantity: quantityParam || undefined,
    [REFINEMENT_DIMENSION.ATTRIBUTE_PLURAL]: attributeIds.length ? attributeIds.map((attr) => attr) : undefined,
    [REFINEMENT_DIMENSION.CATEGORY_PLURAL]: categories.length ? categories.map((attr) => attr.value) : undefined,
    [REFINEMENT_DIMENSION.TEMPLATE_USE_CASES]: templateUseCases.length
      ? templateUseCases.map((uc) => uc.value)
      : undefined,
    [REFINEMENT_DIMENSION.KEYWORD]: keyword ? keyword.value : undefined,
    [REFINEMENT_DIMENSION.COLLECTION]: collection ? collection.value : undefined,
    forcedRankingStrategyOverride: forcedRankingStrategyParam || undefined,
    quickView: quickViewId || undefined,
    enrich: enrich || undefined,
    isProduct: isProduct || undefined,
    experienceType: experienceType || undefined,
    templatePurposes,
    imagePlaceholderAspectRatio: imagePlaceholderAspectRatio || undefined,
    imagePlaceholderAspectRatioTolerance: imagePlaceholderAspectRatioTolerance || undefined,
    placeholderPurposes: placeholderPurposes || undefined,
    useAlternateSearchProvider: useAlternateSearchProvider || undefined,
    useRealisationEngineService: useRealisationEngineService || undefined,
    searchBackend: searchBackend || undefined,
    source: searchBackend || undefined,
    designCreationTypes: designCreationTypes || undefined,
    enableTemplateUseCases: enableTemplateUseCases || undefined,
    highlightKeywords: highlightKeywords || undefined,
    highlightCategories: highlightCategories || undefined,
    takeOverQV: takeOverQV || undefined,
    selectedDesignId: selectedDesignId || undefined,
  })
)
