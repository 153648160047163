import config from 'config'
import { QueryFunctionContext } from '@tanstack/react-query'
import { ProductCatalogPricingService } from '~/services/ProductCatalogPricingService'
import { CHOICE_GROUP_BATCH_SIZE } from 'client/constants'

import { getLogger } from 'client/utils/gallery/logger'

export const QUERY_KEY = 'differential_pricing'

const productCatalogPricingService = new ProductCatalogPricingService(
  config.services.productCatalogPricingService,
  getLogger
)

export const queryBatchDifferentialPricing = async (
  context: QueryFunctionContext<VP.PCT.Queries.ProductCatalogPricingService.DifferentialPriceQueryKey>
): Promise<VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult | null> => {
  const { queryKey } = context
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, {
    market,
    productKey,
    quantity,
    productVersion,
    choiceGroups,
    pricingContext,
    couponCode,
    effectiveDateTime,
    customerGroups,
    selections,
  }] = queryKey

  const requests: Promise<VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult | null>[] = []

  const batchableChoiceGroups = Object.entries(choiceGroups || {})

  // Assigned 2 to CHOICE_GROUP_BATCH_SIZE as a hotfix to increase the no.of batches and reduce the request size
  for (let i = 0; i < batchableChoiceGroups.length; i += CHOICE_GROUP_BATCH_SIZE) {
    const batchedChoiceGroups = batchableChoiceGroups.slice(i, i + CHOICE_GROUP_BATCH_SIZE)
      .reduce((result, [key, value]) => {
        result[key] = value

        return result
      }, {} as VP.PCT.Models.ProductCatalogPricingService.ChoiceGroups)

    const batchedRequest = productCatalogPricingService.getDifferentialPrice(
      market,
      productKey,
      quantity,
      productVersion,
      batchedChoiceGroups,
      pricingContext,
      couponCode,
      effectiveDateTime,
      customerGroups,
      selections
    )

    requests.push(batchedRequest)
  }

  const responses = await Promise.all(requests)

  const responsesChoiceGroups = responses.reduce(
    (accum, response) => ({
      ...accum,
      ...response?.choiceGroups,
    }),
    {} as Promise<VP.PCT.Models.ProductCatalogPricingService.ChoiceGroupsPricing | null>
  )

  return {
    ...responses.reduce(
      (accum, response) => ({
        ...accum,
        ...response,
      }),
      {} as Promise<VP.PCT.Models.ProductCatalogPricingService.DifferentialPricingResult | null>
    ),
    choiceGroups: responsesChoiceGroups,
  }
}
